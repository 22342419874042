import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    id: number;
    username: string;
    password: string;
    emailAddress: string;
    accessToken: string;
    website:string;
    // refreshToken: string;
     roles: number[];
    // pic: string;
    fullName: string;
    // occupation: string;
	// companyName: string;
	// phone: string;
    // address: Address;
    // socialNetworks: SocialNetworks;
    maxEmailVerify: number;

    clear(): void {
      //  this.id = undefined;
        this.username = '';
        this.password = '';
        this.emailAddress = '';
        this.roles = [];
        this.fullName = '';
        this.accessToken = '';
        // this.refreshToken = 'access-token-' + Math.random();
        // this.pic = './assets/media/users/default.jpg';
        // this.occupation = '';
        // this.companyName = '';
        // this.phone = '';
        // this.address = new Address();
        // this.address.clear();
        // this.socialNetworks = new SocialNetworks();
        // this.socialNetworks.clear();
    }
}
