import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class Company extends BaseModel {
    companyName: string;
    industry: string;
    sicCode: string;
    address: string;
    city: string;
    state: string;
    country: string;
    emailAddress: string;
    zip: string;
    phone: string;
    fax: string;
    employeeRange: string;
    businessType: string;
    websitelink:string;
    salesVolumeRange: string;
    expenses : any;

    clear(): void {
        this.companyName = "";
        this.industry = "";
        this.sicCode = "";
        this.address = "";
        this.city = "";
        this.state = "";
        this.country = "";
        this.zip = "";
        this.phone = "";
        this.fax = "";
        this.employeeRange = "";
        this.businessType = "";
        this.salesVolumeRange = "";
        this.expenses = [];
    }
}
