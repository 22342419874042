// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Output } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Auth
import { AuthNotice, AuthNoticeService, AuthService } from '../../../../core/auth/';

@Component({
	selector: 'kt-document-indexing',
    templateUrl: './document-indexing.component.html',
    styleUrls:['document-indexing.component.scss']
})
export class IndexingComponent implements OnInit {

	constructor(public authNoticeService: AuthNoticeService, private cdr: ChangeDetectorRef, private auth: AuthService) {
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
	}

    CreateIndexes() {
        this.auth.indexingSearch().subscribe(data => {
            console.log(data);
        })
    }
}
