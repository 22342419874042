// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import {  AuthService } from '../../../../../core/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	// user$: Observable<User>;
	isAdmin=false;
	_user: any;
	@Input() avatar = false;
	@Input() greeting = true;
	@Input() badge = false;
	@Input() icon =  false;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,
		private router: Router,
		private auth: AuthService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
	this.isAdmin = localStorage.getItem('IsAdmin') == '1' || localStorage.getItem('IsAdmin') == '2' ? true : false;
		// this.user$ = new Observable((observer) => {
		// 	return () => {  return { fullName: "Himanshu", email: "abc@hash.com" }};
		// })// this.store.pipe(select(currentUser));
		this._user =  { fullName: localStorage.getItem("name") || 'user', email: localStorage.getItem('email') };
	}

	profile() {
		this.router.navigateByUrl('/profile'); // Main page
	}

	/**
	 * Log out
	 */
	logout() {
		console.log('#logout');
		this.auth.logout().subscribe(() => {
			this.store.dispatch(new Logout());
			localStorage.clear();
		})
	}

	podcastAdmin(){
		if(localStorage.getItem('IsAdmin') == '1'){
			window.location.href='http://podcasts.bizwizz.net:4100/myvideos?token='+localStorage.getItem('token');
		  }else{
			this.router.navigateByUrl('/auth/login');
		  }
	}
}
