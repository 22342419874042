
import { required ,maxLength,prop,contains} from "@rxweb/reactive-form-validators"
import { emailValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
export class MiniProfileDTO{

    @required({message:'This field is required'})
    continentId:string;
  
    @required({message:'This field is required'})
    countryId:string;
  
    @required({message:'This field is required'})
    companyId:string;

    @prop()
    isVerified:boolean;

    @prop()
    photo:string;

    @required({message:'This field is required'})
    firstname:string;

    @required({message:'This field is required'})
    lastname:string;

    @prop()
    otherCompany:string;

    @prop()
    otherRole:string;

    @prop()
    otherSpeciality:string;

    @prop()
    userid:string;

    @required({message:'This field is required'})
    mobileNo:any;

    @prop()
    roleId:string;

    @prop()
    speciality:string;

    @prop()
    joinedDate:Date;

    @required({message:'This field is required'})
    email:string;

    @prop()
    compnayPhoneNumber:string;

    @required({message:'This field is required'})
    website:string;

    @prop()
    zip: string;

    @prop()
    country: string;

    @prop()
    statename: string;

    @required({message:'This field is required'})
    address: string;

    @prop()
    state: string;

    @prop()
    maxEmailVerify: number;

    phoneNumber: string;

    phoneIsVerified: boolean;

    dialCode: number;

    countryCode :any;
  }