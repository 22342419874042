import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../../core/auth';
import swal,{ SweetAlertOptions } from 'sweetalert2';

declare var BizwizzTracker: any;
const PAGE_ID = 'zoomin';

@Component({
  selector: 'kt-dashboard',
  templateUrl: './zoomingroomin.component.html',
  styleUrls: ['./zoomingroomin.component.scss']
})

export class ZoominGroominComponent implements OnInit {
  @ViewChild('simpleAlert', { static: true }) simpleAlert: SwalComponent;
	simpleAlertOption:SweetAlertOptions = {};
	@ViewChild('simpleDialog', { static: true }) simpleDialog: SwalComponent;
	simpleDialogOption:SweetAlertOptions = {};
  userForm: FormGroup;
  isStarbucksAvailable: string = 'Y';
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private auth: AuthService,) {
    this.initForm();
  }
  
  ngOnInit() {
    this.initForm();
    this.auth.getStarBucksGiftCards('Y').subscribe((res_data:any)=>{
			// console.log('starbucks status==>',res_data, res_data.message.available.count);
      if (res_data.message.available.count > 0) {
        this.isStarbucksAvailable = 'Y';
        this.cdr.detectChanges();
      }
		},(error)=>{
			console.log('Error getting startbucks status', error)
		})
  }
  initForm(){
    this.userForm = this.fb.group({
      email: [ '', Validators.compose([
        Validators.required,
      ])],
      fname: [ '', Validators.compose([
        Validators.required,
      ])],
      lname: [ '', Validators.compose([
        Validators.required,
      ])],
      phone: [ '', Validators.compose([
        Validators.required,
      ])],
      region: [ '', Validators.compose([
        Validators.required,
      ])]
    });
  }
  
  onSubmit() {
    // check url params
    if (this.activatedRoute.snapshot.queryParamMap.get('bizwizzemail') === null || 
        this.activatedRoute.snapshot.queryParamMap.get('bizwizzuid') === null) {
        this.simpleAlertOption = {
          title:"The URL of this page is invalid!.",
          confirmButtonColor:"#3085d6",
          confirmButtonText:'OK',
          icon: 'error',
          focusConfirm: true
        }
        this.cdr.detectChanges()
        this.simpleAlert.fire();
        return;  
    }
    // check for form errors
    const controls = this.userForm.controls;
    if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.simpleAlertOption = {
				title:"Please fill up all required information.",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'OK',
				icon: 'info',
				focusConfirm: true
			}
			this.cdr.detectChanges()
      this.simpleAlert.fire();
			return;
		}
    
    
    // do the conversion
    const tracker = new BizwizzTracker();
    tracker.sendEvent('CONVERSION');
    // store in db and send email to user regarding the conversion
    let data = {
      subEmail: controls.email.value,
      subFname: controls.fname.value,
      subLname: controls.lname.value,
      subRegion: controls.region.value,
      subPhone: controls.phone.value,
    };
    const payload = {
      data: JSON.stringify(data),
      uniqueCode: this.activatedRoute.snapshot.queryParamMap.get('bizwizzuid'),
      pageid: PAGE_ID
    }
    this.auth.landingPageDBEntry(payload).subscribe((res_data:any)=>{
			console.log('response==>',res_data);
		},(error)=>{
			console.log('Error2121', error)
		})
    // show confirmation
    this.simpleAlertOption = {
      title:"Data submitted!",
      confirmButtonColor:"#3085d6",
      confirmButtonText:'OK',
      icon: 'info',
      focusConfirm: true
    }
    this.cdr.detectChanges()
    this.simpleAlert.fire();
    this.userForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
