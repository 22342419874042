import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class SearchFilter extends BaseModel {
    company: string;
    industry: string;
    code: string;
    employeeRange: string;
    salesRange: string;
    USA: boolean;
    China: boolean;
    India: boolean;
    Japan:boolean;
    codeType: string;
    pageIndex: number;
    pageSize: number;
    country: string;
    state: string;
    city: string;
    hsId: string;

    filterCount(): number{
        let count = 0;

        if(this.company){
            count += 1;
        }
        if(this.industry){
            count += 1;
        }
        if(this.code){
            count += 1;
        }
        if(this.employeeRange){
            count += 1;
        }
        if(this.salesRange){
            count += 1;
        }
        if(this.country){
            count += 1;
        }
        if(this.state){
            count += 1;
        }
        if(this.city){
            count += 1;
        }
        if(this.hsId){
            count += 1;
        }

        console.log('#count', count);
        
        return count;
    }

    clear(): void {
        this.company = "";
        this.industry = "";
        this.code = "";
        this.employeeRange = "";
        this.salesRange = "";
        this.USA = false;
        this.China = false;
        this.India = false;
        this.Japan = false;
        this.codeType= "";
        this.pageIndex = 0;
        this.pageSize = 20;
        this.country = "";
        this.state = "";
        this.city = "";
        this.hsId = "";
    }
}
