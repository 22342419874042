// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, Login } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { ToastrService } from 'ngx-toastr';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { OTPDialogComponent } from '../otp-modal/otp-modal.component';
import { Guid } from "guid-typescript";
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { LoaderService } from '../../../theme/loader.service';

declare function captureSignUp():any;
@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
	//from mini-profile

	continentList=[];
  text='Upload';
  profileImage='';
  //verified = true;
  countryList=[];
  allCountry=[];
  filteredCountry=[];
  companyList=[];
  countryCode='';
  roleList=[];
  isOtherCompany=false;
  isOtherRole=false;
  isOtherSpaciality=false;
  displayCounty=false;
  allContinentList=[];
  allCompanyList=[];
  allRoleList=[];
  specialityList=[];
  userData=[];
  //loading = false;
  allSpecialityList=[];
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.China, CountryISO.India, CountryISO.Canada];
//   frmMiniProfile:RxFormGroup;
//   miniProfileDto:MiniProfileDTO;
  SearchCountryField = SearchCountryField;
  phoneVerified:boolean=false;
  continentFilterCtrl=new FormControl('');
  countryFilterCtrl=new FormControl('');
  companyFilterCtrl=new FormControl('');
  roleFilterCtrl= new FormControl('');
  specialityFilterCtrl=new FormControl('');
  stateFilterCtrl = new FormControl('');
  joinedDate = new Date();
  Phone: 'Phone';
  countries: any;
  states: any;
  counties:any;
  stateName:any;
  continentonloadId:any;
  contryOnLoadId:any;
  stateOnLoadId:any;
  formattedAddress='';
  lat:any = [];
  lng:any = [];
  houseNumber:string ='';
  street:string = '';
  mapPaostalCode:any = '';
  mapAddress:any={};
  mapNeighborhood:string='';
  mapCounty:string='';
  mapCity:string='';
  mapcountry:string='';
  mapState:string = '';
  mapUserAddress:string='';
  Elbody = document.getElementsByTagName('body')[0];
  mapountryShortName: any = CountryISO.India;
  inputdialCodevalue: any = 91;
  industryFilterCtrl = new FormControl('');

	registerForm: FormGroup;
	blockedDomainList=[];
	loading = false;
	verified = true;
	errors: any = [];
	passwordType=true;
	linkedInUrl = 'http://127.0.0.1:1338/api/v1/auth/linkedin'
	confirmPasswordType=true;
	// site_key='6Ldwj-0UAAAAABhOgDSgL0uxmZlCrE9MywghIptL'; //kuoda.com
	site_key = '6Lfy1GsaAAAAAHu6ByW1gi5BXpJUxEOlbpLb2LWy'
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	filteredIndustry: any[];
	salesRanges: any;
	countryId: any;
	continentId: any;
	domainName: any;
	private _Currentuser: { fullName: string; email: string; website: string };

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private toastr: ToastrService,
		public dialog: MatDialog,
		private loader:LoaderService,
		private cd:ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	toggle(key){
		switch (key) {
			case 1:
				this.passwordType=!this.passwordType;	
				break;
				case 2:
					this.confirmPasswordType=!this.confirmPasswordType;	
					break;
			default:
				break;
		}
	}
	ngOnInit() {
		this._Currentuser =  { fullName: localStorage.getItem("name"), email: localStorage.getItem('email'), website: localStorage.getItem('website') };
		// if(localStorage.getItem('user')!=null){
		// 	this.router.navigateByUrl('/dashboard');
		// 	return;
		// }
		this.industryFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed industry:', value)
			this._filterIndustries(value);
		});
		this.roleFilterCtrl .valueChanges.subscribe(value => {
			
			console.log('name has changed:', value)
			this._filterRole(value);
		});
		this.companyFilterCtrl .valueChanges.subscribe(value => {
			console.log('name has changed:', value)
			this._filterCompany(value);
		});
		
		this.initRegisterForm();
		this.getBlockedDomain();
		this.auth.getMiniProfileMasterData({
			mode: 'limited'
		}).subscribe((res) => {
			this.roleList = res['data'].roleList;
			this.allRoleList = this.roleList
			//this.loader.display(false);
			this.cd.detectChanges();
			this.salesRanges = res['data'].salesRange;
		});

	}

	isVerified(){
		return this.verified;
	}
	resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response: ${captchaResponse}`);
		this.auth.verifyRecaptcha(captchaResponse).subscribe((res:any) => {
			console.log('#res', res);
			this.verified = false;
			console.log('#verified', this.verified);
			this.cdr.markForCheck();  

		}, (error: any) => {
			this.verified = true;
			console.log('#error', error);
			this.cdr.markForCheck();  

		});
    }
	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.verified = false;
	}
	getBlockedDomain(){
		this.auth.getBlockedDomain().subscribe((x:any)=>{
			this.blockedDomainList = x.data.map(function(item) {
				return item['domain'].toLowerCase();
			  });
			
	},(err)=>{
	
	})
	}
	isEmailWithValidDomain(){
		
	let website=this.registerForm.value.website.toLowerCase();
	let email=this.registerForm.value.email.toLowerCase();
			let url='';
			if(website.includes('http')){
			  url=website.replace('www.','');      
			}else{
			let newUrl= website.replace('www.','');
			 url ='http://'+ newUrl;       
			}
			const host=new URL(url).host;
			if(!email.includes(host)){
			  this.toastr.warning('Given email id is not a valid email please provide with '+host+' domain', 'Warning');
				return false;
			}
			return true;
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			fullname: ['', Validators.compose([
				// Validators.required,
				// Validators.minLength(3),
				// Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				// Validators.required,
				// Validators.email,
				// Validators.minLength(3),
				// // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				// Validators.maxLength(320)
			]),
			],
			website: ['', Validators.compose([
				// Validators.required,
				// Validators.minLength(4),
				// Validators.maxLength(320)
			]),
			],
			password: ['', Validators.compose([
				// Validators.required,
				// Validators.minLength(3),
				// Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				// Validators.required,
				// Validators.minLength(3),
				// Validators.maxLength(100)
			])],
			mobileNo: ['',Validators.compose([
				Validators.required,
			])],
			role: ['',Validators.compose([
				
			])],
			otherRole: ['',Validators.compose([
				
			])],
			companyName: ['',Validators.compose([
				
			])],
			otherCompany: ['',Validators.compose([
				
			])],
			address: ['',Validators.compose([
				Validators.required,
			])],
			zip: ['',Validators.compose([
				
			])],
			statename: ['',Validators.compose([
				
			])],
			country: ['',Validators.compose([
				
			])],
			industry: ['',Validators.compose([
				Validators.required,
			])],
			salesVolumeRange: ['',Validators.compose([
				Validators.required,
			])],
			whatYouBuy: ['',Validators.compose([
				Validators.required,
			])],
			buyRange: ['',Validators.compose([
				Validators.required,
			])],
			compnayPhoneNumber: ['',Validators.compose([
			])],
			maxProspects: ['100',Validators.compose([
				
			])],
			
				

			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		debugger;
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		// if(this.blockedDomainList.indexOf(controls.website.value.toLowerCase())>-1){
		// 	this.toastr.warning('Invalid Domain.','Warning');
		// 	return;
		// }
		// if(!this.isEmailWithValidDomain()){
		// 	return;
		// }

		if (!controls.agree.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.toastr.error("You must agree the terms and condition","Error")
			// this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}
		if(!this.phoneVerified){
			this.toastr.error("Please verify you phone","Error")
			return;
		}

		//const _user: User = new User();
		const _user = {
			id: localStorage.getItem('id'),
			emailAddress:localStorage.getItem('email'),
			fullName : localStorage.getItem("name"),
			// password : controls.password.value,
			website : localStorage.getItem('website'),
			role : controls.role.value !== 0 ? controls.role.value :  controls.otherRole.value,
			companyName: controls.companyName.value !==0 ? controls.companyName.value : controls.otherCompany.value,
			address : controls.address.value,
			zip : controls.zip.value,
			statename: controls.statename.value,
			country: controls.country.value,
			industry: controls.industry.value,
			salesVolumeRange: controls.salesVolumeRange.value,
			whatYouBuy: controls.whatYouBuy.value,
			buyRange: controls.buyRange.value,
			compnayPhoneNumber: controls.compnayPhoneNumber.value,
			maxProspects: controls.maxProspects.value,
			continentId: this.continentId,
			mobileNo: controls.mobileNo.value
		}
		// _user.clear();
		//_user.emailAddress = controls.email.value;
		// _user.username = controls.username.value;
		// _user.fullName = controls.fullname.value;
		// _user.password = controls.password.value;
		// _user.website = controls.website.value;
		// _user.roles = [];
		// console.log("#USER", _user);
		
		this.loading = true;
	
		// let websiteName=_user.website;
		// if(websiteName.includes('http://')){
		//   websiteName= _user.website.replace('http://','');      
		// } 
		// if(websiteName.includes('https://')){
		//   websiteName= _user.website.replace('https://','');   
		// }
		// if(!websiteName.includes('www.')){
		//   websiteName= 'www.'+websiteName;   
		// }
		// _user.website=websiteName;

		this.auth.profileInfo(_user).subscribe((res: any) => {
			//this.data = res;
			console.log("#res_register", res);
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');      
			this.toastr.success(res.message,"Success");
			// this.authNoticeService.setNotice(res.message, 'success');      
			this.loading = false;
			this.cdr.markForCheck();  			
			this.router.navigateByUrl('/auth/login');                                              
		  }, err => {
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			this.toastr.error(err.error && err.error.errorMsg || "Something went wrong","Error");
			//  this.authNoticeService.setNotice(err.error && err.error.errorMsg || "Something went wrong", 'danger');
			console.log("#error rej", err);
			this.loading = false;
		    this.cdr.markForCheck();  
		  });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}
		if(validationType=="website"){
			if(control.value==''){
				return false;
			}			
			var expression = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
			  var res = expression.test(control.value);
			  if(res){
				  return false;
			  }
			  else{
				  return true;
			  }	 
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	onPhoneChange(evt) {
		// if(this.userData[0].dialCode) {
		//   let onchangephoneNumber = this.userData[0].phoneNumber;
		//   let inputphoneNumber = evt.target.value;
		//   let onchnagedialcode = this.userData[0].dialCode;
		//   let inputdialCodevalue = this.inputdialCodevalue
		// 	if(onchangephoneNumber == inputphoneNumber) {
		// 	  if(inputdialCodevalue == onchnagedialcode) {
		// 		this.phoneVerified = true;
		// 	  }
		// 	  else {
		// 		this.phoneVerified = false;
		// 	  }
	  
		// 	}
		// 	else {
		// 	  this.phoneVerified = false;
		// 	}
		// }
		console.log(this.registerForm.controls.mobileNo)
	
	}

	checkNumber(evt) {
		if(this.userData[0].dialCode) {
		let onchnagedialcode = this.userData[0].dialCode;
		  if(onchnagedialcode) {
			this.inputdialCodevalue= evt.dialCode;
			this.checkContactCountry(onchnagedialcode,this.inputdialCodevalue);
		  }
		}
	}
	checkContactCountry(onchnagedialcode,inputdialCodevalue) {
		let onchangephoneNumber = this.userData[0].phoneNumber;
		let inputphoneNumber = this.registerForm.controls.mobileNo.value.number;
		if(inputdialCodevalue == onchnagedialcode) {
		  if(onchangephoneNumber == inputphoneNumber) {
			this.phoneVerified = true;
		  }
		  else {
			this.phoneVerified = false;
		  }
	
		}
		else {
		  this.phoneVerified = false;
		}
	}

	sendOTP(): void {
		let phone = this.registerForm.controls.mobileNo.value;
		//let id = localStorage.getItem('id');
		if (!this.phoneVerified) {
			phone = `${phone.dialCode}${phone.number}`;
			this.auth.sendOTPAtSignup(phone).subscribe((res) => {
				const dialogRef = this.dialog.open(OTPDialogComponent, {
					width: '310px',
					data: {
						phone,
						secret:res.secret
					},
				});
				dialogRef.afterClosed().subscribe((confirmed: boolean) => {
					if (confirmed) {
					this.phoneVerified = confirmed;
					this.cd.detectChanges();
					this.toastr.success('Mobile number has been verified successfully', 'Success');
					}
				});
				this.toastr.success('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
			}, error => {
				this.toastr.success('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
			});
			
		}

	}

	handleAddressChange(event): void {
		
		this.resetMapData();
		this.formattedAddress = event.formatted_address;
		for (let i = 0; i < event.address_components.length; i++) {
		  this.mapAddress = event.address_components[i];
		  if (this.mapAddress.long_name != '') {
			switch (this.mapAddress.types[0]) {
			  case "street_number":
				this.houseNumber = this.mapAddress.long_name;
				break;
			  case "route":
				this.street = this.mapAddress.long_name;
				break;
			  case "locality":
				this.mapCity = this.mapAddress.long_name;
				break;
			  case "administrative_area_level_1":
				this.mapState = this.mapAddress.long_name;
				break;
			  case "country":
				this.mapcountry = this.mapAddress.long_name;
				this.mapountryShortName = this.mapAddress.short_name;
				break;
			  case "postal_code":
				this.mapPaostalCode  = this.mapAddress.long_name;
				break;
			  case "neighborhood":
				this.mapNeighborhood  = this.mapAddress.long_name;
				break;
			  case "administrative_area_level_2":
				this.mapCounty  = this.mapAddress.long_name;
				break;
			  default:
				console.log("No such address exists!");
				break;
			}
		  }
		  
		}
		this.setNewValues();
	
	  }
	  private setCoutryflag(mapountryShortName) {
		this.mapountryShortName= mapountryShortName;
	  }

	  private resetMapData() {
		this.houseNumber = '';
		this.street = '';
		this.mapPaostalCode = 0;
		this.mapAddress = {};
		this.mapNeighborhood = '';
		this.mapCounty = '';
		this.mapCity = '';
		this.mapcountry = '';
		this.mapState = '';
		this.mapUserAddress = '';
	  }
	  private setNewValues() {
		this.loader.display(true);
		this.registerForm.patchValue({
			zip: this.mapPaostalCode,
			statename: this.mapState,
			country: this.mapcountry,
			address: this.formattedAddress
		});
		// this.registerForm.zip = this.mapPaostalCode;
		// this.registerForm.statename = this.mapState;
		// this.registerForm.country = this.mapcountry;
		// this.registerForm.address = this.formattedAddress;
		// this.auth.getCountyId(this.mapcountry).subscribe((res: any) => {
		//   let countryID = res.data.id;
		//   let continentId = res.data.continent_Id;
		//   this.miniProfileDto.countryId = countryID;
		//   this.miniProfileDto.continentId = continentId;
		  
		//   this.auth.getStateId(this.mapState,countryID).subscribe((res: any) => {
		// 	this.miniProfileDto.state = res.data[0].id;
		//   });
		  
		//   this.setCoutryflag(this.mapountryShortName);
		//   if(this.userData[0].dialCode) {
		// 	let onchnagedialcode = this.userData[0].dialCode;
		// 	this.inputdialCodevalue = parseInt(this.miniProfileDto.mobileNo.dialCode);
		// 	this.checkContactCountry(onchnagedialcode,this.inputdialCodevalue);
		//   }
	
	
		// this.cd.detectChanges();
		// this.getCompanyData(continentId,countryID);
		  
		// });
		this.auth.getCountyId(this.mapcountry).subscribe((res: any) => {
			// debugger	
		  let countryID = res.data.id;
		  let continentId = res.data.continent_Id;
		  this.countryId = countryID;
		  this.continentId = continentId;
		  
		})
		this.setCoutryflag(this.mapountryShortName);
		this.cd.detectChanges();
		this.loader.display(false);
		this.companyList = ["company 1","company 2", "company 3"]
    	//this.getCompanyData(continentId,countryID);
	  }

	  onCompanyChange(evt){
		if(evt.value==0){
		this.isOtherCompany=true;
		}else{
		  this.isOtherCompany=false;
		  //console.log(evt.value)
		}
	  }	

	  onRoleChange(evt){
		if(evt.value==0){
		this.isOtherRole=true;
		}else{
		  this.isOtherRole=false;
		}
	  }

	  _filterIndustries(value: any) {			
		this.auth.searchIndustry(value).subscribe((res: any) =>{
			const result = [];
			const map = new Map();
			for (const item of res.data.docs) {
				if (!map.has(item.name)) {
					map.set(item.name, true);    // set any value to Map
					result.push(item);
				}
			}
			//this.filteredIndustry = res.data.docs;
			this.filteredIndustry = result;
			console.log(result)
			this.cdr.markForCheck();
			});
		}

		OnIndustryChange(industry){
			// console.log(industry)
			// this.registerForm.patchValue({
			// 	industry, 
			// });
			console.log(this.registerForm.controls)
		}
		_filterRole(value: string) {
    
			let valueLowerCase = value.toLowerCase();
			this.roleList = this.allRoleList.filter(x => x.rolename.toLowerCase().includes(valueLowerCase) )
	 	}

		_filterCompany(value: string) {
			// if(!this.registerForm.controls.email.valid){
			// 	this.toastr.error("please enter valid email first","Error");
			// 	return;
			// }
			let domain = this._Currentuser.email.split('@')[1].split('.')[0]
			this.auth.searchCompanyName(value).subscribe((res: any) =>{
				this.companyList = res.searchedData.docs.filter((data)=>{
					return data.email[0].split('@')[1].split('.')[0] === domain
				})
				
				this.cdr.markForCheck();
				});
		}
		

		
}
