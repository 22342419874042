// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
@Component({
	selector: 'kt-verify-email',
	templateUrl: './verify-email.component.html',
	encapsulation: ViewEncapsulation.None,
	styles:['.kt-login__body{margin-top:100px !important;}']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	verified = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
 		console.log('-----------');

		// // redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			console.log('#PRARAMS', params);
			this.verifyEmail(params.token);
			// this.returnUrl = params.returnUrl || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * verifyEmail
	 */
	verifyEmail(token: string) {
	 

		this.loading = true;

		console.log('#token', token);
		
		this.auth.verifyEmail(token)
				.subscribe( (res: any) => {
 					// this.router.navigateByUrl('/'); // Main page
					console.log('#res', res);
					this.authNoticeService.setNotice(`${res.message}`, 'success');
					this.loading = false;
					this.verified = true;
					this.cdr.markForCheck();
					setTimeout(() => {
						this.router.navigate(['/auth/login']);
					  }, 3000);
				}, err => {
					this.authNoticeService.setNotice(err.error && err.error.errorMsg || "Link expired", 'danger');
					console.log('#err', err);
					this.loading = false;	
					this.verified = false;

					this.cdr.markForCheck();
				});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	
}
