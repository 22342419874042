// Angular
import {
	Component,
	ViewChild,
	OnInit,
	ChangeDetectorRef,
	Inject,
	Output,
	ViewChildren,
	QueryList,
	ElementRef,
	AfterViewInit,
} from "@angular/core";
// Lodash
import { round, shuffle } from "lodash";
// Services
import { AppState } from "../../../core/reducers";
import { currentUser, Logout, User } from "../../../core/auth";
import { Store, select } from "@ngrx/store";
// Widgets model
import {
	LayoutConfigService,
	SparklineChartOptions,
} from "../../../core/_base/layout";
//ng bootstrap
import { NgbAccordionConfig } from "@ng-bootstrap/ng-bootstrap";

import {
	MatPaginator,
	MatSort,
	MatTableDataSource,
	MatSelect,
	MatDialog,
	PageEvent,
} from "@angular/material";
import {
	CdkDragStart,
	CdkDropList,
	moveItemInArray,
} from "@angular/cdk/drag-drop";
// import  Angular2Csv  from 'angular2-csv/Angular2-csv';
import {
	AuthNoticeService,
	AuthService,
	SearchFilter,
} from "../../../core/auth";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormControl,
} from "@angular/forms";
import _ from "lodash";
//snackbar
import {
	MatSnackBar,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { CardData } from "angular-credit-cards";
import { HttpClient } from "@angular/common/http";
import { int } from "aws-sdk/clients/datapipeline";
import { integer } from "aws-sdk/clients/cloudfront";
import { map, startWith, tap, takeUntil, first } from "rxjs/operators";

// Dialogs
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EventEmitter } from "events";
import { email } from "@rxweb/reactive-form-validators";
import { exit } from "process";

import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import swal, { SweetAlertOptions } from "sweetalert2";

import { StripeService, StripeCardComponent } from "ngx-stripe";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import {
	StripeCardElementOptions,
	StripeElementsOptions,
} from "@stripe/stripe-js";
import { LoaderService } from "../../theme/loader.service";
export interface TemplateDialogData {
	templateName: string;
}
export interface SubPlans {
	value: string;
	name: string;
	id: string;
	cost: number;
	currency: string;
}

@Component({
	selector: "kt-dashboard",
	templateUrl: "./purchase-emails.component.html",
	styleUrls: ["purchase-emails.component.scss"],
})
export class PurchaseEmailsComponent implements OnInit, AfterViewInit {
	searchId: string;
	searchDetails: [];
	searchInfo: {};
	perEmailRate: 0.60;
	showCardInput: false;

	paymentinprogress: boolean = false;
	@ViewChild("simpleAlert", { static: true }) simpleAlert: SwalComponent;
	simpleAlertOption: SweetAlertOptions = {};
	@ViewChild("simpleDialog", { static: true }) simpleDialog: SwalComponent;
	simpleDialogOption: SweetAlertOptions = {};

	// Stripe
	@ViewChild(StripeCardComponent, { static: false })
	card: StripeCardComponent;

	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: "#666EE8",
				color: "#31325F",
				fontWeight: "300",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSize: "18px",
				"::placeholder": {
					color: "#CFD7E0",
				},
			},
		},
	};

	elementsOptions: StripeElementsOptions = {
		locale: "en",
	};
	stripeTest: FormGroup;

	constructor(
		private layoutConfigService: LayoutConfigService,
		private store: Store<AppState>,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		private http: HttpClient,
		private stripeService: StripeService,
		private location: Location,
		private router: Router,
		private loader:LoaderService
	) {
		let tempQS =  atob(router['currentUrlTree'].queryParams['qs'])

		if (tempQS) {
			this.searchInfo = JSON.parse(tempQS);
		}

		console.log('this.searchInfo', this.searchInfo);
		
		this.perEmailRate = 0.60;
	}
	
	sendData() {
		this.auth.sendData(this.searchInfo['search_id'], localStorage.getItem("id"))
		.subscribe(
			(res_data: any) => {
				console.log("sendData==>", res_data.result);

				this.loader.display(false);
				this.paymentinprogress = false;
				this.showCardInput = false;
				this.cdr.detectChanges();

				this.openSnackBar(`Data has been sent to your email(${localStorage.getItem("email")}) address...`, 'OK', "start", "top", 10000 );
			},
			(error) => {
				this.loader.display(false);
				this.paymentinprogress = false;
				this.showCardInput = false;
				this.cdr.detectChanges();
				this.cdr.detectChanges();

				this.openSnackBar(`Payment was completed but an error occurred while sending data to your email(${localStorage.getItem("email")}) address...`, 'OK', "start", "top", 10000 );
			}
		);
	}

	btnSubscribeClick() {
		this.paymentinprogress = true;
		this.cdr.detectChanges();
	}

	btnSecondClick() {		
		alert('You have already over paid. We will refund your extra amount as BizWizz credit.')

		let data = {
			user_id: localStorage.getItem('id'),
			stripe_id: localStorage.getItem('stripeId'),
			currency: 'usd',
			...this.searchInfo
		}

		data = Object.assign(data, this.searchInfo);

		console.log('input to direct pay', data);

		this.loader.display(true);
		this.cdr.detectChanges();

		this.auth.directPayment({data: JSON.stringify(data)}).subscribe((res_data:any)=>{
			console.log('User Refunded===>', res_data)

			this.openSnackBar(`Refund completed and credeitted to BizWizz Account. All the data will be sent to you email adress soon.
			`, 'OK', "right", "top", 1000 );


			this.loader.display(false);
			this.paymentinprogress = false;
			this.showCardInput = false;
			this.cdr.detectChanges();

			setTimeout(() => {
				this.location.go('/')
			}, 2000);

		}, (error) => {
			console.log('User Direct Payment FAILED===>', error)
			this.openSnackBar('Payment failed! ' + error.message, 'OK', "start", "top", 10000 );  
			this.loader.display(false);
			this.paymentinprogress = false;
			this.showCardInput = false;
			this.cdr.detectChanges();
		});
	}

	makePayment(token, bpp = false) {
		let data = {
			user_id: localStorage.getItem('id'),
			stripe_id: localStorage.getItem('stripeId'),
			token: token,
			currency: 'usd',
			...this.searchInfo,
			bpp: bpp
		}

		data = Object.assign(data, this.searchInfo);

		console.log('input to direct pay', data);
		
		this.loader.display(true);
		this.cdr.detectChanges();

		this.auth.directPayment({data: JSON.stringify(data)}).subscribe((res_data:any)=>{
			console.log('User Paid===>', res_data)

			if (this.searchInfo["notification_type"] == "FIRST_PAYMENT") {
				this.openSnackBar(`
				Payment completed. We have started the process to get and verify data.
				Once verification completed we will let you know.
				`, 'OK', "right", "top", 10000 );
			} else if (this.searchInfo["notification_type"] == "SECOND_PAYMENT") {
				this.openSnackBar(`
				Payment completed. We have started the process to send data to your email address.
				Once completed we will let you know.
				`, 'OK', "right", "top", 10000 );
			}

			this.loader.display(false);
			this.paymentinprogress = false;
			this.showCardInput = false;
			this.cdr.detectChanges();

			setTimeout(() => {
				this.router.navigate(['/search_list']);
			}, 2000);
			
		}, (error) => {
			console.log('User Direct Payment FAILED===>', error)
			this.openSnackBar('Payment failed! ' + error.message, 'OK', "start", "top", 10000 );  
			  this.loader.display(false);
			this.paymentinprogress = false;
			this.showCardInput = false;
			this.cdr.detectChanges();
		});
	}

	payToProceed(bpp): void {
		const name = this.stripeTest.get('name').value;

		this.stripeService
		  .createToken(this.card.element, { name })
		  .subscribe((result) => {
			if (result.token) {
				this.makePayment(result.token.id, bpp)
			} else if (result.error) {
			  	// Error creating the token
				this.openSnackBar('Error creating token for payment!', 'OK', "start", "top", 10000 );  
				this.paymentinprogress = false;
				this.showCardInput = false;
			  	console.log('Error creating the token', result.error.message);
			}
		  });
	}

	cancelPayment(): void {
		this.paymentinprogress = false;
		this.cdr.detectChanges();
	}
	
	ngOnInit(): void {
		this.initUserForm();
	}

	ngAfterViewInit() {
		this.cdr.markForCheck();
	}

	initUserForm() {
		this.stripeTest = this.fb.group({
			name: ["", [Validators.required]],
		});
	}
	openSnackBar(
		message: string,
		action: string,
		horizontal: MatSnackBarHorizontalPosition,
		vertical: MatSnackBarVerticalPosition,
		duration: integer
	) {
		this._snackBar.open(message, action, {
			duration: duration,
			horizontalPosition: horizontal,
			verticalPosition: vertical,
		});
	}
}
