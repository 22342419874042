import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../core/auth';
import swal,{ SweetAlertOptions } from 'sweetalert2';

declare var BizwizzTracker: any;
const PAGE_ID = 'unsub';

@Component({
  selector: 'kt-dashboard',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})

export class UnsubscribeComponent implements OnInit {
  @ViewChild('simpleAlert', { static: true }) simpleAlert: SwalComponent;
	simpleAlertOption:SweetAlertOptions = {};
	@ViewChild('simpleDialog', { static: true }) simpleDialog: SwalComponent;
	simpleDialogOption:SweetAlertOptions = {};
  userForm: FormGroup;
  allok: string = 'no';
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private auth: AuthService,) {
  
  }
  
  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParamMap.get('success') === "true") {
      this.allok = 'yes'
    }
  }
  
  onSubmit() {
  }
}
