import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<PrivacyPolicyComponent>) { }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
}
}
