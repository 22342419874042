import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from '../../../../core/auth';
import swal,{ SweetAlertOptions } from 'sweetalert2';

declare var BizwizzTracker: any;
const PAGE_ID = 'loyaltybrands';

@Component({
  selector: 'kt-dashboard',
  templateUrl: './loyaltybrands.component.html',
  styleUrls: ['./loyaltybrands.component.scss']
})

export class LoyaltyBrandsComponent implements OnInit {
  @ViewChild('simpleAlert', { static: true }) simpleAlert: SwalComponent;
	simpleAlertOption:SweetAlertOptions = {};
	@ViewChild('simpleDialog', { static: true }) simpleDialog: SwalComponent;
	simpleDialogOption:SweetAlertOptions = {};
  userForm: FormGroup;
  selectedImages = [];
  franchises = [
    {
      id: 'Atax',
      name: 'Professional Full-Service National Tax Preparation',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/06/atax-Logo_Color_Horizontal.png',
    },
    {
      id: 'Zooming Groomin',
      name: 'Home-Based Mobile Grooming',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Zoomin-Groomin.svg',
    },
    {
      id: 'Salt Suite',
      name: 'Changing Lives, One Breath at a Time®',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/The-Salt-Suite.svg',
    },
    {
      id: 'Little Medical School',
      name: 'Provides Curriculum For Children With A Focus On Healthcare',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Little-Medical-School.svg',
    },
    {
      id: 'Loyalty Business Brokers',
      name: 'Experts Connecting Buyers and Sellers',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Loyalty-Business-Brokers.svg',
    },
    {
      id: 'Ledgers',
      name: 'Helping Small Business Owners',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Ledgers.svg',
    },
    {
      id: 'Estrella Insurance',
      name: 'Property and Casualty Insurance Agency',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Estrella.png',
    },
    {
      id: 'The Inspection Boys',
      name: 'Honest and Authentic Home Inspection',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Inspection.png',
    },
    {
      id: 'Jomsom',
      name: 'Staffing and Outsourced Recruitment Services',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/05/Jomson.png',
    },
    {
      id: 'American Exteriors',
      name: 'Your Home Exterior Experts',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/11/C3-American-Exteriors-logo.png',
    },
    {
      id: 'Action Karate',
      name: 'A Black Belt School Building Character',
      img: 'https://loyaltybrands.com/wp-content/uploads/2022/11/ea00b05c-b9da-4c96-9903-b0276be34031_350.png',
    },
    {
      id: 'Bubbly',
      name: 'All-In-One Laundry And Dry-Cleaning Service',
      img: 'https://loyaltybrands.com/wp-content/uploads/2023/01/Bubblylogo.png',
    },
  ];
  isStarbucksAvailable: string = 'Y';
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private auth: AuthService,) {
    this.initForm();
  }
  
  ngOnInit() {
    this.initForm();
    this.auth.getStarBucksGiftCards('Y').subscribe((res_data:any)=>{
			// console.log('starbucks status==>',res_data, res_data.message.available.count);
      if (res_data.message.available.count > 0) {
        this.isStarbucksAvailable = 'Y';
        this.cdr.detectChanges();
      }
		},(error)=>{
			console.log('Error getting startbucks status', error)
		})
  }
  initForm(){
    this.userForm = this.fb.group({
      email: [ '', Validators.compose([
        Validators.required,
      ])],
      fname: [ '', Validators.compose([
        Validators.required,
      ])],
      lname: [ '', Validators.compose([
        Validators.required,
      ])],
      phone: [ '', Validators.compose([
        Validators.required,
      ])],
      region: [ '', Validators.compose([
        Validators.required,
      ])]
    });
  }
  
  onSubmit() {
    // check url params
    if (this.activatedRoute.snapshot.queryParamMap.get('bizwizzemail') === null || 
        this.activatedRoute.snapshot.queryParamMap.get('bizwizzuid') === null) {
        this.simpleAlertOption = {
          title:"The URL of this page is invalid!.",
          confirmButtonColor:"#3085d6",
          confirmButtonText:'OK',
          icon: 'error',
          focusConfirm: true
        }
        this.cdr.detectChanges()
        this.simpleAlert.fire();
        return;  
    }

    if (this.selectedImages.length <= 0) {
      this.simpleAlertOption = {
				title:"Please select atleast one franchise that you are interested in.",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'OK',
				icon: 'info',
				focusConfirm: true
			}
			this.cdr.detectChanges()
      this.simpleAlert.fire();
			return;
		}

    // check for form errors
    const controls = this.userForm.controls;
    if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.simpleAlertOption = {
				title:"Please fill up all required information.",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'OK',
				icon: 'info',
				focusConfirm: true
			}
			this.cdr.detectChanges()
      this.simpleAlert.fire();
			return;
		}
    
    
    // do the conversion
    const tracker = new BizwizzTracker();
    tracker.sendEvent('CONVERSION');
    // store in db and send email to user regarding the conversion
    let data = {
      subEmail: controls.email.value,
      subFname: controls.fname.value,
      subLname: controls.lname.value,
      subRegion: controls.region.value,
      subPhone: controls.phone.value,
      franchise: this.selectedImages.toString()
    };
    const payload = {
      data: JSON.stringify(data),
      uniqueCode: this.activatedRoute.snapshot.queryParamMap.get('bizwizzuid'),
      pageid: PAGE_ID
    }
    this.auth.landingPageDBEntry(payload).subscribe((res_data:any)=>{
			console.log('response==>',res_data);
		},(error)=>{
			console.log('Error2121', error)
		})
    // show confirmation
    this.simpleAlertOption = {
      title:"Data submitted!",
      confirmButtonColor:"#3085d6",
      confirmButtonText:'OK',
      icon: 'info',
      focusConfirm: true
    }
    this.cdr.detectChanges()
    this.simpleAlert.fire();
    this.userForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

  checkboxChanged(event:any) {
    console.log(event)
		if(event.target.checked === true) {
			this.selectedImages.push(event.target.id);
			console.log(`selected images`, this.selectedImages)
		} else if (event.target.checked === false) {
			try {
				const index = this.selectedImages.indexOf(event.target.id);
				if (index > -1) {
					this.selectedImages.splice(index, 1);
					console.log(`selected images`, this.selectedImages)
				}
				
			} catch (error) {
				
			}
		}
	}
}
