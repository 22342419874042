// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, Inject, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
// Auth
import { AuthNoticeService, AuthService, Register, Company, Login, SelectedExpenditure } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { BreadcrumbService } from 'angular-crumbs';
//snackbar
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { OTPDialogComponent } from '../otp-modal/otp-modal.component';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { MiniProfileDTO } from '../../../../../app/shared/model/mini-profile';
import { TreeComponent } from '../tree/tree.component';
import { LoaderService } from '../../../../../app/views/theme/loader.service';
import { ToastrService } from 'ngx-toastr';
import { date } from '@rxweb/reactive-form-validators';
declare function captureSignUp(): any;


@Component({
	selector: 'kt-company',
	templateUrl: './company.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./company.component.scss'],
	styles: ['table {width: 100%; }.kt-login__form{max-width: none !important;}.kt-login__body{}']
})

export class CompanyComponent implements OnInit, OnDestroy {

	@ViewChild('frmBuy', null) private frmBuy: NgForm;
	@ViewChild('frmSale', null) private frmSale: NgForm;
	@ViewChild('frmSaleProduct', null) private frmSaleProduct: NgForm;
	@ViewChild('frmBuyProduct', null) private frmBuyProduct: NgForm;
	frmBuyingProduct: FormGroup;
	buyingProductItems = new FormArray([]);
	isBuyingProduct = false;
	isSellingProduct = false;
	frmSellingProduct: FormGroup;
	sellingProductItems = new FormArray([]);
	frmExpeditureProduct: FormGroup;
	expeditureItems = new FormArray([]);
	buyingProductRange = [];
	sellingProductRange = [];
	companyForm: FormGroup;
	frmBuyingService: FormGroup;
	frmSellingService: FormGroup;
	loading = false;
	verified = true;
	errors: any = [];
	filteredSics: any = [];
	SicsBuyingService: any = [];
	filteredSicsBuyingService: any = [];
	SicsSellingService = [];
	filteredSicsSellingService = [];
	years = [new Date().getFullYear() - 1];
	employeeRanges: any = [
		'1 to 10', '10 to 25', '25 to 50', '50 to 100',
		'100 to 250', '250 to 500', '500+'
	];
	salesRanges = [];
	saleRangeBuyingService = [];
	expenditureRange = [];
	saleRangeSellingService = [];
	SICs: any = [];
	parentNodesLabel: any = [];
	site_key = '6Lfy1GsaAAAAAHu6ByW1gi5BXpJUxEOlbpLb2LWy'
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	options: ITreeOptions = {
		useCheckbox: true,
		allowDrag: (node) => {
			return false;
		},
		displayField: 'label',
		allowDrop: (node) => {
			return true;
		},
	};
	expenditures = [];
	buyingProductTree = [];
	sellingProductTree = [];
	errorExpense = false;
	breadcrumbList = [{ name: 1 }, { name: 1 }, { name: 1 }, { name: 1 }, { name: 1 },]
	selectedExpenditures: any = [];
	selectedBuyingProductTreedata = [];
	selectedSellingProductTreedata = [];
	selectedExpenseRange: any = [];
	phone: any;
	phoneStatus: any;
	phoneVerified: boolean = false;
	sicFilterCtrl = new FormControl('');
	sicSellingFilterCtrl = new FormControl('');
	sicbuyFilterCtrl = new FormControl('');
	industryFilterCtrl = new FormControl('');
	industryBuyingFilterCtrl = new FormControl('');
	industrySellingFilterCtrl = new FormControl('');
	countryFilterCtrl = new FormControl('');
	stateFilterCtrl = new FormControl('');
	cityFilterCtrl = new FormControl('');
	industries: any = [];
	filteredIndustry: any;
	filteredBuyingProduct: any;
	buyingProductFilterCtrl = new FormControl('');
	filteredSellingProduct: any;
	sellingProductFilterCtrl = new FormControl('');
	industriesBuyingService: any = [];
	filteredIndustryBuyingService: any = [];
	industriesSellingService: any = [];
	filteredIndustrySellingService: any = [];
	countries: any = [];
	filteredCountries: any = [];
	states: any = [];
	filteredstates: any = [];
	cities: any = [];
	filteredCities: any = [];
	miniProfileDTO: MiniProfileDTO = new MiniProfileDTO();
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.China, CountryISO.India];
	Phone: 'Phone';
	formattedAddress = '';
	lat: any = [];
	lng: any = [];
	houseNumber: string = '';
	street: string = '';
	mapPaostalCode: any = 0;
	mapAddress: any = {};
	mapNeighborhood: string = '';
	mapCounty: string = '';
	mapCity: string = '';
	mapcountry: string = '';
	mapState: string = '';
	mapUserAddress: string = '';
	IfProductExist:boolean=true;
	/**
	 * Component constructor
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private toastr: ToastrService,
		private authNoticeService: AuthNoticeService,
		private auth: AuthService,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private loaderService: LoaderService,
		private breadcrumbService: BreadcrumbService
	) {
		this.unsubscribe = new Subject();

		this.frmBuyingProduct = this.fb.group({
			buyingProductItems: this.fb.array([])
		});
		this.loaderService.display(true);
		this.frmSellingProduct = this.fb.group({
			sellingProductItems: this.fb.array([])
		});

		this.frmExpeditureProduct = this.fb.group({
			expeditureItems: this.fb.array([])
		});

		if (localStorage.getItem('user') == null || localStorage.getItem('mini-profiledata') == null) {
			this.router.navigateByUrl('/'); // Main page
			return;
		}
		this.miniProfileDTO = JSON.parse(localStorage.getItem('mini-profiledata'));

		if (this.route.snapshot.data.masterdata.status) {
			console.log(this.route.snapshot.data.masterdata.data);
			this.employeeRanges = this.route.snapshot.data.masterdata.data.employeeRange;
			this.salesRanges = this.route.snapshot.data.masterdata.data.salesRange;

			this.saleRangeBuyingService = this.route.snapshot.data.masterdata.data.salesRange;
			this.saleRangeSellingService = this.route.snapshot.data.masterdata.data.salesRange;
			this.expenditureRange = this.route.snapshot.data.masterdata.data.salesRange;


			this.buyingProductRange = this.route.snapshot.data.masterdata.data.salesRange;
			this.sellingProductRange = this.route.snapshot.data.masterdata.data.salesRange;
			this.cdr.markForCheck();
		}

		this.getAllCountry();

		this.auth.getExpenditure().subscribe((res: any) => {

			console.log('#RES', res);
			this.expenditures = res.data;
			console.log('#expenditures', this.expenditures);
			this.cdr.markForCheck();
			this.cdr.detectChanges();
		});

		
	}


	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	*/

	/**
	 * On init
	 */

	setProductBuyingItem() {
		if (this.selectedBuyingProductTreedata.length > 0) {
			this.isBuyingProduct = true;
			this.frmBuyingProduct = this.fb.group({
				buyingProductItems: this.fb.array([])
			});
			for (let index = 0; index < this.selectedBuyingProductTreedata.length; index++) {
				let element = this.selectedBuyingProductTreedata[index];

				this.buyingProductItems = this.frmBuyingProduct.get('buyingProductItems') as FormArray;
				this.buyingProductItems.push(
					this.fb.group({
						id: new FormControl(element.id, Validators.required),
						name: new FormControl(element.name, Validators.required),
						range: new FormControl('', Validators.required),
						date: new FormControl('', Validators.required),
					}));
			}
			this.cdr.detectChanges();
		}
		else {
			this.isBuyingProduct = true;
		}
	}

	setProductSellingItem() {
		if (this.selectedSellingProductTreedata.length > 0) {
			this.isSellingProduct = true;
			this.frmSellingProduct = this.fb.group({
				sellingProductItems: this.fb.array([])
			});
			for (let index = 0; index < this.selectedSellingProductTreedata.length; index++) {
				let element = this.selectedSellingProductTreedata[index];

				this.sellingProductItems = this.frmSellingProduct.get('sellingProductItems') as FormArray;
				this.sellingProductItems.push(
					this.fb.group({
						id: new FormControl(element.id, Validators.required),
						name: new FormControl(element.name, Validators.required),
						range: new FormControl('', Validators.required),
						date: new FormControl('', Validators.required),
					}));
			}
			this.cdr.detectChanges();
		}
		else {
			this.isSellingProduct = true;
		}
	}

	getAllCountry() {
		if (this.route.snapshot.data.masterdata.status) {
			this.countries = this.route.snapshot.data.masterdata.data.countryList;
			this.filteredCountries = this.route.snapshot.data.masterdata.data.countryList;
			this.filteredstates = this.route.snapshot.data.masterdata.data.stateList;
			this.states = this.route.snapshot.data.masterdata.data.stateList;
			// this.auth.getMiniProfileMasterData({mode:'company',continentId:this.miniProfileDTO.continentId,countryId:this.miniProfileDTO.countryId}).subscribe((x)=>{
			// 	this.loaderService.display(false);
			// 	let companyList=x['data'];
			// 	const companyName =companyList.filter(x=>x.id==this.miniProfileDTO.companyId)[0].companyname;
			// 	this.companyForm.patchValue({
			// 		country:this.miniProfileDTO.countryId,
			// 		companyName:companyName
			// 	});
			//   });
		}
	}

	sellingProductList = [];
	buyingProductList = [];
	buyingServiceList = [];
	sellingServiceList = [];

	onBuyingSubmit() {
		debugger;
		if(this.filteredIndustryBuyingService) {
			const industryObj = this.filteredIndustryBuyingService.filter(x => x.sic_code == this.frmBuyingService.value.industryId)[0];
			const prodObj = this.filteredIndustryBuyingService.filter(x => x.id == this.frmBuyingService.value.industryId)[0];
			if (industryObj) {
				//const sicObj=this.filteredSicsBuyingService.filter(x=>x.sic_code==this.frmBuyingService.value.sic_code_Id)[0];
				if (industryObj) {
					this.frmBuyingService.patchValue({
						industry: { id: industryObj.id, name: industryObj.name },
						industryId:industryObj.id,
						industryName: industryObj.name,
						sic_code: { sic_code: industryObj.sic_code, sic_description: industryObj.sic_description },
						sic_description: industryObj.sic_description
					});
				}
				let ifExist = this.buyingServiceList.filter(x => x.industry.id == this.frmBuyingService.value.industry.id
					&& x.sic_code.sic_code == this.frmBuyingService.value.sic_code.sic_code)

				if (ifExist.length > 0) {
					this.toastr.warning('same record already added', 'Warning');
					return;
				} else {
					this.buyingServiceList.push(this.frmBuyingService.value);
					this.frmBuy.resetForm();
					this.setDefaultIndustryAndSicCode();
					this.frmBuyingService.patchValue({
						date: new Date().getFullYear() - 1
					});
				}
			}
			if(prodObj) {
				if (prodObj) {
					this.frmBuyingProduct.patchValue({
						product: { id: prodObj.id, name: prodObj.name },
						productId:this.frmBuyingService.value.industryId,
						range:this.frmBuyingService.value.range
					});
				}
				let ifExist = this.buyingProductList.filter(x => x.product.id == this.frmBuyingProduct.value.product.id
					&& x.date == this.frmBuyingService.value.date
					&& x.range == this.frmBuyingService.value.range);
				if (ifExist.length > 0) {
					this.toastr.warning('same record already added', 'Warning');
					return;
				} else {
					this.buyingProductList.push(this.frmBuyingProduct.value);
					this.frmBuy.resetForm();
					this.frmBuyingProduct.patchValue({
						date: new Date().getFullYear() - 1
					});
				}
			}

		}
		
	}

	onSellingSubmit(){	
		
		const industryObj=this.filteredIndustrySellingService.filter(x=>x.id==this.frmSellingService.value.industryId)[0];
		const prodObj = this.filteredIndustrySellingService.filter(x => x.id == this.frmSellingService.value.industryId)[0];

		if(industryObj.sic_code) {
			if(industryObj){
				this.frmSellingService.patchValue({
					industry:{id:industryObj.id,name:industryObj.name},
					industryId:industryObj.id,
					industryName: industryObj.name,
					sic_code:{sic_code:industryObj.sic_code,sic_description:industryObj.sic_description},
					sic_description:industryObj.sic_description
				});
				 
			}
	
			let ifExist=this.sellingServiceList.filter(x=>x.industry.id==this.frmSellingService.value.industry.id 
				&& x.sic_code.sic_code== this.frmSellingService.value.sic_code.sic_code)
			
				if(ifExist.length>0){
					this.toastr.warning('same record already added','Warning');
					return;
				}else{
					this.sellingServiceList.push(this.frmSellingService.value);
					this.frmSale.resetForm();				
					this.setDefaultIndustryAndSicCode();
					this.frmSellingService.patchValue({
						date:new Date().getFullYear()-1
					});
				}
		}

		if(industryObj.Product) {
			if (prodObj) {
				this.frmSellingProduct.patchValue({
					product: { id: prodObj.id, name: prodObj.name },
					productId:this.frmSellingService.value.industryId,
					range:this.frmSellingService.value.range
				});
			}
			let ifExist = this.sellingProductList.filter(x => x.product.id == this.frmSellingService.value.industryId
				&& x.date == this.frmSellingService.value.date
				&& x.range == this.frmSellingService.value.range);
			if (ifExist.length > 0) {
				this.toastr.warning('same record already added', 'Warning');
				return;
			} else {
				this.sellingProductList.push(this.frmSellingProduct.value);
				this.frmSale.resetForm();
				this.frmSellingProduct.patchValue({
					date: new Date().getFullYear() - 1
				});
			}
		}


		
	}

	ngOnInit() {

		this.initCompanyForm();
		this.companyForm.controls['phone'].valueChanges.subscribe(() => {
			this.phoneVerified = false;
		});
		this.sicFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed sic:', value)
			this._filterSics(value);
		});
		this.industryFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed industry:', value)
			this._filterIndustries(value);
		});
		this.industrySellingFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed industry selling:', value)
			this._filtersell_Industries(value);
		});
		this.industryBuyingFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed industry buying:', value)
			this._filterbuy_Industries(value);
		});
		this.sellingProductFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed selling product:', value)
			if (value.length > 2) {
				this._filtersell_Products(value);
			}
		});
		this.buyingProductFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed buying product:', value)
			if (value.length > 2) {
				this._filterbuy_Products(value);
			}
		});
		this.sicSellingFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed sic selling:', value)
			this._filtersell_Sics(value);
		});
		this.sicbuyFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed sic buying:', value)
			this._filterbuy_Sics(value);
		});
		this.countryFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed country filter:', value)
			this._filterCountry(value);
		});
		this.stateFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed state filter:', value)
			this._filterStates(value);
		});
		this.cityFilterCtrl.valueChanges.subscribe(value => {
			console.log('name has changed city:', value)
			this._filterCities(value);
		});
		this.getCompanyDataById();

	}

	_filterCountry(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredCountries = this.countries.filter(country => country.name.toLowerCase().includes(valueLowerCase))
	}

	_filterStates(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredstates = this.states.filter(state => state.name.toLowerCase().includes(valueLowerCase))
	}

	_filterCities(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredCities = this.cities.filter(city => city.name.toLowerCase().includes(valueLowerCase))
	}

	_filterSics(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredSics = this.SICs.filter(sic => sic.sic_description.toLowerCase().includes(valueLowerCase))
	}

	_filtersell_Sics(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredSicsSellingService = this.SicsSellingService.filter(sic => sic.sic_description.toLowerCase().includes(valueLowerCase))
	}

	_filterbuy_Sics(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.filteredSicsBuyingService = this.SicsBuyingService.filter(sic => sic.sic_description.toLowerCase().includes(valueLowerCase))
	}

	// _filterIndustries(value: any) {
	// 	this.auth.searchIndustry(value).subscribe((res: any) => {
	// 		let servicedata = res.data.docs;
	// 		let prodata = res.dataProduct.docs;
	// 		let finalObj = servicedata.concat(prodata);
	// 		//distinct value code
	// 		const result = [];
	// 		const map = new Map();
	// 		for (const item of finalObj) {
	// 			if (!map.has(item.name)) {
	// 				map.set(item.name, true);    // set any value to Map
	// 				result.push(item);
	// 			}
	// 		}
	// 		console.log("result==>",result);
	// 		this.filteredIndustry = result;
	// 		this.filteredIndustrySellingService = result;

	// 		console.log("this.filteredIndustry",this.filteredIndustry);
	// 		console.log("this.filteredIndustrySellingService",this.filteredIndustrySellingService);
	// 		this.cdr.markForCheck();
	// 	});
	// }

	_filterIndustries(value: any) {			
		this.auth.searchIndustry(value).subscribe((res: any) =>{
			const result = [];
			const map = new Map();
			for (const item of res.data.docs) {
				if (!map.has(item.name)) {
					map.set(item.name, true);    // set any value to Map
					result.push(item);
				}
			}
			//this.filteredIndustry = res.data.docs;
			this.filteredIndustry = result;
			this.cdr.markForCheck();
			});
		 }

	_filtersell_Industries(value: any) {
		this.auth.searchIndustry(value).subscribe((res: any) => {
			let servicedata = res.data.docs;
			let prodata = res.dataProduct.docs;
			let finalObj = servicedata.concat(prodata);
			//distinct value code
			const result = [];
			const map = new Map();
			for (const item of finalObj) {
				if (!map.has(item.name)) {
					map.set(item.name, true);    // set any value to Map
					result.push(item);
				}
			}
			console.log("result",result);
			this.filteredIndustrySellingService = result;
			this.cdr.markForCheck();
		});
		
	}

	_filterbuy_Industries(value: any) {
		this.auth.searchIndustry(value).subscribe((res: any) => {
			let servicedata = res.data.docs;
			let prodata = res.dataProduct.docs;
			let finalObj = servicedata.concat(prodata);
			//distinct value code
			const result = [];
			const map = new Map();
			for (const item of finalObj) {
				if (!map.has(item.name)) {
					map.set(item.name, true);    // set any value to Map
					result.push(item);
				}
			}
			console.log("result==>",result);
			this.filteredIndustryBuyingService = result;
			this.cdr.markForCheck();
		});
	}

	_filterbuy_Products(value: any) {
		this.auth.searchProduct(value).subscribe((res: any) => {
			this.filteredBuyingProduct = res.data.docs;
			this.cdr.markForCheck();
		});
	}

	_filtersell_Products(value: any) {
		this.auth.searchProduct(value).subscribe((res: any) => {
			this.filteredSellingProduct = res.data.docs;
			console.log(this.filteredBuyingProduct);
			this.cdr.markForCheck();
		});
	}

	OnIndustryChange() {
		debugger
		this.SICs = null;
		this.filteredSics = null;
		let value = this.companyForm.controls["industry"].value;
		let valuetype = typeof(this.companyForm.controls["industry"].value);
		if (value != null && value != "None" && valuetype =="object") {
			this.IfProductExist = true;
			this.auth.getSICs({ industry_id: value }).subscribe((res: any) => {
				console.log('#RES', res);
				this.SICs = res.data;
				this.filteredSics = res.data;
				this.SicsBuyingService = res.data;
				this.filteredSicsBuyingService = res.data;
				this.SicsSellingService = res.data;
				this.filteredSicsSellingService = res.data;
				this.cdr.markForCheck();
			});
		}
		else {
			this.IfProductExist = false;
				const prodObj = this.filteredSellingProduct.filter(x => x.id == this.frmSellingProduct.value.productId)[0];
				if (prodObj) {
					this.frmSellingProduct.patchValue({
						product: { id: prodObj.id, name: prodObj.name },
					});
				}
				let ifExist = this.sellingProductList.filter(x => x.product.id == this.frmSellingProduct.value.product.id
					&& x.date == this.frmSellingProduct.value.date
					&& x.range == this.frmSellingProduct.value.range);
				if (ifExist.length > 0) {
					this.toastr.warning('same record already added', 'Warning');
					return;
				} else {
					this.sellingProductList.push(this.frmSellingProduct.value);
					this.frmSaleProduct.resetForm();
					this.frmSellingProduct.patchValue({
						date: new Date().getFullYear() - 1
					});
				}

		}

	}

	OnIndustryChangeForBuyingService() {
		this.SICs = null;
		this.filteredSicsBuyingService = null;
		this.SicsBuyingService = null;
		let value = this.frmBuyingService.value.industryId;// this.frmBuyingService.controls["industry"].value.id; //this.frmBuyingService.value.industryId; 
		if (value != null && value != "None") {
			this.auth.getSICs({ industry_id: value }).subscribe((res: any) => {
				console.log('#RES', res);
				this.SICs = res.data;
				this.SicsBuyingService = res.data;
				this.filteredSicsBuyingService = res.data;
				this.cdr.markForCheck();
			});
		}
	}

	OnIndustryChangeForSellingService() {
		this.SICs = null;
		this.filteredSicsSellingService = null;
		this.SicsSellingService = null;
		let value = this.frmSellingService.value.industryId;
		if (value != null && value != "None") {
			this.auth.getSICs({ industry_id: value }).subscribe((res: any) => {
				console.log('#RES', res);
				this.SICs = res.data;
				this.SicsSellingService = res.data;
				this.filteredSicsSellingService = res.data;
				this.cdr.markForCheck();
			});
		}
	}

	//#region -------buying and selling product event
	removeBuyingProduct(item, index) {
		//this.buyingProductItems.removeAt(index);
		for (var i = 0; i <= this.buyingProductList.length - 1; i++) {
			if (i == index) {
				this.buyingProductList.splice(i, 1);
				break;
			}
		}
	}

	removeSellingProduct(item, index) {
		//this.sellingProductItems.removeAt(index);
		for (var i = 0; i <= this.sellingProductList.length - 1; i++) {
			if (i == index) {
				this.sellingProductList.splice(i, 1);
				break;
			}
		}
	}

	removeBuyingService(item, index) {
		for (var i = 0; i <= this.buyingServiceList.length - 1; i++) {
			if (i == index) {
				this.buyingServiceList.splice(i, 1);
				break;
			}
		}
	}

	removeSellingService(item, index) {
		for (var i = 0; i <= this.sellingServiceList.length - 1; i++) {
			if (i == index) {
				this.sellingServiceList.splice(i, 1);
				break;
			}
		}
	}

	OnCountryChange() {
		this.states = null;
		this.filteredstates = null;
		let value = this.companyForm.controls["country"].value;
		if (value != null && value != "None") {
			this.auth.getState(value).subscribe((res: any) => {

				this.states = res.data;
				this.filteredstates = res.data;
				this.cdr.markForCheck();
			});
		}
	}

	OnStateChange() {
		this.cities = null;
		this.filteredCities = null;
		let value = this.companyForm.controls["state"].value;
		if (value != null && value != "None") {
			this.auth.getCity(value).subscribe((res: any) => {
				this.cities = res.data;
				this.filteredCities = res.data;
				this.cdr.markForCheck();
			});
		}
	}

	onSelectedChange(event) {
		console.log("#event", event);
	}

	onFilterChange(event) {
		console.log('#onFilterChange', event);
	}

	isVerified() {
		return this.verified;
	}

	onEvent(event) {
		console.log('#event', event);
		this.extractSelectedNodeData(event.node)
	}

	extractSelectedNodeData(node) {
		console.log('#extractSelectedNodeData', node);

		if (node && node.data && node.data.children && node.data.children.length == 0) {
			;
			console.log('#node.data', node.data);
			let nodeData = node.data;
			this.parentNodesLabel = [];
			let nodeParent = this.getNodeParents(node);
			console.log('#nodeParent', nodeParent);
			console.log('#parentNodesLabel', this.parentNodesLabel);
			let selectedExpenditure = new SelectedExpenditure();
			let isExists = this.selectedExpenditures.find(expense => expense.id == node.data.id);
			console.log('#isExists', isExists);
			if (!isExists) {
				selectedExpenditure.id = node.data.id;
				selectedExpenditure.name = node.data.label;
				selectedExpenditure.range = node.data.options;
				selectedExpenditure.path = this.parentNodesLabel;
				this.selectedExpenditures.push(selectedExpenditure);
			} else {
				this.openSnackBar('Expense already added');
			}
			console.log('#selectedExpenditures', this.selectedExpenditures);
		}
	}

	getNodeParents(node) {
		let parentName = node.data.label;
		if (node.parent !== null) {
			this.parentNodesLabel.splice(0, 0, { name: node.data.label });
			return this.getNodeParents(node.parent);
		}
		else {
			console.log('parent', node, node.data.label);
			return node.data.label;
		}
	}

	resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response: ${captchaResponse}`);
		this.auth.verifyRecaptcha(captchaResponse).subscribe((res: any) => {
			console.log('#res', res);
			this.verified = false;
			console.log('#verified', this.verified);
			this.cdr.markForCheck();
		}, (error: any) => {
			this.verified = true;
			console.log('#error', error);
			this.cdr.markForCheck();
		});
	}

	/*On destroy*/
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.verified = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initCompanyForm() {

		this.frmBuyingService = this.fb.group({
			industryId: ['', Validators.compose([
				Validators.required,
			])],
			industry: [''],
			industryName: ['', Validators.compose([

			])],
			sic_code: [''],

			sic_description: ['', Validators.compose([
			])],
			date: [new Date().getFullYear() - 1, Validators.compose([
				Validators.required,
			])],
			range: ['', Validators.compose([
				Validators.required,
			])],
		});
		this.frmSellingService = this.fb.group({
			industryId: ['', Validators.compose([
				Validators.required,
			])],
			industry: [''],
			industryName: ['', Validators.compose([

			])],
			sic_code: [''],
			sic_description: ['', Validators.compose([
			])],
			date: [new Date().getFullYear() - 1, Validators.compose([
				Validators.required,
			])],
			range: ['', Validators.compose([
				Validators.required,
			])],
		});
		this.frmSellingProduct = this.fb.group({
			productId: ['', Validators.compose([
				Validators.required,
			])],
			product: [''],
			date: [new Date().getFullYear() - 1, Validators.compose([
				Validators.required,
			])],
			range: ['', Validators.compose([
				Validators.required,
			])],
		});
		this.frmBuyingProduct = this.fb.group({
			productId: ['', Validators.compose([
				Validators.required,
			])],
			product: [''],
			date: [new Date().getFullYear() - 1, Validators.compose([
				Validators.required,
			])],
			range: ['', Validators.compose([
				Validators.required,
			])],
		});
		this.companyForm = this.fb.group({
			companyName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			websiteLink: ['', Validators.compose([
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			industry: ['', Validators.compose([
				Validators.required,
				Validators.minLength(1),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
			// sicCode: ['', Validators.compose([
			// 	Validators.required
			// ])
			// ],
			country: [''],
			city: [''],
			state: [''],
			zip: [''],
			phone: [''],
			fax: [''],
			employeeRange: ['', Validators.compose([
				Validators.required,
			])
			],
			// businessType: ['', Validators.compose([
			// 	Validators.required,
			// ])
			// ],
			salesVolumeRange: ['', Validators.compose([
				Validators.required,
			])
			],
			address: ['', Validators.compose([
				Validators.required,
			])
			],
		}, {
			// validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	isMinExpensSelected() {
		if (this.selectedExpenditures.length < 3) {
			return true;
		}
		for (let i = 0; i < this.selectedExpenditures.length; i++) {
			let result = this.isRangeSelected(this.selectedExpenditures[i].id);
			console.log('---', result, '---', this.selectedExpenditures[i].name);

			if (result) {
				return result;
			}
		}
		return false;
	}

	expenseSelectionChange(event, id) {
		console.log('#expenseSelectionChange', event, id);

		let index = this.selectedExpenditures.findIndex(expenditure => expenditure.id == id);
		if (index > -1) {
			this.selectedExpenditures[index].selectedRange = event.value == "Select range" ? '' : event.value;
		}

		console.log('#selectedExpenditures', this.selectedExpenditures);

	}

	onBuyingProducrtRangeChange(event, id) {
		console.log('#expenseSelectionChange', event, id);

		let index = this.buyingServiceList.findIndex(expenditure => expenditure.id == id);
		if (index > -1) {
			this.buyingServiceList[index].selectedRange = event.value == "Select range" ? '' : event.value;
		}

		console.log('#selectedExpenditures', this.buyingServiceList);

	}

	expenseSelectionChange1(event) {
		console.log('#expenseSelectionChange1', event);

	}

	getCompanyDataById() {

		let req = {
			continentId: this.miniProfileDTO.continentId,
			companyId: this.miniProfileDTO.companyId
		}
		this.auth.getCompanyDataById(req).subscribe((resp) => {
			console.log("resp----->", resp);
			console.log("this.miniProfileDTO", this.miniProfileDTO);
			const companyInfo = resp.data.companyData[0];
			this.filteredCities = resp.data.cityList;
			console.log("companyInfo.cityId----->", resp.data.companyData[0].city);
			this.companyForm.patchValue({
				companyName: companyInfo.companyname,
				websiteLink: companyInfo.website
			});
			this.bindGrid(resp.data.sellingService, 'sellingService');
			this.bindGrid(resp.data.buyingService, 'buyingService');
			this.bindGrid(resp.data.sellingProduct, 'sellingProduct');
			this.bindGrid(resp.data.buyingProduct, 'buyingProduct');
			this.bindGrid(resp.data.expanditureData, 'expanditureData');


			if (companyInfo.indistry != "") {
				this.auth.getSICs({ industry_id: companyInfo.indistry }).subscribe((res: any) => {

					this.loaderService.display(false);
					this.SICs = res.data;
					this.filteredSics = res.data;

					this.companyForm.patchValue({
						companyName: companyInfo.companyname,
						websiteLink: companyInfo.website,
						industry: parseInt(companyInfo.indistry),
						sicCode: companyInfo.sic_code,
						country: companyInfo.country_id,
						state: parseInt(companyInfo.state),
						city: parseInt(companyInfo.city),
						address: companyInfo.address,
						fax: companyInfo.faxnumber,
						employeeRange: companyInfo.employee_range,
						salesVolumeRange: companyInfo.sales_volume_range,
						zip: companyInfo.zip,
					});
					this.setDefaultIndustryAndSicCode();
					this.cdr.detectChanges();
				});
			} else {
				this.loaderService.display(false);
				this.companyForm.patchValue({
					country: this.miniProfileDTO.countryId,
					companyName: companyInfo.companyname
				});
				this.cdr.detectChanges();
			}
			if (resp.data.companyData.length > 0) {
				if (companyInfo.city != "" || companyInfo.city != undefined) {
					let cityId = parseInt(companyInfo.city);
					this.auth.getCityName(cityId).subscribe((res: any) => {
						this.mapCity = res.data.name;
						this.cdr.detectChanges();
					});
				}
				if (companyInfo.state != "" || companyInfo.state != undefined) {
					let stateId = parseInt(companyInfo.state);
					this.auth.getStateName(stateId).subscribe((res: any) => {
						this.mapState = res.data[0].name;
						this.cdr.detectChanges();
					});
				}
				let countryID = companyInfo.country_id;
				this.auth.getCountryName(countryID).subscribe((res: any) => {
					this.loaderService.display(true);
					this.mapcountry = res.data.name;
					this.loaderService.display(false);
					this.cdr.detectChanges();
				});
				this.mapPaostalCode = companyInfo.zip;
			}


		});

	}

	bindGrid(array, type) {

		let arr = [];
		if (type == 'sellingService' || type == 'buyingService') {
			array.forEach(element => {
				let industry = {
					id: element.id,
					name: element.name
				}
				let sic_code = {
					sic_code: element.sic_code,
					sic_description: element.sic_description
				}
				let item = {
					industry: industry,
					sic_code: sic_code,
					date: element.year,
					range: element.c_range
				}
				arr.push(item);
			});
			if (type == 'sellingService') {
				this.sellingServiceList = arr;
			} if (type == 'buyingService') {
				this.buyingServiceList = arr;
			}
		} else {
			if (type == 'sellingProduct' || type == 'buyingProduct') {
				array.forEach(element => {
					let product = {
						id: element.id,
						name: element.name
					}
					let item = {
						product: product,
						date: element.year,
						range: element.c_range
					}
					arr.push(item);
				});
				if (type == 'sellingProduct') {
					this.sellingProductList = arr;
				}
				if (type == 'buyingProduct') {
					this.buyingProductList = arr;
				}
			}
			if (type == 'expanditureData') {

				this.expeditureItems = new FormArray([]);
				this.expeditureItems = this.frmExpeditureProduct.get('expeditureItems') as FormArray;

				array.forEach(element => {
					this.expeditureItems.push(
						this.fb.group({
							id: new FormControl(element.expenditures),
							name: new FormControl(element.name_with_parent),
							range: new FormControl(element.expenserange),
						}));
				});
				this.cdr.detectChanges();
			}
		}
	}

	submit() {
		const controls = this.companyForm.controls;
		if (this.companyForm.invalid) {
			// Object.keys(controls).forEach(controlName =>
			// 	controls[controlName].markAsTouched()
			// );
			Object.keys(controls).forEach(key => {
				this.companyForm.controls[key].markAsTouched();
				let isValid=this.companyForm.controls[key].valid;
				if(!isValid) {
					this.toastr.warning('Please enter '+ key, 'Warning');
				}
				
			});
			return;
			
		}
		let sellingProductListlength = this.sellingProductList.length;
		let sellingServiceListlength = this.sellingServiceList.length;
		let buyingServiceListlength = this.buyingServiceList.length;
		let buyingProductListlength = this.buyingProductList.length;

		if (sellingProductListlength == 0) {
			if (sellingServiceListlength == 0) {
				this.toastr.warning('Select Atleast one value from What You Sell', 'Warning');
				return;
			}
		}

		if (sellingServiceListlength == 0) {
			if (sellingProductListlength == 0) {
				this.toastr.warning('Select Atleast one value from What You Sell', 'Warning');
				return;
			}
		}

		if (buyingServiceListlength == 0) {
			if (buyingProductListlength == 0) {
				this.toastr.warning('Select Atleast one value from What You Buy', 'Warning');
				return;
			}
		}

		if (buyingProductListlength == 0) {
			if (buyingServiceListlength == 0) {
				this.toastr.warning('Select Atleast one value from What You Buy', 'Warning');
				return;
			}
		}

		this.loading = true;
		let _company: Company = new Company();
		_company.clear();
		_company.companyName = controls.companyName.value;
		_company.industry = parseInt(controls.industry.value).toString();
		//_company.sicCode = controls.sicCode.value;
		_company.address = controls.address.value;
		_company.city = controls.city.value;
		_company.state = controls.state.value;
		_company.country = controls.country.value;
		_company.phone = controls.phone.value && controls.phone.value.internationalNumber;
		_company.fax = controls.fax.value;
		_company.zip = controls.zip.value;
		_company.employeeRange = controls.employeeRange.value;
		_company.salesVolumeRange = controls.salesVolumeRange.value;
		_company.websitelink = controls.websiteLink.value;
		_company.emailAddress = localStorage.getItem('email');
		_company.expenses = this.expeditureItems.value;

		console.log("#_company", _company);
		let id = localStorage.getItem('user');

		let requestData = {
			sellService: this.sellingServiceList,
			buyService: this.buyingServiceList,
			sellProduct: this.sellingProductList,
			buyProduct: this.buyingProductList,
			expenditure: this.expeditureItems.value,
			companyData: _company,
			continentId: this.miniProfileDTO.continentId,
			companyId: this.miniProfileDTO.companyId,
			userid: id
		}

		let requestBody = {
			body: JSON.stringify(requestData)
		};



		this.auth.saveCompanyData_New(requestBody).subscribe((res: any) => {
			this.toastr.success('Information has been updated', 'Success');
			// captureSignUp();
			console.log("#res_register", res);
			this.loading = false;
			this.cdr.markForCheck();
			this.router.navigateByUrl('/auth/billing'); // Main page
		}, err => {
			this.authNoticeService.setNotice(err.error && err.error.errorMsg || "Something went wrong", 'danger');
			console.log("#error rej", err);
			this.loading = false;
			this.cdr.markForCheck();
		});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors label
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.companyForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	openSnackBar(message: string) {
		this._snackBar.open(message, '', {
			duration: 1000,
		});
	}

	isRangeSelected(id) {
		let selectedExpenditure = this.selectedExpenditures.find(expenditure => expenditure.id == id);

		if (!this.companyForm.touched) {
			return false;
		}
		if (selectedExpenditure.selectedRange && selectedExpenditure.selectedRange.trim() != '') {
			return false
		}
		return true;
	}

	openDialog(): void {
		let phone = this.companyForm.controls.phone.value;
		let id = localStorage.getItem('id');
		console.log('#phone', phone);
		phone = `${phone.dialCode}${phone.number}`;
		this.auth.sendOTP(phone, id).subscribe((res) => {
			this.toastr.success('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
		}, error => {
			this.toastr.error('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
		});
		const dialogRef = this.dialog.open(OTPDialogComponent, {
			width: '310px',
			data: {
				phone,
			},
		});
		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				// snack.dismiss();
				this.phoneVerified = confirmed;
				this.cdr.markForCheck();
				this.toastr.success('Mobile number has ben verified successfully', 'Success');
			}
		});
	}

	setDefaultIndustryAndSicCode() {
		this.filteredIndustrySellingService = this.filteredIndustry;
		this.filteredSicsSellingService = this.filteredSics;
		this.filteredIndustryBuyingService = this.filteredIndustry;
		this.filteredSicsBuyingService = this.filteredSics;
		this.cdr.detectChanges();
	}

	handleAddressChange(event): void {
		this.resetMapData();
		this.formattedAddress = event.formatted_address;
		console.log("event", event);
		for (let i = 0; i < event.address_components.length; i++) {
			this.mapAddress = event.address_components[i];
			if (this.mapAddress.long_name != '') {
				switch (this.mapAddress.types[0]) {
					case "street_number":
						this.houseNumber = this.mapAddress.long_name;
						break;
					case "route":
						this.street = this.mapAddress.long_name;
						break;
					case "locality":
						this.mapCity = this.mapAddress.long_name;
						break;
					case "administrative_area_level_1":
						this.mapState = this.mapAddress.long_name;
						break;
					case "country":
						this.mapcountry = this.mapAddress.long_name;
						break;
					case "postal_code":
						this.mapPaostalCode = this.mapAddress.long_name;
						break;
					case "neighborhood":
						this.mapNeighborhood = this.mapAddress.long_name;
						break;
					case "administrative_area_level_2":
						this.mapCounty = this.mapAddress.long_name;
						break;
					default:
						console.log("No such address exists!");
						break;
				}
			}
			this.cdr.detectChanges();
		}
		this.setNewValues();
	}

	private setNewValues() {
		this.auth.getCountyId(this.mapcountry).subscribe((resp: any) => {
			this.companyForm.patchValue({
				country: resp.data.id,
			});
			this.auth.getStateId(this.mapState, resp.data.id).subscribe((res: any) => {
				this.companyForm.patchValue({
					state: res.data[0].id,
				});

			});
		});

		this.auth.getCityId(this.mapCity).subscribe((res: any) => {
			this.companyForm.patchValue({
				city: res.data.id,
			});
		});

		this.companyForm.patchValue({
			address: this.formattedAddress,
			zip: this.mapPaostalCode,
		});

		this.cdr.detectChanges();
	}

	private resetMapData() {
		this.houseNumber = '';
		this.street = '';
		this.mapPaostalCode = 0;
		this.mapAddress = {};
		this.mapNeighborhood = '';
		this.mapCounty = '';
		this.mapCity = '';
		this.mapcountry = '';
		this.mapState = '';
		this.mapUserAddress = '';
	}

	OnSICChange(){
		
	}

}
