import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { ReactiveFormConfig, RxFormGroup, RxFormBuilder, FormBuilderConfiguration } from '@rxweb/reactive-form-validators';
import { MiniProfileDTO } from '../../../../shared/model/mini-profile';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { OTPDialogComponent } from '../otp-modal/otp-modal.component';
import { Guid } from "guid-typescript";
import { MatDialog } from '@angular/material';
import { AuthService } from '../../../../core/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../../theme/loader.service';
require('aws-sdk/dist/aws-sdk');
@Component({
  selector: 'kt-mini-profile',
  templateUrl: './mini-profile.component.html',
  animations: [
    trigger('fadeInOut', [
          state('in', style({ opacity: 100 })),
          transition('* => void', [
                animate(300, style({ opacity: 0 }))
          ])
    ])
],
  styleUrls: ['./mini-profile.component.scss']
})
export class MiniProfileComponent implements OnInit {
  continentList=[];
  text='Upload';
  profileImage='';
  verified = true;
  countryList=[];
  allCountry=[];
  filteredCountry=[];
  companyList=[];
  countryCode='';
  roleList=[];
  isOtherCompany=false;
  isOtherRole=false;
  isOtherSpaciality=false;
  displayCounty=false;
  allContinentList=[];
  allCompanyList=[];
  allRoleList=[];
  specialityList=[];
  userData=[];
  loading = false;
  allSpecialityList=[];
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.China, CountryISO.India, CountryISO.Canada];
  frmMiniProfile:RxFormGroup;
  miniProfileDto:MiniProfileDTO;
  SearchCountryField = SearchCountryField;
  phoneVerified:boolean=false;
  continentFilterCtrl=new FormControl('');
  countryFilterCtrl=new FormControl('');
  companyFilterCtrl=new FormControl('');
  roleFilterCtrl= new FormControl('');
  specialityFilterCtrl=new FormControl('');
  stateFilterCtrl = new FormControl('');
  joinedDate = new Date();
  Phone: 'Phone';
  countries: any;
  states: any;
  counties:any;
  stateName:any;
  continentonloadId:any;
  contryOnLoadId:any;
  stateOnLoadId:any;
  formattedAddress='';
  lat:any = [];
  lng:any = [];
  houseNumber:string ='';
  street:string = '';
  mapPaostalCode:any =0;
  mapAddress:any={};
  mapNeighborhood:string='';
  mapCounty:string='';
  mapCity:string='';
  mapcountry:string='';
  mapState:string = '';
  mapUserAddress:string='';
  Elbody = document.getElementsByTagName('body')[0];
  mapountryShortName: any = CountryISO.India;
  inputdialCodevalue: any = 91;



  constructor(private formBuilder: RxFormBuilder,public dialog: MatDialog,private loader:LoaderService,
    private toastr: ToastrService,
    private route:ActivatedRoute,
    private router:Router,
    private cd:ChangeDetectorRef,
    private auth: AuthService) {
      this.miniProfileDto= new MiniProfileDTO();
      this.frmMiniProfile=<RxFormGroup>this.formBuilder.formGroup(this.miniProfileDto);
      this.miniProfileDto.email=localStorage.getItem('email');
      let name =localStorage.getItem('name');
      let website=  localStorage.getItem('website');
      this.miniProfileDto.website=website;
      if(localStorage.getItem('user')==null) {
        this.router.navigate(['/home']);
        return;
      }
      if(name.split(' ').length>0) {
        this.miniProfileDto.firstname=  name.split(' ')[0];
        this.miniProfileDto.lastname=  name.split(' ')[1];
      }
  }

  ngOnInit(): void {
    
    this.Elbody.classList.add('mini-profilepage');
    let isstyle=this.Elbody.getAttribute("style") == null || this.Elbody.getAttribute("style") == "";
    if(!isstyle){
      this.Elbody.setAttribute('style', '');
    }
    this.getAllCountry();

    this.roleFilterCtrl .valueChanges.subscribe(value => {
      console.log('name has changed:', value)
      this._filterRole(value);
    });

    this.specialityFilterCtrl .valueChanges.subscribe(value => {
      console.log('name has changed:', value)
      this._filterSpeciality(value);
    });

    this.companyFilterCtrl .valueChanges.subscribe(value => {
      console.log('name has changed:', value)
      this._filterCompany(value);
    });

    ReactiveFormConfig.set({
      "validationMessage":{
      "required":"This field is required"
    },
      "reactiveForm":{
      "errorMessageBindingStrategy": ErrorBindingStrategy.onSubmit |ErrorBindingStrategy.onDirtyOrTouched
      }
    });

    if(this.route.snapshot.data.masterdata.status) {
      this.allContinentList=this.route.snapshot.data.masterdata.data.continentList;
      this.continentList=this.allContinentList;
      this.allCountry=this.route.snapshot.data.masterdata.data.countryList;
      this.allSpecialityList=this.route.snapshot.data.masterdata.data.specialityList;
      this.specialityList=this.route.snapshot.data.masterdata.data.specialityList;
      this.allRoleList=this.route.snapshot.data.masterdata.data.roleList;
      this.roleList=this.route.snapshot.data.masterdata.data.roleList;
      this.userData=this.route.snapshot.data.masterdata.data.userData;
    }

    if(this.userData.length > 0 && this.userData[0].isMiniProfileSubmitted == 1) {
      this.loader.display(true);

      this.miniProfileDto.address = this.userData[0].address;
      this.miniProfileDto.zip = this.userData[0].zip;
      this.miniProfileDto.state = this.userData[0].stateId;
      // get state name and  country id
      this.auth.getStateName(this.userData[0].stateId).subscribe((res: any) => {
        this.miniProfileDto.statename = res.data[0].name;
        this.miniProfileDto.countryId = res.data[0].country_id;
        this.auth.getCountryName(res.data[0].country_id).subscribe((res: any) => {
          this.miniProfileDto.country = res.data.name;
          this.miniProfileDto.continentId = res.data.continent_Id;
          if(this.userData[0].dialCode) {
            this.inputdialCodevalue = this.userData[0].dialCode;
            let onchnagedialcode = this.miniProfileDto.mobileNo.dialCode;
            this.checkContactCountry(onchnagedialcode,this.inputdialCodevalue);
          }

          this.getCompanyData(res.data.continent_Id,res.data.id);
          this.cd.detectChanges();
        });
      });

      this.frmMiniProfile.controls.mobileNo.setValue(this.userData[0].phoneNumber);
      this.companyList=[];
      this.frmMiniProfile=<RxFormGroup>this.formBuilder.formGroup(this.miniProfileDto);
      this.countryList= this.allCountry.filter(x=>x.continent_Id==this.continentonloadId);
      this.filteredCountry=this.allCountry.filter(x=>x.continent_Id==this.continentonloadId);
      this.contryOnLoadId = this.route.snapshot.data.masterdata.data.country[0].id;
      this.states = this.route.snapshot.data.masterdata.data.stateList;	
      this.stateOnLoadId = this.route.snapshot.data.masterdata.data.state[0].id;
      this.countryCode=this.allCountry.filter(x=>x.id==this.contryOnLoadId)[0].code;
      this.setCoutryflag(this.countryCode);
      if(this.route.snapshot.data.masterdata.data.userData[0].phoneIsVerified) {
        this.phoneVerified = true;
      }
    }

    this.bindUserData(localStorage.getItem('user'));
  }

  private getCompanyData(continentId,countryId) {
    this.loader.display(true);
    this.companyList = [];
    this.auth.getMiniProfileMasterData({
      mode: 'company', continentId: continentId,
      countryId: countryId
    }).subscribe((x) => {
      this.companyList = x['data'];
      this.allCompanyList = x['data'];
      this.loader.display(false);
      this.cd.detectChanges();
    });
  }

  setDefaultImage(event) {
    event.target.src = "../../../../../assets/media/images/default.jpg";
    this.profileImage= "../../../../../assets/media/images/default.jpg";
  }

  onChangeFile(evt) {
    debugger
    var guid =Guid.create();
    this.loader.display(true);
    let file=evt.target.files[0];
    let AWSService=(<any>window).AWS;
    AWSService.config.accessKeyId='AKIAYNH2PCBNJDQQ272W';
    AWSService.config.secretAccessKey='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';
    let bucket=new AWSService.S3({params:{Bucket:'kuoda-asset/Pictures/uat'}});
    let params={
      Key:guid['value'],
      ContentType:file.type,
      Body:file
    }

    bucket.upload(params,(error,res)=> { 
      this.profileImage=res.Location;
      this.loader.display(false);
      this.cd.detectChanges();   
    });

  }

  _filterCompany(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.companyList = this.allCompanyList.filter(x => x.companyname.toLowerCase().includes(valueLowerCase) )
  }
  
  _filterRole(value: string) {
    
		let valueLowerCase = value.toLowerCase();
		this.roleList = this.allRoleList.filter(x => x.rolename.toLowerCase().includes(valueLowerCase) )
  }

  _filterSpeciality(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.specialityList = this.allSpecialityList.filter(x => x.specialityname.toLowerCase().includes(valueLowerCase) )
	}

  onCompanyChange(evt){
    if(evt.value==0){
    this.isOtherCompany=true;
    }else{
      this.isOtherCompany=false;
    }
  }	    

  onRoleChange(evt){
    if(evt.value==0){
    this.isOtherRole=true;
    }else{
      this.isOtherRole=false;
    }
  }

  onSpacialityChange(evt){
    if(evt.value==0){
    this.isOtherSpaciality=true;
    }else{
      this.isOtherSpaciality=false;
    }
  }

  onPhoneChange(evt) {
    if(this.userData[0].dialCode) {
      let onchangephoneNumber = this.userData[0].phoneNumber;
      let inputphoneNumber = evt.target.value;
      let onchnagedialcode = this.userData[0].dialCode;
      let inputdialCodevalue = this.inputdialCodevalue
        if(onchangephoneNumber == inputphoneNumber) {
          if(inputdialCodevalue == onchnagedialcode) {
            this.phoneVerified = true;
          }
          else {
            this.phoneVerified = false;
          }
  
        }
        else {
          this.phoneVerified = false;
        }
    }

  }

  checkNumber(evt) {
    if(this.userData[0].dialCode) {
    let onchnagedialcode = this.userData[0].dialCode;
      if(onchnagedialcode) {
        this.inputdialCodevalue= evt.dialCode;
        this.checkContactCountry(onchnagedialcode,this.inputdialCodevalue);
      }
    }


  }

  checkContactCountry(onchnagedialcode,inputdialCodevalue) {
    let onchangephoneNumber = this.userData[0].phoneNumber;
    let inputphoneNumber = this.frmMiniProfile.controls.mobileNo.value.number;
    if(inputdialCodevalue == onchnagedialcode) {
      if(onchangephoneNumber == inputphoneNumber) {
        this.phoneVerified = true;
      }
      else {
        this.phoneVerified = false;
      }

    }
    else {
      this.phoneVerified = false;
    }
  }

  onSubmit() {
    if (this.frmMiniProfile.valid) {
      this.miniProfileDto.userid = localStorage.getItem('user');
      this.miniProfileDto.photo = this.profileImage;
      if (!this.phoneVerified) {
        this.toastr.warning('Phone no is not verified please verify first', 'Warning');
        return false;
      }
      localStorage.setItem('maxEmailVerify',this.miniProfileDto.maxEmailVerify.toString());
      let phone = this.miniProfileDto.mobileNo;
      this.miniProfileDto.phoneIsVerified = this.phoneVerified;
      this.miniProfileDto.phoneNumber = phone.number;
      this.miniProfileDto.dialCode = phone.dialCode;
      let url = '';
      if (this.miniProfileDto.website.includes('http')) {
        url = this.miniProfileDto.website.replace('www.', '');
      } else {
        let newUrl = this.miniProfileDto.website.replace('www.', '');
        url = 'http://' + newUrl;
      }
      const host = new URL(url).host.toLowerCase();
      if (!this.miniProfileDto.email.toLowerCase().includes(host)) {
        this.toastr.warning('Given email id is not a valid email please provide with ' + host + ' domain', 'Warning');
        return;
      }
      this.loader.display(true);
      if (this.miniProfileDto.mobileNo.dialCode && this.miniProfileDto.mobileNo.number) {
        //this.miniProfileDto.mobileNo = this.miniProfileDto.mobileNo.dialCode + this.miniProfileDto.mobileNo.number;
        this.miniProfileDto.mobileNo = this.miniProfileDto.mobileNo.number;
        this.miniProfileDto.countryCode
      }
      
      let websiteName = this.miniProfileDto.website;
      if (websiteName.includes('http://')) {
        websiteName = this.miniProfileDto.website.replace('http://', '');
      }
      if (websiteName.includes('https://')) {
        websiteName = this.miniProfileDto.website.replace('https://', '');
      }
      if (!websiteName.includes('www.')) {
        websiteName = 'www.' + websiteName;
      }
      this.miniProfileDto.website = websiteName;


      this.auth.saveMiniProfile(this.miniProfileDto).subscribe((res: any) => {
        if (res['status']) {
          if (res.data.checkCompanyAdmin) {
            this.miniProfileDto.companyId = res['data'].companyId;
            this.router.navigateByUrl('/auth/company-detail');
            localStorage.setItem('mini-profiledata', JSON.stringify(this.miniProfileDto));
          }
          else {
            this.miniProfileDto.companyId = res['data'].companyId;
            this.router.navigateByUrl('/auth/billing');
            localStorage.setItem('mini-profiledata', JSON.stringify(this.miniProfileDto));
          }
        }
        else {
          this.toastr.error(res.message);
        }
      }, err => {
        this.toastr.error('There are some problem to submit your data Please contact administrator', 'Error');
      });
      this.loader.display(false);
    }
  }

  sendOTP(): void {
		let phone = this.miniProfileDto.mobileNo;
		let id = localStorage.getItem('id');
    if (!this.phoneVerified) {
      phone = `${phone.dialCode}${phone.number}`;
      this.auth.sendOTP(phone, id).subscribe((res) => {
        this.toastr.success('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
      }, error => {
        this.toastr.success('OTP has been re-sent to ' + phone + '. If not received, please check the entered mobile number.', 'Success');
      });
      const dialogRef = this.dialog.open(OTPDialogComponent, {
        width: '310px',
        data: {
          phone,
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.phoneVerified = confirmed;
          this.cd.detectChanges();
          this.toastr.success('Mobile number has been verified successfully', 'Success');
        }
      });
    }

	}

  getAllCountry() {
    if(this.route.snapshot.data.masterdata.status) {
        this.countries = this.route.snapshot.data.masterdata.data.countryList;
        this.states = this.route.snapshot.data.masterdata.data.stateList;
    }
  }

  bindUserData(arg0: string) {
    //throw new Error('Method not implemented.');
  }

  handleAddressChange(event): void {
    this.resetMapData();
    this.formattedAddress = event.formatted_address;
    for (let i = 0; i < event.address_components.length; i++) {
      this.mapAddress = event.address_components[i];
      if (this.mapAddress.long_name != '') {
        switch (this.mapAddress.types[0]) {
          case "street_number":
            this.houseNumber = this.mapAddress.long_name;
            break;
          case "route":
            this.street = this.mapAddress.long_name;
            break;
          case "locality":
            this.mapCity = this.mapAddress.long_name;
            break;
          case "administrative_area_level_1":
            this.mapState = this.mapAddress.long_name;
            break;
          case "country":
            this.mapcountry = this.mapAddress.long_name;
            this.mapountryShortName = this.mapAddress.short_name;
            break;
          case "postal_code":
            this.mapPaostalCode  = this.mapAddress.long_name;
            break;
          case "neighborhood":
            this.mapNeighborhood  = this.mapAddress.long_name;
            break;
          case "administrative_area_level_2":
            this.mapCounty  = this.mapAddress.long_name;
            break;
          default:
            console.log("No such address exists!");
            break;
        }
      }
      
    }
    this.setNewValues();

  }

  private setNewValues() {
    this.loader.display(true);
    this.miniProfileDto.zip = this.mapPaostalCode;
    this.miniProfileDto.statename = this.mapState;
    this.miniProfileDto.country = this.mapcountry;
    this.miniProfileDto.address = this.formattedAddress;
    this.auth.getCountyId(this.mapcountry).subscribe((res: any) => {
      let countryID = res.data.id;
      let continentId = res.data.continent_Id;
      this.miniProfileDto.countryId = countryID;
      this.miniProfileDto.continentId = continentId;
      
      this.auth.getStateId(this.mapState,countryID).subscribe((res: any) => {
        this.miniProfileDto.state = res.data[0].id;
      });
      
      this.setCoutryflag(this.mapountryShortName);
      if(this.userData[0].dialCode) {
        let onchnagedialcode = this.userData[0].dialCode;
        this.inputdialCodevalue = parseInt(this.miniProfileDto.mobileNo.dialCode);
        this.checkContactCountry(onchnagedialcode,this.inputdialCodevalue);
      }


    this.cd.detectChanges();
    this.getCompanyData(continentId,countryID);
      
    });

  }

  private setCoutryflag(mapountryShortName) {
    this.mapountryShortName= mapountryShortName;
  }

  private resetMapData() {
    this.houseNumber = '';
    this.street = '';
    this.mapPaostalCode = 0;
    this.mapAddress = {};
    this.mapNeighborhood = '';
    this.mapCounty = '';
    this.mapCity = '';
    this.mapcountry = '';
    this.mapState = '';
    this.mapUserAddress = '';
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.frmMiniProfile.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}

export enum ErrorBindingStrategy {
  none,
  onSubmit,
  onDirty,
  onTouched,
  onDirtyOrTouched
}

interface Window {
  AWS?:any
}
