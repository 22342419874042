import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class SelectedExpenditure extends BaseModel {
    id: string;
    name: string;
    path: any = [];
    range: any = [];
    selectedRange: string;

    clear(): void {
        this.id = '';
        this.name = '';
        this.selectedRange = '';
        this.path = [];
        this.range = [];
    }
}
