import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../../core/auth';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Router } from '@angular/router';
import { currentUser, Logout, User } from '../../../../core/auth';
import { tap } from 'rxjs/operators';
import { isLoggedIn } from '../../../../core/auth/_selectors/auth.selectors';
import { SharedService } from '../../../../shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	_isLoggedIn = false;
	_user: any;
	isAdmin = false;
	isActive = true;
	isHidden = false;
	constructor(
		private toastr: ToastrService,
		private store: Store<AppState>,
		private router: Router,
		private auth: AuthService,
		private sharedService: SharedService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.isAdmin =
			localStorage.getItem("IsAdmin") == "1" ||
			localStorage.getItem("IsAdmin") == "2"
				? true
				: false;
		this.store.pipe(
			select(isLoggedIn),
			tap((loggedIn) => {})
		);
		console.log('Loc=--->', window.location.href)	;
		if (window.location.href.includes('login')) {
			this.isHidden = true;
		}
		this.sharedService.getUserProfile().subscribe((x) => {
			this.setHeader();
		});
		//this.checkminiProfile();
	}

	setHeader() {
		this._user = {
			fullName: localStorage.getItem("name") || "user",
			email: localStorage.getItem("email"),
		};
		if (localStorage.getItem("user") != null) {
			this._isLoggedIn = true;
		} else {
			this._isLoggedIn = false;
		}
	}

	logout() {
		console.log("#logout");
		this.auth.logout().subscribe(() => {
			this.store.dispatch(new Logout());
			localStorage.clear();
		});
		this.isActive = false;
	}

	podcastAdmin() {
		if (
			localStorage.getItem("IsAdmin") == "1" ||
			localStorage.getItem("IsAdmin") == "2"
		) {
			window.location.href =
				"http://podcasts.bizwizz.net:4100/myvideos?token=" +
				localStorage.getItem("token");
		} else {
			this.router.navigateByUrl("/auth/login");
		}
	}

	podcast() {
		if (
			localStorage.getItem("token") != null ||
			localStorage.getItem("token") != undefined
		) {
			window.location.href =
				"http://podcasts.www.bizwizz.net?token=" +
				localStorage.getItem("token");
		} else {
			this.router.navigateByUrl("/auth/login");
		}
	}

	checkminiProfile() {
		var email = localStorage.getItem("email");
		this.auth.getUserByEmail(email).subscribe((res: any) => {
			if (res.data != undefined) {
				let isMiniProfileSubmitted = res.data.isMiniProfileSubmitted,
					companySubmitted = res.data.companySubmitted;

				if (isMiniProfileSubmitted == 1 && companySubmitted == 1) {
					this.isActive = true;
				} else {
					this.isActive = false;
				}
				this.cdr.detectChanges();
			}
		});
	}

	completeMiniProfile() {
		this.toastr.warning(
			"Complete the Mini Profile and Company Information for complete access of Website",
			"Warning"
		);
	}
}
