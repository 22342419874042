import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../../core/auth';
import { MiniProfileDTO } from '../model/mini-profile';

@Injectable()
export class CompanyInfoResolver implements Resolve<Observable<any>> {
    miniProfileDTO:MiniProfileDTO=new MiniProfileDTO();
  constructor(
      private activatedRoute: ActivatedRoute,private authservice:AuthService,private router:Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any 
  {
      let req={
        mode:'form-data',
        continentId:'',
        countryId:''
      }

      if( localStorage.getItem('user') == null || localStorage.getItem('mini-profiledata')==null) {
        this.router.navigateByUrl('/'); // Main page
        return;
    }else{
        this.miniProfileDTO=	JSON.parse(localStorage.getItem('mini-profiledata'));
        req.continentId=this.miniProfileDTO.continentId;
        req.countryId=this.miniProfileDTO.countryId;
        return this.authservice.getMiniProfileMasterData(req);
    }

  }
}
