import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { log } from 'util';
import { AuthNoticeService, AuthService, Register, Company, Login, SelectedExpenditure } from '../../../../core/auth/';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './otp-modal.component.html'
})
export class OTPDialogComponent {
  phone: string = "";
  id : string;
  otp='';
  secret = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private auth: AuthService,
    private toaster:ToastrService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<OTPDialogComponent>) {
    if (data) {
      this.phone = data.phone || this.phone;
      this.secret = data.secret || this.secret;
    }
    this.id =localStorage.getItem('id');
    this.dialogRef.updateSize('200vw','300vw')
  }

  close(): void {
    console.log('-----------');
    this.dialogRef.close(false);
  }
 
  verifyOTP(): void {
    debugger
    let otp = this.otp;
    let secret = this.secret;
    this.auth.verifyOTPAtSignup(otp, secret).subscribe((res) => {
      console.log('#verifyOTP RES',res);
      if(res.status){
        this.dialogRef.close(true);
      }else{
        this.toaster.error('Invalid OTP','Error');
      }
    }, (error) => {
      this.toaster.error('Invalid OTP','Error');
      // console.log('#error', error.error);
      // this.snackBar.open('Invalid OTP', '', {
			// 	duration: 1000,
			//   });
    });
  }

  reSendOTP(){
    let phone = this.phone;
    let id = this.id;
    this.auth.sendOTPAtSignup(phone).subscribe((res) => {
      this.secret = res.secret
      console.log('#sendOTP id', res);
      this.toaster.success('OTP has been re-sent to '+ phone +'. If not received, please check the entered mobile number','Success');
      // this.snackBar.open('OTP has been re-sent to '+ phone +'. If not received, please check the entered mobile number.', '', {
			// 	duration: 2000,
			//   });
    }, error=>{      
      this.toaster.success('OTP has been re-sent to '+ phone +'. If not received, please check the entered mobile number','Success');
      // this.snackBar.open('OTP has been re-sent to '+ phone +'. If not received, please check the entered mobile number.', '', {
			// 	duration: 2000,
			//   });
    });
  }

}