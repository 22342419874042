// Angular
import { Component, ViewChild, OnInit, ChangeDetectorRef, Inject, Output, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
// Lodash
import { shuffle } from 'lodash';
// Services
import { AppState } from '../../../core/reducers';
import { currentUser, Logout, User } from '../../../core/auth';
import { Store, select } from '@ngrx/store';
// Widgets model
import { LayoutConfigService, SparklineChartOptions } from '../../../core/_base/layout';
//ng bootstrap
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginator, MatSort, MatTableDataSource, MatSelect, MatDialog, PageEvent } from '@angular/material';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
// import  Angular2Csv  from 'angular2-csv/Angular2-csv';
import { AuthNoticeService, AuthService, SearchFilter} from '../../../core/auth';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
 import _ from 'lodash';
//snackbar
import {MatSnackBar, MatSnackBarHorizontalPosition,	MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { CardData } from 'angular-credit-cards';
import {HttpClient} from "@angular/common/http";
import { int } from 'aws-sdk/clients/datapipeline';
import { integer } from 'aws-sdk/clients/cloudfront';
import { map, startWith, tap, takeUntil, first } from 'rxjs/operators';

// Dialogs
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { email } from '@rxweb/reactive-form-validators';
import { exit } from 'process';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import swal,{ SweetAlertOptions } from 'sweetalert2';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { Location } from '@angular/common';
import {Router} from "@angular/router"
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
export interface TemplateDialogData {
	templateName: string;
}
export interface SubPlans {
	value: string;
	name: string;
	id: string;
	cost: number;
	currency: string;
}

@Component({
	selector: 'kt-dashboard',
	templateUrl: './mysubscription.component.html',
	styleUrls: ['mysubscription.component.scss'],
})
export class MySubscriptionComponent implements OnInit, AfterViewInit {
	plans:string;
	planDetails: any[];
	subplans: SubPlans[] = [];
	planPrice:string;
	currentUserPlan = '';
	userStripeId:string;
	subscriptionId:string;
	selectedPriceId:string;
	selectedPriceName:string;
	totalVerification:string;
	totalEmailSent:string;
	totalAllowed:string;
	longNetworkOperations:boolean = false;
	paymentinprogress:boolean = false;
	@ViewChild('simpleAlert', { static: true }) simpleAlert: SwalComponent;
	simpleAlertOption:SweetAlertOptions = {};
	@ViewChild('simpleDialog', { static: true }) simpleDialog: SwalComponent;
	simpleDialogOption:SweetAlertOptions = {};

	// Stripe
	@ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;

	cardOptions: StripeCardElementOptions = {
		style: {
		base: {
			iconColor: '#666EE8',
			color: '#31325F',
			fontWeight: '300',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSize: '18px',
			'::placeholder': {
			color: '#CFD7E0'
			}
		}
		}
	};

	elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};
	stripeTest: FormGroup;
	// End Stripe

	constructor(private layoutConfigService: LayoutConfigService,private store: Store<AppState>,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,	
		public dialog: MatDialog,
		private http:HttpClient,
		private stripeService: StripeService,
		private location: Location,
		private router: Router
		) {
			
			this.loadSubPlanPrices();
			this.auth.getPlanDetails().subscribe((res_data:any)=>{
				this.planDetails = res_data
				console.log(this.planDetails);
			},(error)=>{
				this.planDetails = [
					{
						data: {
							verification: 0,
							send: 0,
							usage: '0'
						}
					}
				]
			})	
		}
	ddSubPlanChanged(event: any){
		if (event < 0) return;
		let picked = this.subplans.find(o => o.value === event);
		console.log('Selected Plan Changed===>', picked);
		this.planPrice = `${picked.cost} ${picked.currency}`;
	}
	loadSubPlanPrices(){
		this.longNetworkOperations = true;
		this.auth.getEmailSubPrices().subscribe((res_data:any)=>{
			let ctr = 1;
			res_data.data.forEach(element => {
				this.subplans.push({value: ctr.toString(), name: element.nickname, id: element.id, cost: element.unit_amount/100, currency: element.currency});
				ctr++;
			});
			this.planPrice = `${this.subplans[0].cost} ${this.subplans[0].currency}`;
			this.loadCurrentPlan();
			this.longNetworkOperations = false;
			this.cdr.detectChanges();
		},(error)=>{
			this.longNetworkOperations = false;
			this.cdr.detectChanges();

		})
	}
	loadCurrentPlan(){
		this.longNetworkOperations = true;
		this.auth.getEmailUserCurrentPlan(localStorage.getItem('id')).subscribe((res_data:any)=>{
			console.log('User data==>',res_data.result);
			if (res_data.result.length > 0) {
				this.currentUserPlan = res_data.result[0].current_plan;
					this.userStripeId = res_data.result[0].stripe_id;
					this.planPrice = `${this.subplans[0].cost} ${this.subplans[0].currency}`;
					this.subscriptionId = res_data.result[0].sub_id;
					this.totalAllowed = res_data.result[0].email_volume;
					this.totalVerification = res_data.result[0].verification_volume;
					this.totalEmailSent = res_data.result[0].send_volume;
					console.log('Already stripe user created===>', this.currentUserPlan, this.userStripeId)
			}else {
				// create stripe customer
				this.longNetworkOperations = true;
				let data ={
					user_id: localStorage.getItem('id'),
					email: localStorage.getItem('email'),
					name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
					current_plan: 'Pay As You Go'
				}
				this.auth.saveEmailUserPlan(data).subscribe((res_data:any)=>{
					console.log('New stripe user created===>', res_data)
					this.currentUserPlan = res_data.data.current_plan;
					this.userStripeId = res_data.data.stripe_id;
					console.log(this.currentUserPlan, this.userStripeId)
					this.longNetworkOperations = false;
					this.cdr.detectChanges();
				}, (error) => {
					console.log(error)
					this.longNetworkOperations = false;
					this.cdr.detectChanges();
				});
				// End ctreate stripe customer
				
			}
			
			this.longNetworkOperations = false;
			this.cdr.detectChanges();
		},(error)=>{
			this.longNetworkOperations = false;
			this.cdr.detectChanges();
		})
	}
	btnPayAsYouGoClick(){
		if(this.currentUserPlan === 'Pay As You Go') {
			this.simpleAlertOption = {
				title:"You are already on this plan.",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'OK',
				icon: 'info',
				focusConfirm: true
			}
			this.cdr.detectChanges()
			this.simpleAlert.fire()
		}else {
			this.simpleDialogOption = {
				title:"Switch Plan",
				text:"Switching to a new plan will invalidate your previous plan. Continue?",
				cancelButtonColor:"#d33",
				showCancelButton:true,
				cancelButtonText:"No, Cancel",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'Yes, Switch Plan',
				icon: 'question',
				focusCancel:true,
				focusConfirm: false
			}
			this.cdr.detectChanges()
			this.simpleDialog.fire().then((result) => {
				if(!result.dismiss) {
					// call the API#Subscribe to plan here
					let data = {
						user_id: localStorage.getItem('id'),
						old_sub_id: this.subscriptionId === null ? undefined : this.subscriptionId
					}
					console.log(data);
					this.longNetworkOperations = true;
					this.cdr.detectChanges();
					this.auth.subscribeToPayAsYouGo(data).subscribe((res_data:any)=>{
						console.log('User subscribed to PayAsYouGo===>', res_data)
						this.subscriptionId = res_data.data.current_plan_id;
						this.currentUserPlan = res_data.data.current_plan;
						this.longNetworkOperations = false;
						this.simpleAlertOption = {
							title:"Plan switched to " + this.currentUserPlan,
							confirmButtonColor:"#3085d6",
							confirmButtonText:'OK',
							icon: 'success',
							focusConfirm: true
						}
						this.cdr.detectChanges();
						this.simpleAlert.fire();
					}, (error) => {
						console.log('Subscription to PayAsYouGo FAILED===>', error)
						this.longNetworkOperations = false;
						this.simpleAlertOption = {
							title:"Change of plan to Pay As You Failed: " + error.message,
							confirmButtonColor:"#3085d6",
							confirmButtonText:'OK',
							icon: 'error',
							focusConfirm: true
						}
						this.cdr.detectChanges();
						this.simpleAlert.fire();
					});
				}
			});
		}
	}
	btnSubscribeClick(ddSubPlan:any){
		console.log(ddSubPlan.value)
		let picked = this.subplans.find(o => o.value === ddSubPlan.value);
		console.log(picked);
		this.selectedPriceId = picked.id;
		this.selectedPriceName = picked.name;
		if(this.currentUserPlan === picked.name) {
			this.simpleAlertOption = {
				title:"You are already on this plan.",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'OK',
				icon: 'info',
				focusConfirm: true
			}
			this.cdr.detectChanges()
			this.simpleAlert.fire()
		}else {
			this.simpleDialogOption = {
				title:"Switch Plan",
				text:"Switching to a new plan will invalidate your previous plan. Continue?",
				cancelButtonColor:"#d33",
				showCancelButton:true,
				cancelButtonText:"No, Cancel",
				confirmButtonColor:"#3085d6",
				confirmButtonText:'Yes, Switch Plan',
				icon: 'question',
				focusCancel:true,
				focusConfirm: false
			}
			this.cdr.detectChanges()
			this.simpleDialog.fire().then((result) => {
				if(!result.dismiss) {
					this.paymentinprogress = true;
					this.cdr.detectChanges()
				}
			});
		}
	}
	createToken(): void {
		/*this.location.go('subscribe?success=true')
		setTimeout(()=>{
			this.router.navigate(['/subscribe?success=true'])
		},2000);
		return;*/
		const name = this.stripeTest.get('name').value;
		console.log(this.card);
		//return;
		this.stripeService
		  .createToken(this.card.element, { name })
		  .subscribe((result) => {
			if (result.token) {
			  	// Use the token
			  	console.log('Token====>', result.token.id);
				console.log('SelectedPriceId===>', this.selectedPriceId);
				console.log('SelectedPriceName===>', this.selectedPriceName);
				console.log('Customer Stripe Id====>', this.userStripeId);
				console.log('Subscription Id====>', this.subscriptionId);
				// Subscribe to plan here
				let data = {
					user_id: localStorage.getItem('id'),
					token: result.token.id,
					stripe_id: this.userStripeId,
					price_id: this.selectedPriceId,
					price_name: this.selectedPriceName,
					old_sub_id: this.subscriptionId === null ? undefined : this.subscriptionId
				}
				this.longNetworkOperations = true;
				this.cdr.detectChanges();
				this.auth.subscribeToStripe(data).subscribe((res_data:any)=>{
					this.location.go('subscribe?success=true')
					console.log('User subscribed to strip===>', res_data)
					this.subscriptionId = res_data.data.current_plan_id;
					this.currentUserPlan = res_data.data.current_plan;
					this.totalVerification = String(res_data.data.verification_volume);
					this.totalEmailSent = String(res_data.data.send_volume);
					this.totalAllowed = String(res_data.data.email_volume);
					this.longNetworkOperations = false;
					this.simpleAlertOption = {
						title:"Plan switched to " + this.currentUserPlan,
						confirmButtonColor:"#3085d6",
						confirmButtonText:'OK',
						icon: 'success',
						focusConfirm: true
					}
					this.paymentinprogress = false;
					this.cdr.detectChanges();
					this.simpleAlert.fire();
					setTimeout(()=>{
						this.router.navigate(['/subscribe?success=true'])
					},2000)
					
				}, (error) => {
					console.log('Subscription to stripe FAILED===>', error)
					this.longNetworkOperations = false;
					this.simpleAlertOption = {
						title:"Subscription Failed: " + error.message,
						confirmButtonColor:"#3085d6",
						confirmButtonText:'OK',
						icon: 'error',
						focusConfirm: true
					}
					this.paymentinprogress = false;
					this.cdr.detectChanges();
					this.simpleAlert.fire();
				});
				// End Sunbscribe
			} else if (result.error) {
			  	// Error creating the token
				  this.simpleAlertOption = {
					title:result.error.message,
					confirmButtonColor:"#3085d6",
					confirmButtonText:'OK',
					icon: 'error',
					focusConfirm: true
				}
				this.paymentinprogress = false;
				this.cdr.detectChanges()
				this.simpleAlert.fire();
			  	console.log('Error creating the token', result.error.message);
			}
		  });
	}
	cancelPayment():void {
		this.paymentinprogress = false;
		this.cdr.detectChanges();
	}
	ngOnInit(): void {
		this.initUserForm();
	}
	ngAfterViewInit() {	
		this.cdr.markForCheck();
	}

	initUserForm() {
		this.stripeTest = this.fb.group({
			name: ['', [Validators.required]]
		  });
	}
	openSnackBar(message: string, action: string, horizontal: MatSnackBarHorizontalPosition, vertical: MatSnackBarVerticalPosition, duration: integer) {
		this._snackBar.open( message, action, {
			duration: duration,	
			horizontalPosition: horizontal,
      		verticalPosition: vertical
		});
	}

}