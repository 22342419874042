import { Injectable } from '@angular/core'; 
import { BehaviorSubject, Observable } from 'rxjs';
 
@Injectable() export class SharedService { 
 
private behave = new BehaviorSubject<any>({});
  
constructor( ) { } 
 
setUserProfile(behave) { 
    this.behave.next(behave); 
} 
getUserProfile(): Observable<any> { 
    return this.behave.asObservable(); 
}
}