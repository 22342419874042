// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, Login } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { ToastrService } from 'ngx-toastr';
@Component({
	selector: 'kt-register',
	templateUrl: './signup.component.html',
	encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	blockedDomainList=[];
	loading = false;
	verified = true;
	errors: any = [];
	passwordType=true;
	linkedInUrl = 'http://127.0.0.1:1338/api/v1/auth/linkedin'
	confirmPasswordType=true;
	// site_key='6Ldwj-0UAAAAABhOgDSgL0uxmZlCrE9MywghIptL'; //kuoda.com
	site_key = '6Lfy1GsaAAAAAHu6ByW1gi5BXpJUxEOlbpLb2LWy'
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private toastr: ToastrService
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	toggle(key){
		switch (key) {
			case 1:
				this.passwordType=!this.passwordType;	
				break;
				case 2:
					this.confirmPasswordType=!this.confirmPasswordType;	
					break;
			default:
				break;
		}
	}
	ngOnInit() {
		const _user =  { fullName: localStorage.getItem("name") || 'user', email: localStorage.getItem('email') };
		if(localStorage.getItem('user')!=null){
			this.router.navigateByUrl('/dashboard');
			return;
		}

		this.initRegisterForm();
		this.getBlockedDomain();
	}

	isVerified(){
		return this.verified;
	}
	resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response: ${captchaResponse}`);
		this.auth.verifyRecaptcha(captchaResponse).subscribe((res:any) => {
			console.log('#res', res);
			this.verified = false;
			console.log('#verified', this.verified);
			this.cdr.markForCheck();  

		}, (error: any) => {
			this.verified = true;
			console.log('#error', error);
			this.cdr.markForCheck();  

		});
    }
	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.verified = false;
	}
	getBlockedDomain(){
		this.auth.getBlockedDomain().subscribe((x:any)=>{
			this.blockedDomainList = x.data.map(function(item) {
				return item['domain'].toLowerCase();
			  });
			
	},(err)=>{
	
	})
	}
	isEmailWithValidDomain(){
		
	let website=this.registerForm.value.website.toLowerCase();
	let email=this.registerForm.value.email.toLowerCase();
			let url='';
			if(website.includes('http')){
			  url=website.replace('www.','');      
			}else{
			let newUrl= website.replace('www.','');
			 url ='http://'+ newUrl;       
			}
			const host=new URL(url).host;
			if(!email.includes(host)){
			  this.toastr.warning('Given email id is not a valid email please provide with '+host+' domain', 'Warning');
				return false;
			}
			return true;
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			fullname: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
			website: ['', Validators.compose([
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(320)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {

		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		if(this.blockedDomainList.indexOf(controls.website.value.toLowerCase())>-1){
			this.toastr.warning('Invalid Domain.','Warning');
			return;
		}
		if(!this.isEmailWithValidDomain()){
			return;
		}

		if (!controls.agree.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.toastr.error("You must agree the terms and condition","Error")
			// this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}

		const _user: User = new User();
		_user.clear();
		_user.emailAddress = controls.email.value;
		// _user.username = controls.username.value;
		_user.fullName = controls.fullname.value;
		_user.password = controls.password.value;
		_user.website = controls.website.value;
		_user.roles = [];
		console.log("#USER", _user);
		
		this.loading = true;
	
		let websiteName=_user.website;
		if(websiteName.includes('http://')){
		  websiteName= _user.website.replace('http://','');      
		} 
		if(websiteName.includes('https://')){
		  websiteName= _user.website.replace('https://','');   
		}
		if(!websiteName.includes('www.')){
		  websiteName= 'www.'+websiteName;   
		}
		_user.website=websiteName;

		this.auth.register(_user).subscribe((res: any) => {
			//this.data = res;
			console.log("#res_register", res);
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');      
			this.toastr.success(res.message,"Success");
			// this.authNoticeService.setNotice(res.message, 'success');      
			this.loading = false;
			this.cdr.markForCheck();  			
			this.router.navigateByUrl('/auth/signup/completed');                                              
		  }, err => {
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			this.toastr.error(err.error && err.error.errorMsg || "Something went wrong","Error");
			//  this.authNoticeService.setNotice(err.error && err.error.errorMsg || "Something went wrong", 'danger');
			console.log("#error rej", err);
			this.loading = false;
		    this.cdr.markForCheck();  
		  });
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}
		if(validationType=="website"){
			if(control.value==''){
				return false;
			}			
			var expression = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
			  var res = expression.test(control.value);
			  if(res){
				  return false;
			  }
			  else{
				  return true;
			  }	 
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
