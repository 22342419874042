// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }
    localStoragecompanyDataStored = localStorage.getItem('companyDataStored');
    flag = true;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    let url = '/';
                    if (!loggedIn) {
                        debugger;
                        url = '/auth/login'; 
                        this.router.navigateByUrl(url);
                    }
                    else if(this.localStoragecompanyDataStored == "false") {
                        debugger
                        url = '/auth/company-detail';
                        this.router.navigateByUrl(url)
                        .catch((e: Error) => console.error(e));
                    } 
                })
            );
    }
}
