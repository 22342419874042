// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'kt-register',
	templateUrl: './signupcompleted.component.html',
	encapsulation: ViewEncapsulation.None
})
export class SignupCompletedComponent implements OnInit, OnDestroy {
	constructor(
		private router: Router,
	) {
	}

	ngOnInit() {
		setTimeout(() => {
			this.router.navigateByUrl('/auth/login');
		}, 4000);
	}

	isVerified(){
	}

	ngOnDestroy(): void {
	
	}
}
