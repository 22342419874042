import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Company } from '../_models/company.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { log } from 'util';
import { AnyARecord } from 'dns';
//const BASE_URL = 'http://localhost:1337/api/v1/';
const API_SICS_URL = 'api/v1/sic-codes';
const API_COMPANY_DATA_URL = 'api/v1/user-company-data';
const API_GET_COMPANY_BY_ID = 'api/v1/getCompanyById';

const API_REGISTER_URL = 'api/v1/auth/signup';
const API_LOGIN_URL = '/api/v1/auth/login';
const API_PROFILE_URL = '/api/v1/user/edit';
const API_SEND_EMAIL_URL = '/api/v1/auth/send-email';
const API_Resend_Verifcation_Link = '/api/v1/auth/resend-link';
const API_LOGOUT_URL = '/api/v1/auth/logout';
const API_LINKEDIN_AUTH_URL = '/api/v1/auth/linkedin';
const API_CURRENT_USER_URL = '/api/v1/current-user';
const API_VERIFYING_CODE_URL = '/api/v1/auth/code';
const API_VERIFY_EMAIL_URL = '/api/v1/auth/verify-email';
const API_VERIFY_RECAPTCHA_URL = '/api/v1/verify-recaptcha';
const API_FORGOT_PASS_URL = '/api/v1/auth/forgot-password';
const API_GET_USER_DETAILS = '/api/v1/auth/user-details';
const API_RESET_PASS_URL = '/api/v1/auth/reset-password';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const API_CARD_SAVE_URL = 'api/v1/account/card-validate'; 
const API_FILTER_COMPANY_URL = '/api/v1/search/company';
const API_CSV_DOWNLOAD_URL = '/api/v1/search/csv-download';
const API_EXPENDITURE_URL = '/api/v1/expenditure-tree';
const API_PRODUCTTREE_URL = '/api/v1/product-tree';

const API_COUNTRY_URL = '/api/v1/country';
const API_STATE_URL = '/api/v1/state';
const API_STATENAME_URL = '/api/v1/state-name'
const API_COUNTY_URL='/api/v1/county';
const API_CITY_URL = '/api/v1/city';
const API_HS_PRODUCT_URL = '/api/v1/hs-product';
const API_INDUSTRY_URL = '/api/v1/industry';
const API_FORM_DATA_URL = '/api/v1/form-data';
const API_CONTACT_TITLE_URL = '/api/v1/contact-title';
const API_COUNTRYNAME_URL ='/api/v1/country-name';
const API_CITY_NAME = '/api/v1/city-name';
const API_CITY_ID = '/api/v1/city-id';
const API_STATE_ID = '/api/v1/state-id';
const API_COUNTRY_ID = '/api/v1/country-id';
const API_CONTINENT_ID ='/api/v1/continent-id';
const API_COMPANY_URL = '/api/v1/company-details';

const API_JAPAN_FORM_DATA_URL = '/api/v1/japan-form-data';
const API_INDIA_FORM_DATA_URL = '/api/v1/india-form-data';
const API_CHINA_FORM_DATA_URL = '/api/v1/china-form-data';
const API_SEND_OTP_URL = '/api/v1/send-otp';
const API_SEND_OTP_AT_SIGNUP = 'api/v1/send-otp-at-signup';
const API_VERIFY_OTP_URL = '/api/v1/verify-otp';
const API_VERIFY_OTP_AT_SIGNUP = 'api/v1/verify-otp-at-signup'
const API_UPDATE_PASS_API = '/api/v1/user/update-pass'
const API_CARD_DETAIL_URL = '/api/v1/account/card-detail';
const API_CHANGE_CARD_URL = '/api/v1/account/change-card';
const API_TRANSACTION_URL = '/api/v1/account/transaction';
const API_CONTINENT_COUNTRY_URL='/api/v1/auth/mini-profile';
const API_SAVE_MINI_PROFILE='/api/v1/auth/saveMiniProfile';
const API_UPLOAD_IMAGE='/api/v1/auth/file-upload';
const API_Get_Blocked_Domain='/api/v1/getBlockedDomain';
const API_SCRAPE_WEBSITE='/api/v1/webscraper/scraper';
const API_GET_PRODUCTS_URL = '/api/v1/subscription/products';
const API_GET_PRODUCTS_WITH_PRICES_URL = '/api/v1/subscription/products-prices';
const API_CHECKOUT_SESSION_URL = '/api/v1/subscription/checkout-session';
const API_SAVE_TEMPLATE='/api/v1/webscraper/savetemplate';
const API_LOAD_TEMPLATE='/api/v1/webscraper/loadtemplates';
const API_TEXT_SEARCH_URL = '/api/v1/textsearch/search';
const API_RE_EXECUTE_TEXT_SEARCH_URL = '/api/v1/textsearch/reexecutesavedasolrsearch';
const API_INDEXING_UPDATE = '/api/v1/indexing/update';
const API_STOCK_IMAGES='/api/v1/webscraper/stockImage';
const API_SEARCH='/api/v1/user/search';
const API_SEARCH_REQUEST_DATA = '/api/v1/user/request-data'
const API_SAVE_REQUEST_DATA_LK = '/api/v1/user/request-data-lk'
const API_VERIFIED_SEARCH='/api/v1/user/search/verifiedsearch';
const API_VERIFIED_BLAZE_EMAILS='/api/v1/user/search/getblazeverifiedemails';
const API_VERIFY_EMAILS='/api/v1/batch/createbatch';
const API_UPDATE_USERSEARCH ='/api/v1/email/updateusersearch';
const API_GET_VERIFY_EMAIL_STATUS='/api/v1/batch/batchstatus';
const API_CREATE_CAMPAIGN='/api/v1/zoho/createcampaign';
const API_LOAD_CAMPAIGNS='/api/v1/bizwizz/campaigns/load';
const API_CAMPAIGN_DETAILS='/api/v1/bizwizz/campaign/details';
const API_ZOHO_CAMPAIGN_EXECUTE='/api/v1/zoho/executecampaign';

const API_PRODUCT_TEXTSEARCH = '/api/v1/textsearch/product';
const API_INDUSTRY_TEXTSEARCH = '/api/v1/textsearch/industry';
const API_COMPANY_NAME_SEARCH = '/api/v1/search-company-name';

const API_UPLOAD_USER_IMAGES='/api/v1/webscraper/uploaduserimages';
const API_USER_IMAGES='/api/v1/webscraper/getuserimages';

const API_GET_EMAIL_SUB_PRICES = '/api/v1/emailsubscription/prices';
const API_GET_PLAN_DETAILS = '/api/v1/executebytype?type=GET_PLAN_DETAILS';
const API_GET_EMAIL_USER_PLAN = '/api/v1/emailsubscription/loaduserplan';
const API_SAVE_EMAIL_USER_PLAN = '/api/v1/emailsubscription/saveuserplan';
const API_SAVE_EMAIL_SUBSCRIBE_TO_STRIPE = '/api/v1/emailsubscription/subscribetostripe';
const API_SAVE_EMAIL_SUBSCRIBE_TO_PAYG = '/api/v1/emailsubscription/subscribetopayasyougo';
const API_UPDATE_SEND_VOLUME = '/api/v1/emailsubscription/updatesendvolume';
const API_DIRECT_PAY = '/api/v1/emailsubscription/directpay';
const API_START_EMAIL_VERIFICATION = '/api/v1/user/search/startemailverification'
const API_DIRECT_PAYMENT = '/api/v1/pay/directpayment';
const API_HOW_MANY_TO_VERIFY = '/api/v1/emailsubscription/howmanytoverify';
const API_EMAILS_FROM_SAVED_SEARCH = '/api/v1/user/search/getemailsfromsavedsearch';
const API_CONTACT_EXCLUDE_FILTER='/api/v1/contact-excludefilter';
const API_EMAIL_STATS = '/api/v1/emailsubscription/email-stats';
const API_SEND_VERIFIED_EMAIL = '/api/v1/emailsubscription/verified-email';
const API_GET_REGISTERED_USERS_COUNT = '/api/v1/user/get-registered-users';
const API_GET_WEBSCAN_DATA = '/api/v1/user/get-webscan-data';
const API_GET_USERS_FOR_ADMIN  = '/api/v1/user/get-users-for-admin';
const API_SET_USER_ADMIN_SADMIN  = '/api/v1/user/set-user-as-admin-suadmin';
const API_CHECK_CSV  = '/api/v1/emailverifier/checkcsv';
const API_VERIFY_CSV_EMAILS  = '/api/v1/email/verify/standalone';
const API_GET_VERIFIED_CSV_LIST ='/api/v1/emailverifier/getverifiedcsvlist';
const API_GET_VERIFIED_EMAILS_LIST ='/api/v1/emailverifier/getverifiedemailsbyrecid';
const API_POST_STOP_VERIFICATION ='/api/v1/emailverifier/stopverificationbyrecid';
const API_GET_VERIFICACTION_STATS ='/api/v1/emailverifier/getverificationstats';
const API_GET_ALL_COUNTRIES ='/api/v1/user/getAllCountries';
const API_GET_SUB_USERS ='/api/v1/user/get-subscribed-users';
const API_GET_CREDIT_BALANCE = '/api/v1/user/fetch-credit-balance';
const API_FUND_CREDIT_ACCOUNT = 'api/v1/user/fund-credit-account';
const API_FETCH_CREDIT_TRANSACTIONS = 'api/v1/user/fetch-credit-transactions';
const API_BLOCK_CREDIT_BALANCE = 'api/v1/user/block-credit-balance'
const API_INVITE_CONTACTS_GOOGLE = '/api/v1/user/send-invite'
const API_INVITE_CONTACTS_OUTLOOK = '/api/v1/user/send-invite-outlook'
const API_GET_TOP_CREDIT_HOLDERS = '/api/v1/top-credit-holders';
const API_DEBIT_CREDIT_CAMPAIGN = '/api/v1/user/debit-balance-direct'
const API_GET_DASHBOARD_DATA_URL = '/api/v1/search/get-dashboard-data'
const API_CREATE_CAMPAIGN_BIZWIZZ = '/api/v1/bizwizz/campaign/create'
const API_UPDATE_CAMPAIGN_BIZWIZZ = '/api/v1/bizwizz/campaign/update'
const API_EXECUTE_CAMPAIGN = '/api/v1/bizwizz/campaign/execute'
const API_GET_DEMOS = '/api/v1/demo/get-demos'
const API_GET_CURRENT_USER = '/api/v1/user/get-user'
const API_PROFILE_INFO = '/api/v1/auth/profile-info'
const API_CONTACT_US = '/api/v1/auth/contact-us'
const API_GET_LINKED_CODES = '/api/v1/scraper/get-linkedin-codes'
const API_GET_LINKED_PLACESCODE_FOR_COUNTRY = '/api/v1/scraper/get-linkedin-places-codes-for-country'

const API_GET_SEARCH_DETAILS = '/api/v1/search/get-search-details'
const API_SEND_DATA = '/api/v1/search/send-data'

const API_GET_EXECUTIONS = '/api/v1/bizwizz/campaign/getexecutions'
const API_GET_EXECUTIONS_SMS = '/api/v1/sms/getexecutions'
const API_GET_EXECUTION_DETAILS = '/api/v1/bizwizz/campaign/getexecutiondetails'
const API_GET_EXECUTION_DETAILS_SMS = '/api/v1/sms/getexecutiondetails'
const API_GET_DRIP_EXECUTION_DETAILS = '/api/v1/bizwizz/campaign/getdripexecutiondetails'
const API_GET_CAMPAIGN_EMAIL_STATUS = '/api/v1/bizwizz/campaign/getcampaignemailstatus'
const API_GET_CAMPAIGN_EMAIL_STATUS_NEW = '/api/v1/bizwizz/campaign/getcampaignemailstatusnew'
const API_GET_CAMPAIGN_EMAIL_STATUS_SG = '/api/v1/bizwizz/campaign/getcampaignemailstatussendgrid'
const API_GET_EXECUTION_BOUNCES  = '/api/v1/emailtracker/getexecutionbounces'
const API_GET_EXECUTION_BOUNCES_DRIP  = '/api/v1/emailtracker/getexecutionbouncesdrip'

const API_LOAD_DRIP_TEMPLATES_GLOBAL='/api/v1/drips/loaddriptemplatesglobal';

const API_DELETE_USER_IAMGE='/api/v1/webscraper/deleteuserimage';

const API_LANDING_PAGE_DB_ENTRY = '/api/v1/landingpage/dbentry';
const API_GET_STARTBUCKS_STATUS = '/api/v1/landingpage/getstarbucksstatus'
@Injectable()
export class AuthService {

    constructor(private http: HttpClient) {}
    // Scraper
    scrapeWebsite(web_url: any, user_id: any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('web_url', web_url);
        params = params.append('user_id', user_id);
        return this.http.get<any>(API_SCRAPE_WEBSITE ,{ params: params });
    }
    saveTemplate(data) : Observable<any> {
        return this.http.post<any>(API_SAVE_TEMPLATE, data);
    }
    loadTemplateNames(request: any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('user_id', request);
        return this.http.get<any>(API_LOAD_TEMPLATE ,{ params: params });
    }
    stockImage(request:any) : Observable<any> {    
        let { pageSize, pageIndex, imageTag} = request;
        return this.http.get<any>(`${API_STOCK_IMAGES}?imageTag=${ imageTag }&pageSize=${pageSize}&pageIndex=${pageIndex}`);   
    }
    userImage(request:any, userid:any) : Observable<any> {    
        let { pageSize, pageIndex, imageTag} = request;
        return this.http.get<any>(`${API_USER_IMAGES}?imageTag=${ imageTag }&pageSize=${pageSize}&pageIndex=${pageIndex}&user_id=${userid}`);   
    }
    // End Scraper
    
    // Campaigns
    createCampaign(data) : Observable<any> {
        return this.http.post<any>(API_CREATE_CAMPAIGN, data);
    }
    getcampaigndetails(request:any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('campaignid', request);
        return this.http.get<any>(API_CAMPAIGN_DETAILS ,{ params: params });
    }
    loadSavedCampaigns(request: any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('user_id', request);
        return this.http.get<any>(API_LOAD_CAMPAIGNS ,{ params: params });
    }
    executecampaign(data) : Observable<any> {
        return this.http.post<any>(API_EXECUTE_CAMPAIGN, data);
    }
    //End Campaigns


    // Authentication/Authorization
    login(emailAddress: string, password: string): Observable<User> {
        return this.http.post<User>(API_LOGIN_URL, { emailAddress, password });
        // return this.http.post<User>('http://127.0.0.1:1338'+API_LOGIN_URL, { emailAddress, password });
    }
    editProfile(data): Observable<any> {
		return this.http.post<any>(API_PROFILE_URL, data );
    }
    getBlockedDomain(){
        return this.http.get(API_Get_Blocked_Domain);
    }

    getContactExcludeFilter():Observable<any>{
        return this.http.get(API_CONTACT_EXCLUDE_FILTER);
    }
    checkWebsiteDomain(url:string){
        //return this.http.get('https://api.objenious.com/v1/devices/states?id=3940649673951919,3940649673951920');
return this.http.get(url);
    }
       
    sendEmail(data: any): Observable<any> {
        return this.http.post<any>(API_SEND_EMAIL_URL, data );
    }


    resendVerificationLink(data: any): Observable<any> {
        return this.http.post(API_Resend_Verifcation_Link,data);
    }

    saveCompanyData(company: Company, id: string): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_COMPANY_DATA_URL, {...company, id},{ headers: httpHeaders });
    }

    getCompanyDataById(request): Observable<any> {
        return this.http.post(API_GET_COMPANY_BY_ID,request);
    }

    getCompany(continentId,countryID,websiteLink):Observable<User> {
            return this.http.get<User>(`${API_COMPANY_URL}?continentId=${continentId}&countryID=${countryID}&websiteLink=${websiteLink}`);
    }

    
    saveCompanyData_New(request): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
         return this.http.post(API_COMPANY_DATA_URL,request);
         //return this.http.post<any>(API_COMPANY_DATA_URL, {request},{ headers: httpHeaders });
     }

    
    
    verifyEmail( token: string ): Observable<User> {
        return this.http.get<User>(`${API_VERIFY_EMAIL_URL}?token=${ token }`);
    }

    verifyRecaptcha( response: string ): Observable<User> {
        return this.http.get<User>(`${API_VERIFY_RECAPTCHA_URL}?response=${ response }`);
        // return this.http.get<User>(`http://127.0.0.1:1338${API_VERIFY_RECAPTCHA_URL}?response=${ response }`);
    }
    
    sendOTP( phone: string, id: string ): Observable<any> {
        return this.http.get<any>(`${API_SEND_OTP_URL}?phone=${ phone }&id=${id}`);
    }
    sendOTPAtSignup( phone: string ): Observable<any> {
        return this.http.get<any>(`${API_SEND_OTP_AT_SIGNUP}?phone=${ phone }`);
    }

    changePasword(data: any): Observable<any>  {
        return this.http.post<any>(`${API_UPDATE_PASS_API}`,  { ...data });
      }

    verifyOTP( otp: string, id: string ): Observable<any> {
        return this.http.get<any>(`${API_VERIFY_OTP_URL}?otp=${ otp }&id=${id}`);
    }
    verifyOTPAtSignup( otp: string, secret: string ): Observable<any> {
        return this.http.get<any>(`${API_VERIFY_OTP_AT_SIGNUP}?otp=${ otp }&secret=${secret}`);
    }

    cardDetail(): Observable<any> {
        return this.http.get<any>(`${API_CARD_DETAIL_URL}`);
    }
    transactionDetail(): Observable<any> {
        return this.http.get<any>(`${API_TRANSACTION_URL}`);
    }

    changeCard(token: String): Observable<any> {
        return this.http.post<any>(`${API_CHANGE_CARD_URL}`, {token});
    }

    getSICs(request): Observable<User> {
        // if(industry==null){
        //     return this.http.get<User>(`${API_SICS_URL}`);
        // }

        // let sIndustry = this.Encode(industry); 
        // let queryString = "?Industry="+sIndustry;        
        // return this.http.get<User>(`${API_SICS_URL}${queryString}`);
        
        // let queryString = "?industry_id="+id;        
        // return this.http.get<any>(`${API_SICS_URL}${queryString}`);
        return this.http.get<any>(API_SICS_URL ,{ params: request });
      
    }


    getFormData(): Observable<User> {
        return this.http.get<User>(`${API_FORM_DATA_URL}`);
    }

    getContactTitle(){
        return this.http.get(`${API_CONTACT_TITLE_URL}`);
    }

    

    getIndustry(): Observable<User> {
        return this.http.get<User>(`${API_INDUSTRY_URL}`);
    }
    
    getJapanFormData(): Observable<any> {
        return this.http.get<any>(`${API_JAPAN_FORM_DATA_URL}`);
    }
    
    getIndiaFormData(): Observable<any> {
        return this.http.get<any>(`${API_INDIA_FORM_DATA_URL}`);
    }
     
    getChinaFormData(language = 'en'): Observable<any> {
        return this.http.get<any>(`${API_CHINA_FORM_DATA_URL}?language=${language}`);
    }
    
    linkedIN(): Observable<any> {
        return this.http.get<any>(`${API_LINKEDIN_AUTH_URL}`);
    }

    getCurrentUser(appid: string): Observable<any> {
        return this.http.get<any>(`${API_CURRENT_USER_URL}?appid=${appid}`)
    }

    getUser(id: string): Observable<any> {
        return this.http.get<any>(`${API_GET_CURRENT_USER}?id=${id}`)
    }

    getCountry(pagetype=null): Observable<User> {
        if(pagetype!=null){           
            return this.http.get<User>(`${API_COUNTRY_URL}?page=`+pagetype);
        }
        else{           
            return this.http.get<User>(`${API_COUNTRY_URL}?page=C`);
        }
       
    }

    getHSProduct(name): Observable<User> {
        return this.http.get<User>(`${API_HS_PRODUCT_URL}?name=${name}`);
    }

    getState(countryId): Observable<User> {
        return this.http.get<User>(`${API_STATE_URL}?id=${countryId}`);
    }
    getStateId(stateName,countryID): Observable<User> {
        return this.http.get<User>(`${API_STATENAME_URL}?name=${stateName}&country_id=${countryID}`);
    }
    getStateName(stateID): Observable<User> {
        return this.http.get<User>(`${API_STATE_ID}?id=${stateID}`)
    }
    getCountyId(countryName): Observable<User> {
        return this.http.get<User>(`${API_COUNTRYNAME_URL}?name=${countryName}`);
    }
    getCountryName(countryId): Observable<any> {
        return this.http.get<User>(`${API_COUNTRY_ID}?id=${countryId}`);
    }
    getCounty(stateId): Observable<User> {
        return this.http.get<User>(`${API_COUNTY_URL}?id=${stateId}`);
    }

    getCity(stateId): Observable<User> {
        return this.http.get<User>(`${API_CITY_URL}?id=${stateId}`);
    }

    getCityId(cityName): Observable<User> {
        return this.http.get<User>(`${API_CITY_NAME}?name=${cityName}`)
    }

    getSearchStats(serachId):Observable<User> {
        return this.http.get<User>(`${API_EMAIL_STATS}?id=${serachId}`)
    }

    getCityName(cityId):Observable<User> {
        return this.http.get<User>(`${API_CITY_ID}?id=${cityId}`);
    }
    
    getContinetID(countryId):Observable<User> {
        return this.http.get<User>(`${API_CONTINENT_ID}?id=${countryId}`);
    }

    verifyingCode(code): Observable<User> {
        return this.http.get<User>(`${API_VERIFYING_CODE_URL}?code=${code}`);
    }

    getExpenditure(): Observable<User> {
        return this.http.get<User>(`${API_EXPENDITURE_URL}`);
    }

    getProductTree(): Observable<User> {
        return this.http.get<User>(`${API_PRODUCTTREE_URL}`);
    }

    
    
    getFilteredCompany( data: any ,cName=''): Observable<User> {
        
        console.log("Data", data);
        cName= this.Encode(cName);
        let { pageSize, pageIndex, country} = data;
        
       let queryString = this.getSearchQueryByCountry(data);
        queryString = country != -1 ? `${queryString}&country=${country}`: queryString; 
        queryString = pageIndex ? `${queryString}&pageIndex=${pageIndex}`: queryString; 
        queryString = pageSize ? `${queryString}&pageSize=${pageSize}`: queryString; 
        queryString = cName ? `${queryString}&cName=${cName}`: queryString; 
        

        
        console.log('#query',queryString);
        return this.http.get<User>(`${API_FILTER_COMPANY_URL}${queryString}`,{ headers: new HttpHeaders({ timeout: `${120000}` }) });
    }

    getSearchQueryByCountry(data: any){
        let queryString = '?data=company';
        let {country} = data;
        if(country == 'United States'){
            let { industry, salesRange, employeeRange, company, code,codeType,
                state, city, hsId ,title,zipcode} = data;                          
              let sIndustry = this.Encode(industry);
              let sCompany =   this.Encode(company); 
              let sCode = this.Encode(code);         
              queryString = sIndustry ? `${queryString}&industry=${sIndustry}`: queryString; 
              queryString = salesRange ? `${queryString}&salesRange=${salesRange}`: queryString; 
              queryString = employeeRange ? `${queryString}&employeeRange=${employeeRange}`: queryString; 
              queryString = sCompany ? `${queryString}&company=${sCompany}`: queryString;
              queryString = `${queryString}&codeType=${'SIC'}`;               
              queryString = sCode ? `${queryString}&code=${sCode}`: queryString; 
              queryString = state ? `${queryString}&state=${state}`: queryString; 
              queryString = city ? `${queryString}&city=${city}`: queryString; 
              queryString = hsId ? `${queryString}&hsId=${hsId}`: queryString; 
              queryString = title ? `${queryString}&title=${title}`: queryString; 
              queryString = zipcode ? `${queryString}&zipcode=${zipcode}`: queryString; 
           
          }else if(country == 'Japan'){
            let { industry, product, business, company, salesRange,
                employeeRange} = data;            
                                      
                let sIndustry = this.Encode(industry);
                let sCompany =   this.Encode(company);   
                let sProduct = this.Encode(product);
                let sBusiness =   this.Encode(business); 
              queryString = sIndustry ? `${queryString}&industry=${sIndustry}`: queryString; 
              queryString = salesRange ? `${queryString}&salesRange=${salesRange}`: queryString; 
              queryString = employeeRange ? `${queryString}&employeeRange=${employeeRange}`: queryString; 
              queryString = sCompany ? `${queryString}&company=${sCompany}`: queryString; 
              queryString = sProduct ? `${queryString}&product=${sProduct}`: queryString; 
              queryString = sBusiness ? `${queryString}&business=${sBusiness}`: queryString; 
 
          }else if(country == 'India'){
            let { industry, category, subCategory, company, paidCapital,
                authCapital,state} = data;              
              
                let sIndustry = this.Encode(industry);
                let sCompany =   this.Encode(company);                 

              queryString = sIndustry ? `${queryString}&industry=${sIndustry}`: queryString; 
              queryString = category ? `${queryString}&category=${category}`: queryString; 
              queryString = subCategory ? `${queryString}&subCategory=${subCategory}`: queryString; 
              queryString = sCompany ? `${queryString}&company=${sCompany}`: queryString; 
              queryString = paidCapital ? `${queryString}&paidCapital=${paidCapital}`: queryString; 
              queryString = authCapital ? `${queryString}&authCapital=${authCapital}`: queryString; 
              queryString = state ? `${queryString}&state=${state}`: queryString;
 
          }else if(country == 'China'){
            let { industry, company, salesRange,
                employeeRange, language} = data;              
              
                let sIndustry = this.Encode(industry);
                let sCompany =   this.Encode(company);   

              queryString = sIndustry ? `${queryString}&industry=${sIndustry}`: queryString; 
              queryString = salesRange ? `${queryString}&salesRange=${salesRange}`: queryString; 
              queryString = employeeRange ? `${queryString}&employeeRange=${employeeRange}`: queryString; 
              queryString = sCompany ? `${queryString}&company=${sCompany}`: queryString; 
              queryString = language ? `${queryString}&language=${language}`: queryString; 
              
          }
          return queryString;
      
    }

    
    getCSVData( data: any ): Observable<any> {
        console.log("Data", data);
        
        let { industry, salesRange, employeeRange, company, code,
        USA, India, China, Japan, codeType, pageSize, pageIndex,
         country, state, city, hsId } = data;
        console.log("###industry",industry, ' #company', company);
        
        let queryString = '?data=company';
        queryString = industry ? `${queryString}&industry=${industry}`: queryString; 
        queryString = salesRange ? `${queryString}&salesRange=${salesRange}`: queryString; 
        queryString = employeeRange ? `${queryString}&employeeRange=${employeeRange}`: queryString; 
        queryString = company ? `${queryString}&company=${company}`: queryString; 
        queryString = code ? `${queryString}&code=${code}`: queryString; 
        
        queryString = USA ? `${queryString}&USA=${USA}`: queryString; 
        queryString = India ? `${queryString}&India=${India}`: queryString; 
        queryString = China ? `${queryString}&China=${China}`: queryString; 
        queryString = Japan ? `${queryString}&Japan=${Japan}`: queryString; 
        queryString = codeType ? `${queryString}&codeType=${codeType}`: queryString; 
        console.log();
        
        queryString = pageIndex ? `${queryString}&pageIndex=${pageIndex}`: queryString; 
        queryString = pageSize ? `${queryString}&pageSize=${pageSize}`: queryString; 

        queryString = country != -1 ? `${queryString}&country=${country}`: queryString; 
        queryString = state ? `${queryString}&state=${state}`: queryString; 
        queryString = city ? `${queryString}&city=${city}`: queryString; 
        queryString = hsId ? `${queryString}&hsId=${hsId}`: queryString; 
        console.log('#query',queryString);
        let httpOptions = {
            headers: new HttpHeaders({}),
            responseType: 'text/csv'
          };
          let httpHeaders = new HttpHeaders({ Accept: 'text/csv'});
           
        return this.http.get<any>(`${API_CSV_DOWNLOAD_URL}${queryString}`,{ headers: httpHeaders });
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(API_FORGOT_PASS_URL, { headers: httpHeaders });
    }

    // register(user: User): Observable<any> {
    //     const httpHeaders = new HttpHeaders();
    //     httpHeaders.set('Content-Type', 'application/json');
         
    //     return this.http.post<User>(API_REGISTER_URL, user, { headers: httpHeaders });
         
    // }
    register(user: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
         
        return this.http.post<any>(API_REGISTER_URL, user, { headers: httpHeaders });
         
    }
    profileInfo(user: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
         
        return this.http.post<any>(API_PROFILE_INFO, user, { headers: httpHeaders });
         
    }
    addCard(token: String, email: String): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_CARD_SAVE_URL, { token, email}, { headers: httpHeaders });
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
    	return this.http.get(API_FORGOT_PASS_URL + '?emailAddress='+email)
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }

    public resetPassword(password: string, token: string): Observable<any> {
    	return this.http.post(API_RESET_PASS_URL, {password, token})
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }


    getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_FORGOT_PASS_URL);
    }

    getUserById(userId: number): Observable<User> {
		return this.http.get<User>(API_FORGOT_PASS_URL + `/${userId}`);
	}

    getUserByEmail(email:any):Observable<User> {
        return this.http.get<User>(`${API_GET_USER_DETAILS}?email=${email}`);
    }

    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_FORGOT_PASS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_FORGOT_PASS_URL, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
    	const httpHeaders = new HttpHeaders();
     httpHeaders.set('Content-Type', 'application/json');
		   return this.http.post<User>(API_FORGOT_PASS_URL, user, { headers: httpHeaders});
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_FORGOT_PASS_URL + '/findUsers', queryParams, { headers: httpHeaders});
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    getMiniProfileMasterData(request): Observable<boolean> {
        return this.http.get<any>(API_CONTINENT_COUNTRY_URL ,{ params: request });
    }

    saveMiniProfile(body): Observable<boolean> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        //httpHeaders.append("Content-Type", "multipart/form-data");
        httpHeaders.append("Accept", "application/json");
        return this.http.post<any>(API_SAVE_MINI_PROFILE, body,{ headers: httpHeaders });
    }
    uploadProfilePic(body:FormData): Observable<boolean> {
        const httpHeaders = new HttpHeaders();
        //httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.append("Content-Type", "multipart/form-data");
        httpHeaders.append("Accept", "application/json");
        return this.http.post<any>(API_UPLOAD_IMAGE, body,{ headers: httpHeaders });
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
	}

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    logout(): Observable<any>{
        return this.http.post<any>(API_LOGOUT_URL, {emailAddress: localStorage.getItem('email')} );
    }

    getAllProducts(): Observable<any> {
        return this.http.get<any>(`${API_GET_PRODUCTS_URL}`);
    }

    getAllProductsWithPrices(): Observable<any> {
        return this.http.get<any>(`${API_GET_PRODUCTS_WITH_PRICES_URL}`);
    }

    checkoutSession(priceId: String): Observable<any> {
        return this.http.post<any>(`${API_CHECKOUT_SESSION_URL}`, {priceId} );
    }

    textSearch(query: any, country: any, pageStart: any, pageEnd: any, continentId: number): Observable<any> {
        return this.http.post<any>(`${API_TEXT_SEARCH_URL}`, {query, country, pageStart, pageEnd, continentId} );
    }

    getDashboardData(countryId: any,): Observable<any> {
        return this.http.get<any>(`${API_GET_DASHBOARD_DATA_URL}?countryId=${countryId}`);
    }

    reExecuteTextSearch(query: any, country: any, row_size:any, start:any): Promise<any> {
        return this.http.post(`${API_RE_EXECUTE_TEXT_SEARCH_URL}`, {query, country, row_size, start} ).toPromise();
    }

    indexingSearch(): Observable<any> {
        return this.http.get<any>(`${API_INDEXING_UPDATE}`);
    }
    searchSave(data:any):Observable<any>{
        console.log("data in search save", data)
        let maxRec = parseInt(data.maxEmailVerify) * 4;
        let queryModified = data.searchquery.replace('~3500', `~${maxRec.toString()}`)
        console.log('modified query', queryModified);
        return this.http.post<any>(`${API_SEARCH}`, {
            userid:data.userid,
            countryid:data.countryid,
            searchname:data.searchname,
            query:queryModified,
            emailcount:data.emailcount,
            maxEmailVerify:data.maxEmailVerify,
            isSolrSearch:data.isSolrSearch,
            isUISearch: 1
        } );
    }
    requestData(searchId:any, lkQuery:any):Observable<any>{
        return this.http.post<any>(`${API_SEARCH_REQUEST_DATA}`,{searchId, lkQuery});   
    }
    requestDataLK(keyword:any, lk_query:any, country_code: any, numberofRecords: any):Observable<any>{
        return this.http.post<any>(`${API_SAVE_REQUEST_DATA_LK}`,{keyword, lk_query, country_code, numberofRecords});   
    }
    getSearch(userid:any):Observable<any>{
        return this.http.get<any>(`${API_SEARCH}?userid=${ userid }`);   
    }

    getVerifiedSearch(userid:any):Observable<any>{
        return this.http.get<any>(`${API_VERIFIED_SEARCH}?userid=${ userid }`);   
    }

    getBlazeVerifiedEmails(companyids:any):Promise<any>{
        return this.http.post<any>(`${API_VERIFIED_BLAZE_EMAILS}`, { companyIds:companyids }).toPromise();   
    }

    deleteSearch(searchid:any):Observable<any>{
        return this.http.delete<any>(`${API_SEARCH}?searchid=${ searchid }`);   
    }
    createEmailsBatchToVerify(searchId:any):Observable<any>{
        return this.http.post<any>(`${API_VERIFY_EMAILS}`, {searchId:searchId} );       
    }
    updateUserSearchVerified(searchId:any) :Observable<any> {
        return this.http.post<any>(`${API_UPDATE_USERSEARCH}`,{searchId:searchId});
    }
    batchStatus(searchId:any):Observable<any>{
        return this.http.post<any>(`${API_GET_VERIFY_EMAIL_STATUS}`, {searchId:searchId} );
    }

    searchProduct(query: any): Observable<any> {
        return this.http.post<any>(`${API_PRODUCT_TEXTSEARCH}`, {query: query});;
    }

    searchIndustry(query: any): Observable<any> {
        return this.http.post<any>(`${API_INDUSTRY_TEXTSEARCH}`, {query: query});;
    }
    searchCompanyName(query: any): Observable<any> {
        return this.http.get<any>(`${API_COMPANY_NAME_SEARCH}?query=${query}&country=231&pageStart=0&pageEnd=10`);;
    }

    Encode(value){ 
        let re = /&/gi;
        if(value)
        {
            // return btoa(value);
            return encodeURIComponent(value); //.replace(re,"");
        }  
        else{
            return value;
        }     
    }
    uploadUserPics(body:FormData, token:string): Observable<boolean> {
        /* const httpHeaders = new HttpHeaders();
        httpHeaders.append("Content-Type", "multipart/form-data");
        httpHeaders.append("Accept", "application/json");
        return this.http.post<any>(API_UPLOAD_USER_IMAGES + '?token=' + token, body,{ headers: httpHeaders }); */
        
        const httpHeaders = new HttpHeaders({
            'Authorization': token,
        });
        return this.http.post<any>('https://cognitiveclicks.com:4000/upload', body, { headers: httpHeaders });

    }

    getEmailSubPrices(): Observable<any> {
        return this.http.get<any>(`${API_GET_EMAIL_SUB_PRICES}`);
    }
    getPlanDetails(): Observable<any> {
        return this.http.get<any>(`${API_GET_PLAN_DETAILS}`);
    }
    getEmailUserCurrentPlan(user_id:any): Observable<any> {
        return this.http.get<any>(`${API_GET_EMAIL_USER_PLAN}?user_id=${ user_id }`);   
    }
    saveEmailUserPlan(data:any):Observable<any>{
        return this.http.post<any>(`${API_SAVE_EMAIL_USER_PLAN}`, {user_id:data.user_id, current_plan:data.current_plan, email:data.email, name: data.name} );
    }
    subscribeToStripe(data:any):Observable<any>{
        return this.http.post<any>(`${API_SAVE_EMAIL_SUBSCRIBE_TO_STRIPE}`, {user_id:data.user_id, token: data.token, stripe_id: data.stripe_id, 
            price_id: data.price_id, price_name: data.price_name, old_sub_id: data.old_sub_id} );
    }
    subscribeToPayAsYouGo(data:any):Observable<any>{
        return this.http.post<any>(`${API_SAVE_EMAIL_SUBSCRIBE_TO_PAYG}`, {user_id:data.user_id, old_sub_id: data.old_sub_id} );
    }
    updateSendVolume(data:any):Observable<any>{
        return this.http.post<any>(`${API_UPDATE_SEND_VOLUME}`, {user_id:data.user_id, total_added:data.total_added} );
    }
    directPay(data:any):Observable<any>{
        return this.http.post<any>(`${API_DIRECT_PAY}`, {user_id:data.user_id, stripe_id:data.stripe_id, 
            token: data.token, amount:data.amount, currency: data.currency} );
    }
    directPayment(data:any):Observable<any>{
        return this.http.post<any>(`${API_DIRECT_PAYMENT}`, data);
    }
    howManyToVerify(searchId:any): Observable<any> {
        return this.http.get<any>(`${API_HOW_MANY_TO_VERIFY}?searchId=${ searchId }`);   
    }
    getEmailsFromSavedSearch(searchid:any, emailCount:any):Observable<any>{
        return this.http.get<any>(`${API_EMAILS_FROM_SAVED_SEARCH}?searchid=${ searchid }&emailCount=${emailCount}`);   
    }
    startEmailVerification(data:any):Observable<any>{
        return this.http.get<any>(`${API_START_EMAIL_VERIFICATION}?searchId=${data.searchId}&isSolrSearch=${data.isSolrSearch}`);   
    }
    sendverifiedEmail(data:any): Observable<any> {
        let url= `https://cognitiveclicks.com:3000/api/v1.0/verify-ui?search_id=${data.searchId}`;

        // if(data.blockId && data.userId){
        //     url += `&blockId=${data.blockId}&userId=${data.userId}`
        // }

        fetch(url);
        return this.http.get<any>(`${API_HOW_MANY_TO_VERIFY}?searchId=${ 0 }`);   
    }
    getEmailCampaignConfig(): Observable<any> {
        let url = `${environment.cognitiveclicks}/get-email-campaign-config`;
        return this.http.get<any>(`${url}`);   
    }

    getWaitSkipConfig(id): Observable<any> {
        let url= `${environment.cognitiveclicks}/get-skip-wait-config/${id}`;
        return this.http.get<any>(`${url}`);   
    }
    setEmailCampaignConfig(data: any, method): Observable<any> {
        let url= `${environment.cognitiveclicks}/set-email-campaign-config`;
        let doneObserv = null;
        switch (method) {
            case 'post':
                doneObserv = this.http.post<any>(`${url}`, { ...data });
                break;
            case 'put':
                doneObserv = this.http.put<any>(`${url}`, { ...data });
                break;
            case 'delete':
                url = `${url}/${data.id}`;
                doneObserv = this.http.delete<any>(`${url}`, { ...data });
                break;
        }
        return doneObserv;
    }

    skipWaitDomainConfig(data: any, method): Observable<any> {
        let url= `${environment.cognitiveclicks}/skip-wait-domain-config`;
        let doneObserv = null;
        switch (method) {
            case 'post':
                doneObserv = this.http.post<any>(`${url}`, { ...data });
                break;
            case 'put':
                doneObserv = this.http.put<any>(`${url}`, { ...data });
                break;
            case 'delete':
                url = `${url}/${data.id}`;
                doneObserv = this.http.delete<any>(`${url}`, { ...data });
                break;
        }
        return doneObserv;
    }

    activateEmailConfig(data: any, method): Observable<any> {
        let url= `${environment.cognitiveclicks}/activate-email-campaign-config`;
        let doneObserv = null;
        switch (method) {
            case 'put':
                doneObserv = this.http.put<any>(`${url}`, { ...data });
                break;
        }
        return doneObserv;
    }

    getRegisteredUsersCount(website:any):Observable<any>{
        if(website) {
            return this.http.get<any>(`${API_GET_REGISTERED_USERS_COUNT}?website=${website}`);
        }else {
            return this.http.get<any>(`${API_GET_REGISTERED_USERS_COUNT}`);
        }
           
    }
    getWebscanData(website):Observable<any>{
        if(website) {
            return this.http.get<any>(`${API_GET_WEBSCAN_DATA}?website=${website}`);   
        } else {
            return this.http.get<any>(`${API_GET_WEBSCAN_DATA}`);   
        }
        
    }
    getUsersForAdmin():Observable<any>{
        return this.http.get<any>(`${API_GET_USERS_FOR_ADMIN}`);   
    }

    setUserAsAdminOrSuperAdmin(data: any): Observable<any>  {
        return this.http.post<any>(`${API_SET_USER_ADMIN_SADMIN}`,  { ...data });
    }
    checkcsv(body:FormData, uid:string): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.append("Content-Type", "multipart/form-data");
        httpHeaders.append("Accept", "application/json");
        return this.http.post<any>(API_CHECK_CSV + '?user_id=' + uid, body,{ headers: httpHeaders });
    }
    verifyCSVEmails(data:any):Observable<any>{
        //console.log(data);
        return this.http.post<any>(`${API_VERIFY_CSV_EMAILS}`, {
            verificationId: data.verificationId,
            fileName: data.fileName,
            emails:data.emails,
            user_id: data.user_id,
            blockId:data.blockId,
            DataTradingOpted:data.DataTradingOpted
        } );
    }

    stopVerification(searchId:any, recID: any):Observable<any>{
        return this.http.post<any>(`${API_POST_STOP_VERIFICATION}`, {
            searchId: searchId,
            recID: recID
        } );
    }

    getVerifiedCSVList(user_id:any):Observable<any>{
        return this.http.get<any>(`${API_GET_VERIFIED_CSV_LIST}?user_id=${user_id}`);   
    }
    getVerifiedEmailsList(recID:any, dataType:any):Observable<any>{
        let httpHeaders = new HttpHeaders({ Accept: 'text/csv'});
        return this.http.get<any>(`${API_GET_VERIFIED_EMAILS_LIST}?recID=${recID}&dataType=${dataType}`, { headers: httpHeaders });   
    }
    getVerificationStats(recId:any):Observable<any>{
        return this.http.get<any>(`${API_GET_VERIFICACTION_STATS}?recId=${recId}`);   
    }
    getAllCountries():Observable<any>{
        return this.http.get<any>(`${API_GET_ALL_COUNTRIES}`);   
    }
    getSubscribedUsers(website: any):Observable<any>{
        if (website) {
            return this.http.get<any>(`${API_GET_SUB_USERS}?website=${website}`)
        } else {
            return this.http.get<any>(`${API_GET_SUB_USERS}`);   
        }
    }
    getCreditBalance(userId:any):Observable<any>{
        return this.http.get<any>(`${API_GET_CREDIT_BALANCE}?userId=${userId}`);   
    }

    fundCreditAccount(data:any):Observable<any>{
        return this.http.post<any>(`${API_FUND_CREDIT_ACCOUNT}`, {user_id:data.user_id, stripe_id:data.stripe_id, 
            token: data.token, amount:data.amount, currency: data.currency, credits:data.credits, canSkipPayment: data.canSkipPayment} );
    }

    fetchTrasactionsHistory(userId:any):Observable<any>{
        return this.http.get<any>(`${API_FETCH_CREDIT_TRANSACTIONS}?userId=${userId}`);   
    }

    
    blockCreditBalance(data:any):Observable<any>{
        return this.http.post<any>(`${API_BLOCK_CREDIT_BALANCE}`,{
            "userId":data.userId,
            "blockAmount":data.blockAmount,
            "description":data.description
        });
    }

    sendInviteGoogle(data:any):Observable<any>{
        return this.http.post<any>(`${API_INVITE_CONTACTS_GOOGLE}`,data);
    }
    sendInviteOutlook(data:any):Observable<any>{
        return this.http.post<any>(`${API_INVITE_CONTACTS_OUTLOOK}`,data);
    }

    getTopCreditHolders():Observable<any>{
        return this.http.get<any>(`${API_GET_TOP_CREDIT_HOLDERS}`);   
    }
    debitCreditsForCampaign(data:any):Observable<any>{
        return this.http.post<any>(`${API_DEBIT_CREDIT_CAMPAIGN}`,data);
    }

    createCampaignBizwizz(data) : Observable<any> {
        return this.http.post<any>(API_CREATE_CAMPAIGN_BIZWIZZ, data);
    }
    updateCampaignBizwizz(data) : Observable<any> {
        return this.http.post<any>(API_UPDATE_CAMPAIGN_BIZWIZZ, data);
    }
    executeCampaignBizwizz(data) : Observable<any> {
        return this.http.post<any>(API_EXECUTE_CAMPAIGN, data);
    }
    getDemos():Observable<any>{
        return this.http.get<any>(`${API_GET_DEMOS}`);   
    }
    contactUS(data: any) : Observable<any> {
        return this.http.post<any>(API_CONTACT_US, data);
    }

    getLinkedInCodes(type: any): Observable<any> {
        return this.http.get<any>(`${API_GET_LINKED_CODES}?type=${type}`);
    }
    getLinkedInPlacesCodesForCountry(countryCode: any, country: any): Observable<any> {
        return this.http.get<any>(`${API_GET_LINKED_PLACESCODE_FOR_COUNTRY}?countryCode=${countryCode}&country=${country}`);
    }
    getSearchDetails(searchId: any, userId: any): Observable<any> {
        return this.http.get<any>(`${API_GET_SEARCH_DETAILS}?searchId=${searchId}&userId=${userId}`);
    }
    sendData(searchId: any, userId: any): Observable<any> {
        return this.http.get<any>(`${API_SEND_DATA}?searchId=${searchId}&userId=${userId}`);
    }
    getExecutions(request:any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', request);
        return this.http.get<any>(API_GET_EXECUTIONS ,{ params: params });
    }
    getExecutionsSMS() : Observable<any> {
        return this.http.get<any>(API_GET_EXECUTIONS_SMS)
    }
    getExecutionDetails(id:any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get<any>(API_GET_EXECUTION_DETAILS ,{ params: params });
    }
    getExecutionDetailsSMS(id:any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get<any>(API_GET_EXECUTION_DETAILS_SMS ,{ params: params });
    }
    getCampaignEmailStatus(data: any) : Observable<any> {
        return this.http.post<any>(API_GET_CAMPAIGN_EMAIL_STATUS, data);
    }
    getCampaignEmailStatusNew(id: any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get<any>(API_GET_CAMPAIGN_EMAIL_STATUS_NEW, { params: params});
    }
    getCampaignEmailStatusSendgrid(id: any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get<any>(API_GET_CAMPAIGN_EMAIL_STATUS_SG, { params: params});
    }
    getExecutionBounces(data: any) : Observable<any> {
        return this.http.post<any>(API_GET_EXECUTION_BOUNCES, data);
    }
    loadDripTemplatesGlobal() : Observable<any> {
        return this.http.get<any>(API_LOAD_DRIP_TEMPLATES_GLOBAL );
    }
    deleteUserImage(data: any) : Observable<any> {
        return this.http.post<any>(API_DELETE_USER_IAMGE, data);
    }

    landingPageDBEntry(data: any) : Observable<any> {
        return this.http.post<any>(API_LANDING_PAGE_DB_ENTRY, data);
    }
    getStarBucksGiftCards(searchOnlyAvailable: any,) {
        let params = new HttpParams();
        params = params.append('searchOnlyAvailable', searchOnlyAvailable);
        return this.http.get<any>(API_GET_STARTBUCKS_STATUS, { params: params});
    }
    getIpAddress() {
        return fetch('http://api.ipify.org/?format=json')
    }
    getDripExecutionDetails(id:any) : Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get<any>(API_GET_DRIP_EXECUTION_DETAILS ,{ params: params });
    }
    getExecutionBouncesDrip(data: any) : Observable<any> {
        return this.http.post<any>(API_GET_EXECUTION_BOUNCES_DRIP, data);
    }
    /*  () {
       /*  let httpx = require('http');
        return new Promise ((resolve, reject) => {
            httpx.get({'host': 'api.ipify.org', 'port': 80, 'path': '/'}, function(resp:any) {
                resp.on('data', function(ip:any) {
                    resolve(ip);
                    console.log("My public IP address is: " + ip);
                });
            });
        });
    } */
}
