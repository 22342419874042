import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Injectable, Inject, Optional} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ITreeOptions } from 'angular-tree-component';
import { SelectedExpenditure } from '../../../../core/auth';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'kt-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent {
  displayedColumns: string[] = ['position', 'name', 'range'];
  dataSource = [];
  // [{position: 1, name: 'Hydrogen', range:10},
	// {position: 2, name: 'Helium', range:20}];
  options: ITreeOptions = { 

		useCheckbox:true,
		allowDrag: (node) => {
		  return false;
		},
		displayField: 'label',
		allowDrop: (node) => {
		  return true;
		},
	  };
	expenditures = [];
	errorExpense = false;
	breadcrumbList = [{name: 1},{name: 1},{name: 1},{name: 1},{name: 1}, ]
	selectedExpenditures : any = [];
  selectedExpenseRange: any = [];
  parentNodesLabel=[];
	treeType='E';
	mode=''
  constructor(
	  private toaster:ToastrService,
    public dialogRef: MatDialogRef<TreeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public result: any
    ) {
		this.mode=result.mode;
      this.treeType=result.treeType;
    this.expenditures=result.treeData;
    
  }

  onEvent(event){
		console.log('#event', event);
		debugger;
		this.extractSelectedNodeData(event.node)
  }
  onSelect(event){
		console.log('#event', event);
		this.extractSelectedNodeData(event.node)
  }
  
  onDeSelect(event){
		console.log('#event', event);
		this.extractSelectedNodeData(event.node)
	}
  expenseSelectionChange(event, id) {
		console.log('#expenseSelectionChange',event, id);
		
		let index = this.selectedExpenditures.findIndex(expenditure => expenditure.id == id );
		if( index > -1 ) {
			this.selectedExpenditures[index].selectedRange = event.value == "Select range" ? '' :  event.value;
		}
		
		console.log('#selectedExpenditures', this.selectedExpenditures);
		
  }
  
  removeExpenditure(expenditure) {
		console.log('#expenditure', expenditure);
		console.log('#selectedExpenditures', this.selectedExpenditures);
		for( var i = 0; i <= this.selectedExpenditures.length-1; i++){
			console.log('inloop', this.selectedExpenditures[i].id ,  expenditure.id );
			
			if ( this.selectedExpenditures[i].id == expenditure.id ) {
				this.selectedExpenditures.splice(i, 1);
				//this.cdr.markForCheck();
				break;
			}
		}
  }
  
  Submit(){

	if((this.mode=='buyProduct' || this.mode=='sellProduct') && this.selectedExpenditures.length>3){
		this.toaster.warning('You can not select more than 3 product','Warning');
		return;
	}
	if((this.mode=='') && this.selectedExpenditures.length<1){
		this.toaster.warning('Please select minimum 1 item','Warning');
		return;
	}

	  let response={
		treeType:this.treeType,
		  data:this.selectedExpenditures,
		  mode:this.mode
	  }
    this.dialogRef.close(response);
  }
  onCancel(){
	let response={
		treeType:this.treeType,
		  data:[],
		  mode:this.mode
	  }
    this.dialogRef.close(response);
  }

	extractSelectedNodeData(node) {
		debugger;
		console.log('#extractSelectedNodeData',node);
		
		if (node && node.data && node.data.children && node.data.children.length == 0 ){
		
			console.log('#node.data',node.data);
			let nodeData = node.data;
			this.parentNodesLabel = [];
			let nodeParent = this.getNodeParents(node);
			console.log('#nodeParent', nodeParent);
			console.log('#parentNodesLabel', this.parentNodesLabel);
			let selectedExpenditure = new SelectedExpenditure();
			let isExists = this.selectedExpenditures.find( expense => expense.id == node.data.id );
			console.log('#isExists', isExists);
			if (!isExists){
				selectedExpenditure.id = node.data.id;
				selectedExpenditure.name = node.data.label;
				selectedExpenditure.range = node.data.options;
				selectedExpenditure.path = this.parentNodesLabel;
        this.selectedExpenditures.push(selectedExpenditure);
        this.dataSource=this.selectedExpenditures;
			}else{
				//this.openSnackBar('Expense already added');

				for( var i = 0; i <= this.selectedExpenditures.length-1; i++){
					console.log('inloop', this.selectedExpenditures[i].id ,  node.data.id );
					if ( this.selectedExpenditures[i].id == node.data.id ) {
						this.selectedExpenditures.splice(i, 1);
						break;
					}
				}
			}
			console.log('#selectedExpenditures', this.selectedExpenditures);
		}
  }
  
  getNodeParents(node){
		let parentName = node.data.label;
		if(node.parent !== null){
			this.parentNodesLabel.splice( 0, 0, { name: node.data.label} );
			return this.getNodeParents(node.parent);
		}
		else{
			console.log('parent', node, node.data.label);
			return node.data.label;
		}
	}

}
