import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class LoaderService {

constructor(private router: Router) {
}

  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  display(value: boolean) {
    setTimeout(() => {
      this.status.next(value);
    }, 100);
  }
}