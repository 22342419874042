// Angular
import { Component, ViewChild, OnInit, ChangeDetectorRef, Inject, Output, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
// Lodash
import { ceil, round, shuffle } from 'lodash';
// Services
// Widgets model
import { LayoutConfigService, SparklineChartOptions } from '../../../core/_base/layout';
//ng bootstrap
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginator, MatSort, MatTableDataSource, MatSelect, MatDialog, PageEvent } from '@angular/material';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
// import  Angular2Csv  from 'angular2-csv/Angular2-csv';
import { AuthNoticeService, AuthService, SearchFilter} from '../../../core/auth';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
 import _ from 'lodash';
//snackbar
import {MatSnackBar, MatSnackBarHorizontalPosition,	MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { CardData } from 'angular-credit-cards';
import {HttpClient} from "@angular/common/http";
import { int } from 'aws-sdk/clients/datapipeline';
import { integer } from 'aws-sdk/clients/cloudfront';
import { map, startWith, tap, takeUntil, first } from 'rxjs/operators';

// Dialogs
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { email } from '@rxweb/reactive-form-validators';
import { exit } from 'process';
import { Chart, ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, BaseChartDirective } from 'ng2-charts';
import { LoaderService } from '../../theme/loader.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
	StripeCardElementOptions,
	StripeElementsOptions
  } from '@stripe/stripe-js';
import { Location } from '@angular/common';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { VerifierUIService } from './verifierui.service';
import { StatsComponentDialog } from "./stats-popup/stats-popup.component";
import { element } from 'protractor';
export interface lstCSV {
	id: string;
	status: string;
	result: string;
	createdAt: string;
	fileName: string;
	creditsEarned: string;
	recId: string;
}
@Component({
	selector: 'kt-dashboard',
	templateUrl: './verifierui.component.html',
	styleUrls: ['verifierui.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
	  }]
})

export class VerifierUIComponent implements OnInit, AfterViewInit {
	userid: any;
	selectedFile: any = '';
	paymentComplete: boolean = false;
	emailsToVerify: any = 0;
	// defaultRate = 10; // Verification per email -> 10 credit = 0.04 usd = 4 cent | Old rate
	defaultRate: any = 1; // 1 email costs -> 0.1 credits = 0.04 usd = 4 us cent
	longNetworkOperations: boolean = false;
	userStripeId:string = null;
	@ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
	@ViewChild('inputCSV', {static: true}) inputCSV: ElementRef;
	@ViewChild('paginatorcsvlist', { static: true }) paginatorusers: MatPaginator;
	cardOptions: StripeCardElementOptions = {
		style: {
		base: {
			iconColor: '#666EE8',
			color: '#31325F',
			fontWeight: '300',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSize: '18px',
			'::placeholder': {
			color: '#CFD7E0'
			}
		}
		}
	};
	elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};
	stripeTest: FormGroup;
	paymentinprogress:boolean = false;
	canSkipPayment = false;
	arrCsvEmails =[];
	uploadedFileName =[];
	verifiedResult =  [];
	csvLists: lstCSV[] = [];
	dataSourceCSVList = new MatTableDataSource<lstCSV>(this.csvLists);
	displayedColumns: string[] = ['id', 'fileName', 'status', 'createdAt', 'download'];
	emailsObj:any;
	optDataTrading = 0;
	dtChecked = false;
	canOptForDataTrading = false;
	constructor(private layoutConfigService: LayoutConfigService,
				private auth: AuthService,
				private fb: FormBuilder,
				private _snackBar: MatSnackBar,	
				private http:HttpClient,
				private loader:LoaderService,
				private stripeService: StripeService,
				private verifierService:VerifierUIService,
				private cdr: ChangeDetectorRef,
				private location: Location,
				public dialog: MatDialog,
		) {
			this.loadCurrentPlan();
			this.userid=localStorage.getItem('id');
			if(localStorage.getItem('IsAdmin')=="2"){
				this.canSkipPayment = true; // true for testing locally
			}else{
				this.canSkipPayment = false;
			}
			// this.getCSVList();
	}
	
	ngOnInit(): void {
		this.initUserForm();
	}
	
	ngAfterViewInit() {	
		
	}

	roundNumber(num: any) {
		return (round(num));
	}
	initUserForm() {
		this.stripeTest = this.fb.group({
			name: ['', [Validators.required]]
		  });	
	}
	getCSVList(){
		this.loader.display(true);
		this.csvLists = [];
		this.auth.getVerifiedCSVList(this.userid).subscribe(res_data => {
			console.log('list data--->', res_data);
			res_data.data.forEach(element => {
				let status = element.status === true ? 'Verified' : 'In Progress'
				var d = new Date(element.createdAt);
				var dtc = d.toLocaleString();
				this.csvLists.push({
					id: element.id,
					result: element.result,
					status: status,
					createdAt: dtc,
					creditsEarned: element.CreditsEarned,
					fileName: element.fileName,
					recId: element.RecId
				});
			});
			this.loader.display(false);
			this.updateCSVList();
		},(error)=>{
			//console.log(error);
			this.loader.display(false);
			this.openSnackBar('Error fetching list of verifications!', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		})
	}
	updateCSVList() {
		console.log(this.csvLists)
		this.dataSourceCSVList = new MatTableDataSource<lstCSV>(this.csvLists);
		this.dataSourceCSVList.paginator = this.paginatorusers;
		this.cdr.detectChanges();
	}
	openSnackBar(message: string, action: string, horizontal: MatSnackBarHorizontalPosition, vertical: MatSnackBarVerticalPosition, duration: integer) {
		this._snackBar.open( message, action, {
			duration: duration,	
			horizontalPosition: horizontal,
      		verticalPosition: vertical
		});
	}
	selectCSVFile(event) {
		this.dtChecked = false;
		this.canOptForDataTrading =false;
		this.selectedFile = event.target.files;
		if (this.selectedFile.length <=0) {
			this.openSnackBar('Please select a csv file', 'OK', "start", "top", 5000 );	
			return;
		}
		const formData: FormData = new FormData();
				
		formData.append('csv', this.selectedFile[0]);
		this.loader.display(true);
		
		this.auth.checkcsv(formData, localStorage.getItem('id')).subscribe(res_data => {
			console.log("res_data===>", res_data);
			// console.log(res_data.data);
			this.loader.display(false);
			this.arrCsvEmails = res_data.data || [];
			this.uploadedFileName = res_data.fileName;

			// for (let i = 0; i < res_data.data.length; i++) {
			// 	this.arrCsvEmails.push(res_data.data[i]);
			// }

			if (res_data.canTradeData === "no") {
				this.canOptForDataTrading =false;
				this.dtChecked = false;
			} else {
				this.canOptForDataTrading =true;
			}

			this.cdr.detectChanges();

			console.log("arrCsvEmails===>", this.arrCsvEmails);

			this.emailsToVerify = this.arrCsvEmails.length;
			this.openSnackBar('File uploaded!', 'OK', "start", "top", 5000 );	
		},(error)=>{
			this.loader.display(false);
			this.arrCsvEmails = [];
			this.inputCSV.nativeElement.value = "";
			console.log("Error--->",error);
			if (error.error.code === 1){
				this.openSnackBar(error.error.errormsg, 'OK', "start", "top", 5000 );
			} else {
				this.openSnackBar('Could not process csv file!', 'OK', "start", "top", 5000 );		
			}
			this.cdr.detectChanges();
		})
    }
	download(event, row: any){
		event.target.innerHTML = 'Downloading...';
	
		this.auth.getVerifiedEmailsList(row.recId, 'DATA').subscribe((data) => {
		   event.target.innerHTML = 'Downlaod'
		   this.verifierService.downloadAllValid(data.data, `StanaloneSearch_${row.id}.csv`);
		});
	}

	stopVerification(row: any) {
		if (confirm('Are you sure, you want to stop email verification for file - ' + row.fileName + '? You won\'t be able to start it again. However you can upload same file again to intiate the verification.')){
			this.auth.stopVerification(-111, row.recId).subscribe(data => {
			  console.log(data, 'stopVerification result');
			  this.getCSVList();
			});
		}
	}

	getStatsCount(data:any) {
		let obj = {
			Valid: 0,
			InValid: 0
		};

		if (data && data.valid) {
			obj.Valid = data.valid.length;
		}

		if (data && data.invalid) {
			obj.InValid = data.invalid.length;
		}

		return obj;
	}

	getVerificationStats(row:any) {
		const dialogRef = this.dialog.open(StatsComponentDialog,{
			height: '50hw',
			width: '50vw',
			data: {
				data: row,
			}
		});
	}

	getStats(data:any, id:any, credits:any) {
		console.log(credits)
		this.loader.display(true);
		let totalcount = data.length;
		let risky = 0;
		let uncertain = 0;
		let valid = 0;
		let invalid = 0;
		data && data.forEach(element => {
			switch (element.Status) {
				case 'Risky':
					risky++;
					break;
				case 'Uncertain':
					uncertain++;
					break;
				case 'Valid':
					valid++;
					break;
				case 'Invalid':
					invalid++;
					break;
				default:
					break;
			}
		});
		
		let riskypercent = (risky / totalcount) * 100;
		let uncertainpercent = (uncertain / totalcount) * 100;
		let validpercent = (valid / totalcount) * 100;
		let invalidpercent = (invalid / totalcount) * 100;
		
		let riskyval = riskypercent.toFixed(2);
		let uncertainval = uncertainpercent.toFixed(2);
		let validval= validpercent.toFixed(2);
		let invalidval = invalidpercent.toFixed(2);

		this.emailsObj={
			Risky:riskyval,
			Uncertain: uncertainval,
			Valid: validval,
			Invalid: invalidval
		}
		// console.log("this.emailsObj",this.emailsObj);
		this.loader.display(false);
		const dialogRef = this.dialog.open(StatsComponentDialog,{
			height: '50hw',
			width: '50vw',
			data: {
				dataKey: this.emailsObj,
				searchname: id,
				creditsEarned: credits
				
			}
		});
	}
	
	loadCurrentPlan(){
		this.longNetworkOperations = true;
		this.auth.getEmailUserCurrentPlan(localStorage.getItem('id')).subscribe((res_data:any)=>{
			console.log('User Plan data==>',res_data.result);
			if (res_data.result.length > 0) {
				this.userStripeId = res_data.result[0].stripe_id;
			}else {
				this.userStripeId = null;
			}
			this.longNetworkOperations = false;
			this.cdr.detectChanges();
		},(error)=>{
			this.cdr.detectChanges();
			this.longNetworkOperations = false;
		})
	}
	onTabChanged($event) {
		let clickedIndex = $event.index;
		if(clickedIndex === 1) {
			this.getCSVList();
		}
	}
	optForDataTrading(event:any){
		if(event.source.checked === true) {
			this.optDataTrading = 1;
			this.dtChecked = true;
		} else {
			this.optDataTrading = 0;
			this.dtChecked = false;
		}
		this.cdr.detectChanges();
		console.log(this.optDataTrading)
		console.log(this.dtChecked)
	}

	chargeCreditsForVerification(){
		/*if (this.optDataTrading === 1 && this.canSkipPayment === false) { // disable till merge of datag branch; only allow for su admins(test)
			this.openSnackBar('Data trading is currently not available for all users.', 'OK', "start", "top", 5000 );	
			return;
		}*/
		console.log('----->', round(this.emailsToVerify*this.defaultRate));

		this.loader.display(true);
		this.longNetworkOperations = true;
		let _this = this;

		if(this.canSkipPayment) {
			setTimeout(function () {
				_this.paymentComplete = true;
				_this.loader.display(false);
				_this.longNetworkOperations = false;
				_this.openSnackBar('Payment complete. Sending emails for verification...', 'OK', "start", "top", 5000 );	

				// Send for verification
				console.log(_this, '_this');
				
				let data = {
					fileName: _this.uploadedFileName,
					emails: _this.arrCsvEmails,
					user_id: _this.userid,
					verificationId: _this.userid.toString() + ((Math.floor(10000 + Math.random() * 90000)).toString()), //Date.now()
					DataTradingOpted: _this.optDataTrading
				}

				_this.loader.display(true);
				_this.longNetworkOperations = true;
				
				_this.auth.verifyCSVEmails(data).subscribe(res_data => {
					console.log(res_data);
					_this.loader.display(false);
					_this.longNetworkOperations = false;
					_this.openSnackBar('Emails sent for verification. To check status, go to "Verifivation List\'s" section', 'OK', "start", "top", 5000 );		
				},(error)=>{
					_this.loader.display(false);
					_this.longNetworkOperations = false;
					_this.arrCsvEmails = [];
					console.log(error);
					_this.openSnackBar('Failed to verify emails', 'OK', "start", "top", 5000 );	
					_this.cdr.detectChanges();
				})
			}, 3000);
		} else {
			this.auth.blockCreditBalance({
				"userId":localStorage.getItem('id'),
				"blockAmount":round(this.emailsToVerify*this.defaultRate),
				"description":`Charge for standalone verfication of ${this.emailsToVerify}`
			}).subscribe((res_data:any)=>{
				console.log('User Paid===>', res_data)
				this.longNetworkOperations = false;
				this.loader.display(false);
				this.paymentinprogress = false;
				debugger
				setTimeout(function () {
					_this.paymentComplete = true;
					_this.loader.display(false);
					_this.longNetworkOperations = false;
					_this.openSnackBar('Payment complete. Sending emails for verification...', 'OK', "start", "top", 5000 );	
					// Send for verification
					let data = {
						fileName: _this.uploadedFileName,
						emails: _this.arrCsvEmails,
						user_id: _this.userid,
						verificationId: _this.userid.toString() + ((Math.floor(10000 + Math.random() * 90000)).toString()), //Date.now()
						blockId: res_data.data.blockId,
						DataTradingOpted: _this.optDataTrading
					}
					_this.loader.display(true);
					_this.longNetworkOperations = true;
					_this.auth.verifyCSVEmails(data).subscribe(res_data => {
						console.log(res_data);
						_this.loader.display(false);
						_this.longNetworkOperations = false;
						_this.openSnackBar('Emails sent for verification. To check status, go to "Verifivation List\'s" section', 'OK', "start", "top", 5000 );		
					},(error)=>{
						_this.loader.display(false);
						_this.longNetworkOperations = false;
						_this.arrCsvEmails = [];
						console.log(error);
						_this.openSnackBar('Failed to verify emails', 'OK', "start", "top", 5000 );	
						_this.cdr.detectChanges();
					})
				}, 1);
				this.cdr.detectChanges();
			}, (error) => {
				console.log('User Direct Payment FAILED===>', error)
				this.openSnackBar('Payment failed! ' + error.error.errormsg, 'OK', "start", "top", 5000 );  
				this.longNetworkOperations = false;
				this.loader.display(false);
				this.paymentinprogress = false;
				this.cdr.detectChanges();
			});
		}
	}

}

