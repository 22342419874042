import {Component,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'show-criteria-popup',
  templateUrl: './show-criteria-popup.component.html',
  styleUrls: ['./show-criteria-popup.component.scss'],
})

export class ShowCriteriaComponentDialog {
    criteriaList:any = [];
    keywords:string = '';
    date:string = '';
    
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    ngOnInit() {
      if (this.data && this.data.data && this.data.data.lk_query) {
        try {
          let searchQuery = decodeURI(this.data.data.lk_query);
          console.log(searchQuery, 'searchQuery');
          
          searchQuery.split(',').forEach((t) => {    
            let temp = t.split('text:')
        
            if (temp.length > 1) {
              this.criteriaList.push(decodeURIComponent(temp[1]))
            }
          })

          if (this.criteriaList.length == 0 && searchQuery.indexOf('&q=') != -1) {
            this.criteriaList.push(searchQuery.replace('&q=', ''))
          }

          this.keywords = this.data.data.searchquery && this.data.data.searchquery.substr(0, this.data.data.searchquery.indexOf('~'));
          this.keywords = this.keywords && this.keywords.replace(/["()]/gi, '');
          this.date = this.data.data.createdAt
        } catch (error) {
        }
      }

      console.log(this.criteriaList, 'criteriaList');
    }
}