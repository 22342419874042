import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../../core/auth';

@Injectable()
export class UserDetailResolver implements Resolve<Observable<any>> {
  constructor(
      private activatedRoute: ActivatedRoute,private authservice:AuthService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any 
  {
      let req={
        mode:'all',
        continentId:'',
        countryId:'',
        userId:''
      }
      if(localStorage.getItem('user') != null) {
        req.userId = localStorage.getItem('user');
      }
        return this.authservice.getMiniProfileMasterData(req);
  }
}
