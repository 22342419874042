// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
@Component({
	selector: 'kt-oauth',
	templateUrl: './oauth.component.html',
	encapsulation: ViewEncapsulation.None
})
export class OAuthComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	verified = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;
	appId: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
 		console.log('-----------');

		// // redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			console.log('#PRARAMS', params);
			this.verifyCode(params.token);
			this.appId = params.appid;
			this.getCurrentUser(params.appid);
			this.verifyCode(params.token);
			localStorage.setItem('token', params.token );
			
			// this.returnUrl = params.returnUrl || '/';
		});

		
	}

	getCurrentUser(appid: string){
		debugger
		this.auth.getCurrentUser(appid).subscribe((res) => {
			localStorage.setItem('user', res.data.id );
			localStorage.setItem('name', res.data.fullName );
			localStorage.setItem('email', res.data.emailAddress); // need to be change
			this.store.dispatch(new Login({authToken: res.data.id })); //res.accessToken
			let companyDataStored = res.data.companySubmitted;
			let isMiniProfileSubmitted = res.data.isMiniProfileSubmitted;
			let stripeCustomerId = res.data.stripeCustomerId;
			localStorage.setItem('stripeId', stripeCustomerId)
			localStorage.setItem('id', res.data.id)
			localStorage.setItem('companyDataStored', 'true');
			localStorage.setItem('token', res.token);

			if(isMiniProfileSubmitted==0){
				this.router.navigateByUrl('/auth/mini-profile'); // Main page
			}else if( companyDataStored ) {
				if( !stripeCustomerId ){
					this.router.navigateByUrl('/auth/billing'); // billing page
				}
				this.router.navigateByUrl(this.returnUrl); // Main page
			}else{
				this.router.navigateByUrl('/auth/company-detail'); // Main page
			}

			




			
			this.authNoticeService.setNotice(res.message, 'success');      
			this.loading = false;
			this.cdr.markForCheck();			
		})
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * verifyEmail
	 */
	verifyCode(code: string) {
	 

		// this.loading = true;

		// console.log('#token', code);
		
		// this.auth.verifyingCode(code)
		// 		.subscribe( (res: any) => {
 		// 			// this.router.navigateByUrl('/'); // Main page
		// 			console.log('#res', res);
		// 			this.authNoticeService.setNotice(`${res.message}`, 'success');
		// 			this.loading = false;
		// 			this.verified = true;
		// 			this.cdr.markForCheck();
		// 		}, err => {
		// 		//	this.authNoticeService.setNotice(err.error && err.error.errorMsg || "Link expired", 'danger');
		// 			console.log('#err', err);
		// 			this.loading = false;	
		// 			this.verified = false;

		// 			this.cdr.markForCheck();
		// 		});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
