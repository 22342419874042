// Angular
import { Component, ViewChild, OnInit, ChangeDetectorRef, Inject, Output, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
// Lodash
import { shuffle } from 'lodash';
// Services
// Widgets model
import { LayoutConfigService, SparklineChartOptions } from '../../../core/_base/layout';
//ng bootstrap
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginator, MatSort, MatTableDataSource, MatSelect, MatDialog, PageEvent } from '@angular/material';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
// import  Angular2Csv  from 'angular2-csv/Angular2-csv';
import { AuthNoticeService, AuthService, SearchFilter} from '../../../core/auth';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
 import _ from 'lodash';
//snackbar
import {MatSnackBar, MatSnackBarHorizontalPosition,	MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { CardData } from 'angular-credit-cards';
import {HttpClient} from "@angular/common/http";
import { int } from 'aws-sdk/clients/datapipeline';
import { integer } from 'aws-sdk/clients/cloudfront';
import { map, startWith, tap, takeUntil, first } from 'rxjs/operators';

// Dialogs
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { email } from '@rxweb/reactive-form-validators';
import { exit } from 'process';
import { Chart, ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, BaseChartDirective } from 'ng2-charts';
import { LoaderService } from '../../theme/loader.service';
export interface SubscribedUser {
	value: string;
	name: string;
	email: string;
	type: string;
	website: string;
}

export interface Users {
	value: string;
	name: string;
	email: string;
	type: string
}
export interface TopCreditHolders {
	value: string;
	name: string;
	email: string;
	balance: string
}
export interface Demos {
	value: string;
	demo_date: string;
	name: string;
	email: string;
	phone: string;
	country: string;
	created_date: string;
}
@Component({
	selector: 'kt-dashboard',
	templateUrl: './adminp.component.html',
	styleUrls: ['adminp.component.scss'],
})
export class AdminpComponent implements OnInit, AfterViewInit {
	
	totalUsers = 0;
	verifiedUsers = 0;
	unverifiedUsers = 0;
	newUsers = 0;
	subscribedUsers = 0;
	payAsYoGoUsers = 0;
	miniProfileSubmitted = 0;
	total_web_scanned = 0;
	isSuperAdmin = false;
	currentUserEmail: string;
	users: Users[] = [];
	topcredithholders: TopCreditHolders[] = [];
	usersSub: SubscribedUser[] = [];
	demos: Demos[] = [];
	displayedColumns: string[] = ['name', 'email', 'type', 'markadmin', 'marksuperadmin'];
	displayedColumnsTopCreditHolders: string[] = ['name', 'email', 'balance'];
	displayedColumnsSub: string[] = ['name', 'email', 'type', 'website'];
	displayedColumnsDemos: string[] = ['demo_date', 'name', 'email', 'phone', 'country', 'created_date'];
	dataSourceUsers = new MatTableDataSource<Users>(this.users);
	dataSourceUsersSub = new MatTableDataSource<SubscribedUser>(this.usersSub);
	dataSourceTopCreditHolders = new MatTableDataSource<TopCreditHolders>(this.topcredithholders);
	dataSourceDemos = new MatTableDataSource<Demos>(this.demos);
	companyFilterCtrl1=new FormControl('');
	allCompanyList1=[];
	companyList1=[];
	countryFilterCtrl1=new FormControl('');
	allCountryList1=[];
	countryList1=[];
	country1Notselected = true;

	companyFilterCtrl2=new FormControl('');
	allCompanyList2=[];
	companyList2=[];
	countryFilterCtrl2=new FormControl('');
	allCountryList2=[];
	countryList2=[];
	country2Notselected = true;

	companyFilterCtrl3=new FormControl('');
	allCompanyList3=[];
	companyList3=[];
	countryFilterCtrl3=new FormControl('');
	allCountryList3=[];
	countryList3=[];
	country3Notselected = true;

	subTableHidden = true;
	@ViewChild('paginatorusers', { static: true }) paginatorusers: MatPaginator;
	@ViewChild(MatSort, {static: true}) sortAdmin: MatSort;
	@ViewChild(MatSort, {static: true}) sortDemos: MatSort;
	@ViewChild('paginatoruserssub', { static: true }) paginatoruserssub: MatPaginator;
	@ViewChild('paginatordemos', { static: true }) paginatordemos: MatPaginator;
	constructor(private layoutConfigService: LayoutConfigService,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private _snackBar: MatSnackBar,	
		public dialog: MatDialog,
		private http:HttpClient,
		private loader:LoaderService
		) {
			this.getRegisteredUsers(null);
			this.getUsersSub(null);
			this.getTotalWebsiteScanned(null);
			this.getUsersAdmin();
			this.getTopCreditHolders();
			this.getCountryData1();
			this.getDemos();
	}
	
	ngOnInit(): void {
		var userid=localStorage.getItem('id');
		this.currentUserEmail = localStorage.getItem('email');
		if(localStorage.getItem('IsAdmin')=="2"){
			this.isSuperAdmin = true;
			this.displayedColumns = ['name', 'email', 'type', 'markadmin', 'marksuperadmin', 'revoke'];
		}else{
			this.isSuperAdmin = false;
			this.displayedColumns = ['name', 'email', 'type', 'markadmin', 'revoke'];
		}
		this.initUserForm();
		this.companyFilterCtrl1.valueChanges.subscribe(value => {
			// console.log('company name has changed:', value)
			this._filterCompany1(value);
		});
		this.countryFilterCtrl1.valueChanges.subscribe(value => {
			// console.log('country name has changed:', value)
			this._filterCountry1(value);
		});
		this.companyFilterCtrl2.valueChanges.subscribe(value => {
			// console.log('company name has changed:', value)
			this._filterCompany2(value);
		});
		this.countryFilterCtrl2.valueChanges.subscribe(value => {
			// console.log('country name has changed:', value)
			this._filterCountry2(value);
		});
		this.companyFilterCtrl3.valueChanges.subscribe(value => {
			// console.log('company name has changed:', value)
			this._filterCompany3(value);
		});
		this.countryFilterCtrl3.valueChanges.subscribe(value => {
			// console.log('country name has changed:', value)
			this._filterCountry3(value);
		});
	}
	_filterCompany1(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.companyList1 = this.allCompanyList1.filter(x => x.companyname.toLowerCase().includes(valueLowerCase) )
  	}
	_filterCompany2(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.companyList2 = this.allCompanyList2.filter(x => x.companyname.toLowerCase().includes(valueLowerCase) )
  	}
	_filterCompany3(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.companyList3 = this.allCompanyList3.filter(x => x.companyname.toLowerCase().includes(valueLowerCase) )
  	}
	private getCompanyData1(continentId: number,countryId: number) {
		this.loader.display(true);
		this.companyList1 = [];
		this.auth.getMiniProfileMasterData({
			mode: 'company', continentId: continentId,
			countryId: countryId
		}).subscribe((x) => {
			this.companyList1 = x['data'];
			this.allCompanyList1 = x['data'];
			this.loader.display(false);
			this.cdr.detectChanges();
		});
	}
	onCompanyChange1(evt:any){
		console.log('===>', evt.value)
		this.subTableHidden = true;
		this.getRegisteredUsers(evt.value);
		this.getUsersSub(evt.value);
	}
	_filterCountry1(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.countryList1 = this.allCountryList1.filter(x => x.name.toLowerCase().includes(valueLowerCase) )
  	}
	private getCountryData1() {
		this.loader.display(true);
		this.companyList1 = [];
		this.auth.getAllCountries().subscribe((x) => {
			this.countryList1 = x['result'];
			this.allCountryList1 = x['result'];
			this.countryList2 = x['result'];
			this.allCountryList2 = x['result'];
			this.countryList3 = x['result'];
			this.allCountryList3 = x['result'];
			//console.log(this.countryList1);
			this.loader.display(false);
			this.cdr.detectChanges();
		});
	}
	onCountryChange1(evt:any){
		console.log('===>', evt)
		this.country1Notselected = false;
		this.getCompanyData1(evt.value.continent_Id, evt.value.id);
	}  
	ClearFilter1(s1:any, s2:any){
		s1.value = undefined;
		s2.value = undefined;
		this.country1Notselected = true;
		this.getRegisteredUsers(null);
		this.getUsersSub(null);
		this.cdr.detectChanges();

	}
	private getCompanyData2(continentId: number,countryId: number) {
		this.loader.display(true);
		this.companyList2 = [];
		this.auth.getMiniProfileMasterData({
			mode: 'company', continentId: continentId,
			countryId: countryId
		}).subscribe((x) => {
			this.companyList2 = x['data'];
			this.allCompanyList2 = x['data'];
			this.loader.display(false);
			this.cdr.detectChanges();
		});
	}
	onCompanyChange2(evt:any){
		console.log('===>', evt.value)
		this.getTotalWebsiteScanned(evt.value);
	}
	_filterCountry2(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.countryList2 = this.allCountryList2.filter(x => x.name.toLowerCase().includes(valueLowerCase) )
  	}

	onCountryChange2(evt:any){
		console.log('===>', evt)
		this.country2Notselected = false;
		this.getCompanyData2(evt.value.continent_Id, evt.value.id);
	}  
	ClearFilter2(s1:any, s2:any){
		s1.value = undefined;
		s2.value = undefined;
		this.country2Notselected = true;
		this.getTotalWebsiteScanned(null);
		this.cdr.detectChanges();
	}
	private getCompanyData3(continentId: number,countryId: number) {
		this.loader.display(true);
		this.companyList3 = [];
		this.auth.getMiniProfileMasterData({
			mode: 'company', continentId: continentId,
			countryId: countryId
		}).subscribe((x) => {
			this.companyList3 = x['data'];
			this.allCompanyList3 = x['data'];
			this.loader.display(false);
			this.cdr.detectChanges();
		});
	}
	onCompanyChange3(evt:any){
		console.log('===>', evt.value)
		//this.getTotalWebsiteScanned(evt.value);
	}
	_filterCountry3(value: string) {
		let valueLowerCase = value.toLowerCase();
		this.countryList3 = this.allCountryList3.filter(x => x.name.toLowerCase().includes(valueLowerCase) )
  	}
	onCountryChange3(evt:any){
		console.log('===>', evt)
		this.country3Notselected = false;
		this.getCompanyData3(evt.value.continent_Id, evt.value.id);
	}  
	ClearFilter3(s1:any, s2:any){
		s1.value = undefined;
		s2.value = undefined;
		this.country2Notselected = true;
		//this.getTotalWebsiteScanned(null);
		this.cdr.detectChanges();
	}

	@ViewChildren("checkboxestoclear") checkboxestoclear: QueryList<any>;
	uncheckAll() {
		this.checkboxestoclear.forEach((element) => {
			element.checked = false;
		});
	}
	getTotalWebsiteScanned(website: any) {
		this.loader.display(true);
		this.auth.getWebscanData(website).subscribe((res:any)=>{
			console.log('Webacan data ==>',res.result.total_scanned);
			this.loader.display(false);
			this.total_web_scanned = res.result.total_scanned;
			this.cdr.detectChanges();
		},(error)=>{
			this.openSnackBar('Failed to load webSCAN data..', 'OK', "start", "top", 5000 );
			this.loader.display(false);
			this.cdr.detectChanges();
		})
	}
	getRegisteredUsers(website:any){
		this.loader.display(true);
		this.auth.getRegisteredUsersCount(website).subscribe((res_data:any)=>{
			console.log('Registered Users==>',res_data.result);
			if (res_data.result) {
				this.totalUsers = res_data.result.tot_users_unverified + res_data.result.tot_users_verified;
				this.verifiedUsers = res_data.result.tot_users_verified;
				this.unverifiedUsers = res_data.result.tot_users_unverified;
				this.newUsers = res_data.result.tot_users_new;
				this.subscribedUsers = res_data.result.tot_users_subscribed;
				this.payAsYoGoUsers = this.verifiedUsers - this.subscribedUsers;
				this.miniProfileSubmitted = res_data.result.tot_users_mini_profile_submitted;
				this.loader.display(false);
			}else {
				this.openSnackBar('Failed to load user data..', 'OK', "start", "top", 5000 );
				this.loader.display(false);
			}
			this.cdr.detectChanges();
		},(error)=>{
			this.openSnackBar('Failed to load user data..', 'OK', "start", "top", 5000 );
			this.loader.display(false);
			this.cdr.detectChanges();
		})
	}
	getUsersAdmin(){
		this.loader.display(true);
		this.users = [];
		this.auth.getUsersForAdmin().subscribe(res_data => {
			console.log('res_data Users For Admin--->', res_data);
			res_data.result.forEach((element: { isAdmin: number; id: any; email: any; name: any; }) => {
				let tp = 'User';
				if (element.isAdmin === 1) {
					tp = 'Admin';
				} else if (element.isAdmin === 2) {
					tp = 'Super Admin';
				} 
				this.users.push({value: element.id, email: element.email, name: element.name, type: tp });
			});
			this.loader.display(false);
			this.updateUserTable();
		},(error)=>{
			//console.log(error);
			this.loader.display(false);
			this.openSnackBar('Error occured fetching Users for Admin panel!', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		})
	}
	getUsersSub(website:any){
		this.loader.display(true);
		this.usersSub = [];
		this.auth.getSubscribedUsers(website).subscribe(res_data => {
			console.log('res_data subscribed users', res_data);
			res_data.result.forEach((element: any) => {
				this.usersSub.push({value: element.id, email: element.email, name: element.name, type: element.type, website: element.website });
			});
			this.updateUserTableSub();
			this.loader.display(false);
		},(error)=>{
			//console.log(error);
			this.loader.display(false);
			this.openSnackBar('Error occured fetching Sub Users!', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		})
	}
	getTopCreditHolders(){
		this.loader.display(true);
		this.topcredithholders = [];
		this.auth.getTopCreditHolders().subscribe(res_data => {
			// console.log('Top cr==>', res_data)
			res_data.data.finalArr.forEach((element: any) => {
				this.topcredithholders.push({value: element.id, email: element.email, name: element.name, balance: element.balance });
			});
			this.updateTopCreditHolders()
			this.loader.display(false);
		},(error)=>{
			//console.log(error);
			this.loader.display(false);
			this.openSnackBar('Error occured fetching Top!', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		})
	}
	getDemos(){
		this.loader.display(true);
		this.demos = [];
		this.auth.getDemos().subscribe(res_data => {
			console.log('res_data demos', res_data);
			res_data.result.forEach((element: any) => {
				this.demos.push({value: element.id, email: element.email, phone: element.phone , name: element.name, country: element.country, created_date: element.created_date, demo_date: element.demo_date });
			});
			
			this.updateDemoTable();
			this.loader.display(false);
		},(error)=>{
			//console.log(error);
			this.loader.display(false);
			this.openSnackBar('Error occured fetching Sub Users!', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		})
	}
	updateDemoTable() {
		console.log('this.demos',this.demos);
		this.dataSourceDemos = new MatTableDataSource<Demos>(this.demos);
		this.dataSourceDemos.paginator = this.paginatordemos;
		// this.dataSourceDemos.sort = this.sortDemos;
		this.cdr.detectChanges();
	}
	updateUserTable() {
		this.dataSourceUsers = new MatTableDataSource<Users>(this.users);
		this.dataSourceUsers.paginator = this.paginatorusers;
		this.dataSourceUsers.sort = this.sortAdmin;
		this.cdr.detectChanges();
	}
	updateUserTableSub() {
		this.dataSourceUsersSub = new MatTableDataSource<SubscribedUser>(this.usersSub);
		this.dataSourceUsersSub.paginator = this.paginatoruserssub;
		this.cdr.detectChanges();
	}
	updateTopCreditHolders() {
		this.dataSourceTopCreditHolders = new MatTableDataSource<TopCreditHolders>(this.topcredithholders);
		this.cdr.detectChanges();
	}
	applyFilterDemos(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSourceDemos.filter = filterValue.trim().toLowerCase();
	
		if (this.dataSourceDemos.paginator) {
		  this.dataSourceDemos.paginator.firstPage();
		}
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSourceUsers.filter = filterValue.trim().toLowerCase();
	
		if (this.dataSourceUsers.paginator) {
		  this.dataSourceUsers.paginator.firstPage();
		}
	}
	applyFilterSub(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSourceUsersSub.filter = filterValue.trim().toLowerCase();
	
		if (this.dataSourceUsersSub.paginator) {
		  this.dataSourceUsersSub.paginator.firstPage();
		}
	}

	ngAfterViewInit() {	
		// this.sort.sortChange.subscribe(() => {
		// });
		this.cdr.markForCheck();
		this.dataSourceUsers.paginator = this.paginatorusers;
		this.dataSourceUsers.sort = this.sortAdmin;
	}
	toggleDrawer(drawercomp:any) {
		drawercomp.toggle();
	}
	MarkAdmin(id: any, email: any) {
		let data = {
			type: 'Admin',
			email: email
		}
		this.loader.display(true);
		this.auth.setUserAsAdminOrSuperAdmin(data).subscribe(res_data => {
			console.log(res_data)
			this.getUsersAdmin();	
			this.loader.display(false);
			//this.updateUserTable();
		},(error)=>{
			console.log(error);
			this.loader.display(false);
			this.openSnackBar('Failed to set user as Admin', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		});
	}
	MarkSuperAdmin(id: any, email: any) {
		let data = {
			type: 'Super Admin',
			email: email
		}
		this.loader.display(true);
		this.auth.setUserAsAdminOrSuperAdmin(data).subscribe(res_data => {
			this.getUsersAdmin();	
			this.loader.display(false);
			//this.updateUserTable();
		},(error)=>{
			console.log(error);
			this.loader.display(false);
			this.openSnackBar('Failed to set user as Super Admin', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		});

	}
	Revoke(id: any, email: any) {
		let data = {
			type: 'User',
			email: email
		}
		this.loader.display(true);
		this.auth.setUserAsAdminOrSuperAdmin(data).subscribe(res_data => {
			this.getUsersAdmin();	
			this.loader.display(false);
			//this.updateUserTable();
		},(error)=>{
			console.log(error);
			this.loader.display(false);
			this.openSnackBar('Failed to revoke priveleges', 'OK', "start", "top", 5000 );	
			this.cdr.detectChanges();
		});
	}
	subscribedUserClicked(){
		this.subTableHidden = false;
	}
	initUserForm() {
		
	}
	openSnackBar(message: string, action: string, horizontal: MatSnackBarHorizontalPosition, vertical: MatSnackBarVerticalPosition, duration: integer) {
		this._snackBar.open( message, action, {
			duration: duration,	
			horizontalPosition: horizontal,
      		verticalPosition: vertical
		});
	}
}

