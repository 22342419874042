// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
 import {environment} from "../../../../../environments/environment";
import { SharedService } from '../../../../shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
  
  
@Component({
	selector: 'kt-login',
	templateUrl: './demo.component.html',
	encapsulation: ViewEncapsulation.None
})
export class DemoComponent implements OnInit, OnDestroy {


	// Public params
	loginForm: FormGroup;
	loading = false;
	errors: any = [];
	user_name = '';
	email = '';
	dt = '';
	tm = '';
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private sharedService:SharedService
 	) {
	}
	ngOnInit(): void {
		const _user =  { fullName: localStorage.getItem("name") || 'user', email: localStorage.getItem('email') };
		this.initLoginForm();
		let url = new URL(window.location.href);
		let searchParams = new URLSearchParams(url.search);
		this.user_name = searchParams.get('name');
		this.email = searchParams.get('email');
		this.dt = searchParams.get('dt');
		this.tm = searchParams.get('tm');
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.loading = false;
	}

	initLoginForm() {
		this.loginForm = this.fb.group({
		});
	}
	HandleTabs(type: string){
		
	}
	
}
