// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { BillingComponent } from './views/pages/auth/billing/billing.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { RegisterComponent} from './views/pages/auth/register/register.component';
import { ZoominGroominComponent } from '../app/views/pages/landingpages/zoomingroomin/zoomingroomin.component';
import { BaseTemplateComponent } from '../app/views/pages/landingpages/basetemplate/basetemplate.component';
import { AtaxComponent } from '../app/views/pages/landingpages/atax/atax.component';
import { InsuranceComponent } from '../app/views/pages/landingpages/insurance/insurance.component';
import { LoyaltyBrandsComponent } from '../app/views/pages/landingpages/loyaltybrands/loyaltybrands.component';
import { UnsubscribeComponent } from '../app/views/pages/unsubscribed/unsubscribe.component';

// Auth
import { AuthGuard } from './core/auth';
import { AdminGuard } from './core/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule, MatAutocompleteModule, MatMenuModule, MatProgressSpinnerModule,
	MatSortModule, MatPaginatorModule ,MatRadioModule, MatButtonModule, MatCheckboxModule, 
	MatSelectModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { SweetAlert2LoaderService, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
const routes: Routes = [
	{ path:'',redirectTo:'home',pathMatch:'full' },
	{ path: 'home',  loadChildren: () => import('./views/pages/home-layout/home.module').then(m => m.HomeModule) },
	{ path: 'auth',  loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
	{ path: 'billing', component: BillingComponent },
	{ path: 'zoomingroomin', component: ZoominGroominComponent },
	{ path: 'atax', component: AtaxComponent },
	{ path: 'insurance', component: InsuranceComponent },
	{ path: 'loyaltybrands', component: LoyaltyBrandsComponent},
	{ path: 'unsubscribed', component: UnsubscribeComponent},
	{ path: 'base', component: BaseTemplateComponent },
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'lkbgscrapper',
				loadChildren: () => import('./views/pages/lkbgscrapper/lkbgscrapper.module').then(m => m.LkbgScrapperModule)
			},
			{
				path: 'profile',
				loadChildren: () => import('./views/pages/user/user.module').then(m => m.UserModule)
			},
			{
				path: 'email_marketing',
				loadChildren: () => import('./views/pages/email-marketing/email-m.module').then(m => m.EmailMarketingModule)
			},
			
			{
				path: 'search_list',
				loadChildren: () => import('./views/pages/user-search-list/user-search-list.module').then(m => m.UserSearchListModule)
			},
			/*{
				path: 'emailverifier',
				loadChildren: () => import('./views/pages/verifierui/verifier.module').then(m => m.VerifierUIModule)
			},*/
			{
				path: 'credits',
				loadChildren: () => import('./views/pages/credits/credits.module').then(m => m.CreditsModule)
			},
			{
				path: 'success',
				loadChildren: () => import('./views/pages/stripe-success/stripe-success.module').then(m => m.StripeSuccessModule)
			},
			{
				path: 'cancel',
				loadChildren: () => import('./views/pages/stripe-cancel/stripe-cancel.module').then(m => m.StripeCancelModule)
			},     
			{
				path: 'register',
			
				component: RegisterComponent,
				
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: 'dashboard', redirectTo: 'dashboard', pathMatch: 'full'},
			{
				path: 'payment?success=true',
				redirectTo: 'search_list',
				pathMatch: 'full'
			},
			{
				path: 'creditpurchase?success=true',
				redirectTo: 'credits',
				pathMatch: 'full'
			},
			{
				path: 'subscribe?success=true',
				redirectTo: 'auth/mysubscriptions',
				pathMatch: 'full'
			},
			{
				path: '**',
				component: ErrorPageComponent,
				data: {
					type: 'error-v3',
					code: 404,
					title: '404... Page Not Found',
					desc: 'Looks Like the page you are looking for is not found'
				}
			}
			//{path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
		FormsModule, ReactiveFormsModule,
		MatTableModule, MatAutocompleteModule, MatMenuModule, MatProgressSpinnerModule,
		MatSortModule, MatPaginatorModule ,MatRadioModule, MatButtonModule, MatCheckboxModule, 
		MatSelectModule, MatFormFieldModule, MatInputModule,
		CommonModule,
		SweetAlert2Module.forRoot()
	],
	declarations:[
		BaseTemplateComponent,
		ZoominGroominComponent,
		AtaxComponent,
		InsuranceComponent,
		LoyaltyBrandsComponent,
		UnsubscribeComponent
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
