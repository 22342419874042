 // Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
//Stripe
//import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { StripeService } from "ngx-stripe";
declare function captureStripe():any;
@Component({
  selector: 'kt-billing',
  templateUrl: './billing.component.html',
	encapsulation: ViewEncapsulation.None
})
export class BillingComponent implements OnInit {
  loading = false;
	billingForm: FormGroup;

  // optional parameters
  //elementsOptions: ElementsOptions = {locale: 'en' };
  elementsOptions: any = {locale: 'en'}; 
  //elements: Elements;
  //card: StripeElement;
  elements: any;
  card: any;
  notice: string;
  private unsubscribe: Subject<any>;

  constructor(		
    private authNoticeService: AuthNoticeService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
    private auth: AuthService,
    private fb: FormBuilder,	
    private translate: TranslateService,
    private stripeService: StripeService) {
      this.unsubscribe = new Subject();
     }

  ngOnInit() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            },
            hidePostalCode: true,
          });
          this.card.mount('#card-element');
        }
      });
      this.initBillingForm();
  }

  initBillingForm(){
    this.billingForm = this.fb.group({
      name:[ '',  Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
    ],
    }) 
  }

  goNext(){
    this.router.navigateByUrl('/dashboard');                                              
  }

  /**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

  AddCard() {
    const controls = this.billingForm.controls;

    /** check form */
		if (this.billingForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
    const name = this.billingForm.get('name').value;
    console.log('#name', name);

    this.stripeService
      .createToken(this.card, { name: name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log("#token", result.token);
          this.validateAndAddCard(result.token.id, localStorage.getItem("email"));

        } else if (result.error) {
          // Error creating the token
          this.authNoticeService.setNotice(result.error && result.error.message || "Something went wrong", 'danger');

          console.log("#ERROR", result.error.message);
        }
      });
  }


  validateAndAddCard(token, email) {
    this.loading = true;
    this.cdr.markForCheck();

     this.auth.addCard(token, email).subscribe((res: any) => {
			//this.data = res;
			console.log("#res_register", res);
			this.authNoticeService.setNotice('Card succesfully have been validted and saved', 'success');      
      //this.gtag_report_conversion("https://www.bizwizz.net/")
			// this.authNoticeService.setNotice(res.message, 'success');      
			this.loading = false;
      this.cdr.markForCheck();
      // captureStripe()
      setTimeout(()=>{
        this.router.navigateByUrl('/dashboard');                                              
      }, 1000);  
		  }, err => {
			// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			this.authNoticeService.setNotice("Something went wrong, please try again", 'danger');
			console.log("#error rej", err);
			this.loading = false;
		    this.cdr.markForCheck();  
		  });
  }

/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.billingForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
