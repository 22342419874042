// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule, MatDialogModule, MatTreeModule, MatIconModule ,MatCheckboxModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatDatepickerModule, MatTableModule, MatExpansionModule, MatTooltipModule, MatToolbarModule, MatProgressSpinnerModule, MatRadioModule, MatCardModule, MatTabsModule,MatPaginatorModule, MatSidenavModule, MatGridListModule, MatDividerModule, MatStepperModule } from '@angular/material';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { DemoComponent } from './demo/demo.component';
import { OAuthComponent } from './oauth/oauth.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CompanyComponent } from './company/company.component';
import { BillingComponent } from './billing/billing.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, AuthGuard, AdminGuard, authReducer, AuthService } from '../../../core/auth';
import { RecaptchaModule } from 'ng-recaptcha';
// environment
import {environment} from '../../../../environments/environment';
import { from } from 'rxjs';
import { NgxStripeModule } from 'ngx-stripe';
import { TreeviewModule } from 'ngx-treeview';
import { TreeModule } from 'angular-tree-component';
import {BreadcrumbModule} from 'angular-crumbs';
 import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import {OTPDialogComponent } from './otp-modal/otp-modal.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { PrivacyPolicyComponent } from '../../partials/content/general/privacy-policy/privacy-policy.component';
import { MiniProfileComponent } from './mini-profile/mini-profile.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { UserDetailResolver } from '../../../shared/resolver/mini-profile.resolver';
import { CompanyInfoResolver
 } from '../../../shared/resolver/company-info.resolver';
import { ToastrModule } from 'ngx-toastr';
import { TreeComponent } from './tree/tree.component';
import { LoaderService } from '../../theme/loader.service';
import { PartialsModule } from '../../partials/partials.module';
import { IndexingComponent } from './indexing/document-indexing.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";  
// import { NgxLinkedinModule } from 'ngx-linkedin';
// import {
//     SocialLoginModule,
//     AuthServiceConfig,
//     LinkedinLoginProvider,
// } from "angular-6-social-login-v2";
import { SweetAlert2LoaderService, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MySubscriptionComponent } from './../mysubscription/mysubscription.component';
import { AdminpComponent } from './../adminp/adminp.component';
import { VerifierUIComponent } from './../verifierui/verifierui.component';
import { VerifierUIService } from './../verifierui/verifierui.service';
import { StatsComponentDialog } from "./../verifierui/stats-popup/stats-popup.component";
import { ChartsModule } from 'ng2-charts';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { SignupComponent } from './signup/signup.component';
import { PurchaseEmailsComponent } from '../purchase-emails/purchase-emails.component';
import { ShowCriteriaComponentDialog } from '../user-search-list/show-criteria-popup/show-criteria-popup.component';
import { SignupCompletedComponent } from './signup/completed/signupcompleted.component';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				
				
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'email/confirm',
				component: VerifyEmailComponent
			},
			
			{
				path: 'mini-profile',
				component: MiniProfileComponent,
				canActivate:[AuthGuard],
				resolve:{masterdata:UserDetailResolver}
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent,
			},
			{
				path: 'company-detail',
				component: CompanyComponent,
				canActivate:[AuthGuard],
				resolve:{masterdata:CompanyInfoResolver}
			},
			{
				path: 'billing',
				canActivate:[AuthGuard],
				component: BillingComponent,
			},
			{
				path: 'oauth/callback',
				component: OAuthComponent,
			},
			{
				path: 'indexing',
				component: IndexingComponent,
			},
			{
				path: 'mysubscriptions',
				component: MySubscriptionComponent,
				canActivate:[AuthGuard]
			},
			{
				path: 'purchase-emails',
				component: PurchaseEmailsComponent,
				canActivate:[AuthGuard]
			},
			{
				path: 'adminp',
				component: AdminpComponent,
				canActivate:[AdminGuard]
			},
			{
				path: 'emailverifier',
				component: VerifierUIComponent,
				canActivate:[AuthGuard]
			},
			{
				path: 'demo',
				component: DemoComponent,
			},
			{
				path: 'register',
			
				component: RegisterComponent,
				canActivate: [AuthGuard]
				
			},
			{
				path: 'signup',
				component: SignupComponent
			},
			{
				path: 'signup/completed',
				component: SignupCompletedComponent
			}
		]
	}
];


@NgModule({
	imports: [
		LivechatWidgetModule,
		CommonModule,
		FormsModule,
		PartialsModule,
		RouterModule.forChild(routes),
		ReactiveFormsModule,
		RxReactiveFormsModule,
		MatDatepickerModule,
		MatButtonModule,
		MatInputModule,
		MatTableModule,
		MatFormFieldModule,
		MatSelectModule,
		MatTooltipModule,
		MatCheckboxModule,
		InternationalPhoneNumber2Module,
		MatIconModule,
		MatExpansionModule,
		MatAutocompleteModule,
		NgxMatSelectSearchModule,
		MatTreeModule,
		BreadcrumbModule,
		TreeModule.forRoot(),
		TreeviewModule.forRoot(),
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		RecaptchaModule,
		OAuthModule.forRoot(),
		MatToolbarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatCardModule,
		MatTabsModule,
		MatPaginatorModule,
		MatSidenavModule,
		MatGridListModule,
		MatDividerModule,
		MatStepperModule,
		ChartsModule,
		//BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
		timeOut: 10000,
		closeButton:true,
		progressBar:true,
		progressAnimation:'decreasing',
		
	}),
	SweetAlert2Module.forRoot(),
		MatDialogModule,
		NgxIntlTelInputModule,
		NgxStripeModule.forRoot(environment.STRIPE_PUBLISHABLE_KEY),
		GooglePlaceModule
 	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},  
		UserDetailResolver,
		CompanyInfoResolver,
		VerifierUIService
		
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		DemoComponent,
		VerifyEmailComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		AuthNoticeComponent,
		CompanyComponent,
		BillingComponent,
		OAuthComponent,
		OTPDialogComponent,
		PrivacyPolicyComponent,
		TreeComponent,
		MiniProfileComponent,
		IndexingComponent,
		MySubscriptionComponent,
		PurchaseEmailsComponent,
		AdminpComponent,
		VerifierUIComponent,
		StatsComponentDialog,
		ShowCriteriaComponentDialog,
		SignupComponent,
		SignupCompletedComponent
	],
	entryComponents: [ OTPDialogComponent,PrivacyPolicyComponent,TreeComponent, StatsComponentDialog, ShowCriteriaComponentDialog],

})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard,
				AdminGuard,
				LoaderService
			]
		};
	}
}
