// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
 import {environment} from "../../../../../environments/environment";
import { SharedService } from '../../../../shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
  
  
@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {


	// Public params
	loginForm: FormGroup;
	loading = false;
	EnableSignIn:boolean=false;
	EnableSignUp:boolean=true;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	
	// site_key = '6Ldwj-0UAAAAABhOgDSgL0uxmZlCrE9MywghIptL' //kuoda.com
	site_key = '6Lfy1GsaAAAAAHu6ByW1gi5BXpJUxEOlbpLb2LWy'
	private unsubscribe: Subject<any>;
	linkedInUrl = 'http://127.0.0.1:1338/api/v1/auth/linkedin'
	private returnUrl: any;
	verified: boolean = true;
 	isEmailVerified=false;
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private sharedService:SharedService
 	) {
		this.unsubscribe = new Subject();
		this.linkedInUrl = environment.linkedInURL
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		const _user =  { fullName: localStorage.getItem("name") || 'user', email: localStorage.getItem('email') };
		if(localStorage.getItem('user')!=null){
			this.router.navigateByUrl('/dashboard');
			return;
		}


		this.initLoginForm();
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});


	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
	
		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */

	HandleTabs(type: string){
		//debugger;
		if(type=='0'){
			this.EnableSignIn=true;
			this.EnableSignUp=false;
		}
		else{
			this.EnableSignIn=false;
			this.EnableSignUp=true;
		}
		
	}

	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const authData = {
			email: controls.email.value,
			password: controls.password.value
		};
		this.auth.login(authData.email, authData.password)
				.subscribe( (res: any) => {
					
					console.log('#res', res);
					
					localStorage.setItem('user', res.data.id );
					localStorage.setItem('name', res.data.name );
					localStorage.setItem('email', authData.email); // need to be change
					localStorage.setItem('website', res.data.website);
				
					
					this.store.dispatch(new Login({authToken: res.data.id })); //res.accessToken
					let companyDataStored = res.data.companySubmitted;
					let stripeCustomerId = res.data.stripeCustomerId;
					localStorage.setItem('stripeId', stripeCustomerId)
					localStorage.setItem('id', res.data.id)
					//debugger;
					localStorage.setItem('companyDataStored', 'true');
					localStorage.setItem('token', res.token);
					
					localStorage.setItem('IsAdmin', res.data.IsAdmin);
					this.sharedService.setUserProfile(res.data);
					this.auth.getEmailUserCurrentPlan(localStorage.getItem('id')).subscribe((res_data:any)=>{
						if (res_data.result.length > 0) {
							console.log('Already stripe user created===>login',res_data.result)
						}else {
							let data ={
								user_id: localStorage.getItem('id'),
								email: localStorage.getItem('email'),
								name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
								current_plan: 'Pay As You Go'
							}
							this.auth.saveEmailUserPlan(data).subscribe((res_data:any)=>{
								console.log('New stripe user created===>login', res_data)
								this.cdr.detectChanges();
							}, (error) => {
								console.log('Error saving user plan===>login',error)
							});
							// End ctreate stripe customer
							
						}
					},(error)=>{
						console.log('Error getting user plan===>login',error)
					})
					// if(res.data.isMiniProfileSubmitted==0){
					// 	this.router.navigateByUrl('/auth/mini-profile'); // Main page
					// }else if( companyDataStored ) {
					// 	if( !stripeCustomerId ) {
					// 		this.router.navigateByUrl('/auth/billing'); // Main page
					// 	}
					// 	this.router.navigateByUrl('/dashboard'); // Main page
					// }else{
					// 	this.router.navigateByUrl('/auth/company-detail'); // Main page
					// }
					// this.authNoticeService.setNotice(res.message, 'success');  
					this.router.navigateByUrl('/home');
					this.toastr.success(res.message, 'Success');    
					this.loading = false;
					this.cdr.markForCheck();
				}, err => {
					const controls = this.loginForm.controls;
					try {
						//debugger;
						let errorDetails=JSON.parse(err.error.errormsg);
					if(errorDetails.errorType=='EmailNotVerified'){
						this.resendVerificationLink();
					}
					else{
						this.toastr.error(errorDetails.errorMessage, 'Error');
						// this.authNoticeService.setNotice(errorDetails.errorMessage, 'danger');
					}
					
					
					} catch (error) {
						console.log(error);
					}
					
					this.loading = false;
					this.cdr.markForCheck();
				});
	}
	
	resendVerificationLink(){
		const controls = this.loginForm.controls;
let req={emailAddress:controls.email.value};
this.auth.resendVerificationLink(req)
		.subscribe( (res: any) => {			
			var errorMessage='Info ! Your account is pending for verification. We have just sent the verification e-mail to '+controls.email.value;			
			this.toastr.warning(errorMessage, 'Warning');
			// this.authNoticeService.setNotice(errorMessage, 'Error');
		},
		err => {
			this.toastr.error(err.error.errormsg, 'Error');
			// this.authNoticeService.setNotice(err.error.errormsg, 'danger');
		}
		)


		
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	isVerified(){
		return this.verified;
	}

	resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response: ${captchaResponse}`);
		this.auth.verifyRecaptcha(captchaResponse).subscribe((res:any) => {
			console.log('#res', res);
			this.verified = false;
			console.log('#verified', this.verified);
			this.cdr.markForCheck();  

		}, (error: any) => {
			this.verified = true;
			console.log('#error', error);
			this.cdr.markForCheck();  

		});
	}
	
	login(){
		// this.auth.linkedIN().subscribe((data) => {
		// 	console.log('linkedIN', data );
			
		// })
		window.open('/api/v1/auth/linkedin',"mywindow","location=0,status=1,scrollbars=1, width=800,height=800");
		let listener = window.addEventListener('message', (message) => {
		  //message will contain facebook user and details
		  console.log('#message', message);
		  
		});
		console.log('#login');
	}
}
